import React, { useState } from 'react';
import MainContentPanel from '../../components/MainContentPanel';
import { Typography, IconButton, Menu, MenuItem, Select, Stack, Tooltip } from '@mui/material';
import { SearchableLicenseTable } from './SearchableLicenseTable';
import { Add } from '@mui/icons-material';
import { GET_LICENSES } from './License.graphql';
import LoadingSpinner from '../../components/LoadingSpinner';
import SearchMaconomyCustomerDialog from '../details/SearchMaconomyCustomerDialog';
import AddLicenseDialog from './AddLicenseDialog';
import { MCustomer } from '../../interfaces';
import { useQuery } from '@apollo/client';
import { initialSettings, ISettings, LicenseOverviewModel } from './ColumnDefinitions';
import { useLocation } from 'react-router-dom';

export function LicenseOverview(props) {
  let searchParms = new URLSearchParams(useLocation().search);
  let pendingTermination = searchParms.get('filter') === 'pendingTermination';
  let locked = searchParms.get('filter') === 'locked';
  let adfs = searchParms.get('filter') === 'adfs';

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [openSearch, setOpenSearch] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchTermParent, setSearchTermParent] = useState('');
  const [customer, setCustomer] = useState<MCustomer>();
  const [series, setSeries] = useState(0);
  const [onlyActive, setOnlyActive] = useState(true);
  const [onlyLocked, setOnlyLocked] = useState(locked);
  const [onlyPendTerm, setOnlyPendTerm] = useState(pendingTermination);
  const [onlyADFS, setOnlyADFS] = useState(adfs);
  const [columnlist, setColumnList] = useState<ISettings[]>(initialSettings);
  const [onlyInformed, setOnlyInformed] = useState(false);
  const { loading, error, data } = useQuery<{ licenses: LicenseOverviewModel[] }>(GET_LICENSES, {
    variables: {
      series,
      onlyActive,
      onlyLocked,
      onlyPendTerm,
      onlyADFS,
      onlyInformed,
      getColumn: columnlist.filter((c) => c.showColumn).map((c) => c.columnName),
    },
  });

  const handleMenuClick = (setState: (boolean) => void) => {
    setMenuAnchor(null);
    setState(true);
  };

  const handleCustomer = (customer: MCustomer) => {
    setOpenSearch(false);
    setCustomer(customer);
  };

  const handler = (ids: boolean[]) => {
    const newArr = [...columnlist];
    newArr.forEach((c, index) => {
      c.showColumn = ids[index];
    });

    setColumnList(newArr);
  };

  const handleChange = (event) => {
    setSeries(event.target.value);
  };

  return (
    <MainContentPanel>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="h5">
          Kunder
          <IconButton aria-label="Legg til kunde" onClick={(event) => setMenuAnchor(event.currentTarget)} size="large">
            <Add />
          </IconButton>
        </Typography>

        <Typography variant="h5">Serie:</Typography>
        <Tooltip placement="top" title="Velg lisenser med serie: Alle, BFS med delserier eller BVN!">
          <Select value={series} onChange={handleChange}>
            <MenuItem value={0}>Alle</MenuItem>
            <MenuItem value={9}>BFS</MenuItem>
            <MenuItem value={16}>BVN</MenuItem>
          </Select>
        </Tooltip>
      </Stack>

      <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
        <MenuItem onClick={() => handleMenuClick(setOpenDialog)}>Fyll ut manuelt</MenuItem>
        <MenuItem onClick={() => handleMenuClick(setOpenSearch)}>Hent fra kundekort i Maconomy</MenuItem>
      </Menu>
      {error && <Typography>{error.message}</Typography>}
      {(loading || !data) && <LoadingSpinner />}
      {data && data.licenses && (
        <SearchableLicenseTable
          setColumns={handler}
          licenses={data.licenses}
          onlyActive={onlyActive}
          onlyLocked={onlyLocked}
          onlyPendTerm={onlyPendTerm}
          onlyADFS={onlyADFS}
          onlyInformed={onlyInformed}
          setSearchTermParent={setSearchTermParent}
          setOnlyActive={setOnlyActive}
          setOnlyLocked={setOnlyLocked}
          setOnlyPendTerm={setOnlyPendTerm}
          setOnlyADFS={setOnlyADFS}
          setOnlyInformed={setOnlyInformed}
          columnlist={columnlist}
          searchTermParent={searchTermParent}
        />
      )}

      <SearchMaconomyCustomerDialog open={openSearch} onCancel={() => setOpenSearch(false)} onSubmit={handleCustomer} />
      <AddLicenseDialog open={Boolean(customer)} customer={customer} onClose={() => setCustomer(undefined)} />
      <AddLicenseDialog open={openDialog} onClose={() => setOpenDialog(false)} />
    </MainContentPanel>
  );
}
