import React from 'react';
import MainContentPanel from '../../components/MainContentPanel';
import { Typography, List, ListItem, Button } from '@mui/material';
import { useMutation } from '@apollo/client';
import { RENEW } from './LicenseInfo.graphql';

interface TestingPanelProps {
  licenseId: number;
}

export function TestingPanel({ licenseId }: TestingPanelProps) {
  const [renew] = useMutation(RENEW);
  return (
    <MainContentPanel>
      <Typography variant="h5">Testing Testing</Typography>
      <List>
        <ListItem>
          <Button variant="contained" color="secondary" onClick={(_) => renew({ variables: { input: { licenseId, notify: true } } })}>
            Varlse fornyelse
          </Button>
        </ListItem>
        <ListItem>
          <Button variant="contained" color="secondary" onClick={(_) => renew({ variables: { input: { licenseId, notify: false } } })}>
            Forny
          </Button>
        </ListItem>
      </List>
    </MainContentPanel>
  );
}
