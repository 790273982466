import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import { GET_LICENSES_FOR_TASK } from './Tasks.graphql';
import { useQuery } from '@apollo/client';

interface Props {
  open: boolean;
  licenseId?: number;
  onCancel: () => void;
  onSubmit: (data: any) => void;
}

export default function CreateTaskDialog({ licenseId, open, onCancel, onSubmit }: Props) {
  const [description, setDescription] = useState('');
  const [bksLicenseId, setBksLicenseId] = useState(licenseId);

  const cancel = () => {
    setDescription('');
    setBksLicenseId(licenseId);
    onCancel();
  };
  const submit = () => {
    setDescription('');
    setBksLicenseId(licenseId);
    onSubmit({ bksLicenseId, description });
  };

  return (
    <Dialog open={open} onClose={() => cancel()} maxWidth="sm" fullWidth>
      <DialogTitle>Opprett oppgave</DialogTitle>
      <DialogContent>
        <CustomerSelect visible={!Boolean(licenseId)} licenseId={bksLicenseId} setLicenseId={setBksLicenseId} />
        <TextField
          label="Beskrivelse"
          fullWidth
          value={description}
          onChange={(ev) => setDescription(ev.target.value)}
          multiline
          margin="normal"
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => cancel()}>Avbryt</Button>
        <Button variant="contained" color="primary" onClick={() => submit()}>
          Opprett
        </Button>
      </DialogActions>
    </Dialog>
  );

  interface CustomerSelectprops {
    visible: boolean;
    licenseId: number | undefined;
    setLicenseId: (id: number) => void;
  }
  function CustomerSelect({ licenseId, setLicenseId, visible }: CustomerSelectprops) {
    const { loading, data } = useQuery<{ licenses: any[] }>(GET_LICENSES_FOR_TASK);

    if (!visible) return null;

    if (loading || !data) return <TextField label="Henter kunder.." fullWidth />;

    return (
      <TextField
        label={'Kunde'}
        fullWidth
        select
        SelectProps={{ native: true }}
        value={licenseId}
        onChange={(ev) => setLicenseId(Number(ev.target.value))}
        required
      >
        <option key="none" value=""></option>
        {data.licenses.map((l) => (
          <option key={l.id} value={l.id}>
            {l.companyName}
          </option>
        ))}
      </TextField>
    );
  }
}
