import Moment from 'moment';

export let formatDate = (date, format?) => (date ? Moment(date).format(format || 'DD.MM.YYYY HH:mm') : '');
export let formatDateOnly = (date, format?) => (date ? Moment(date).format(format || 'DD.MM.YYYY') : '');

export function numberAsKr(amount?: number): string | undefined {
  if (!amount) return undefined;
  return amount.toLocaleString('nb-NO', { style: 'currency', currency: 'NOK', minimumFractionDigits: 2 });
}

export function numberAsPercentage(amount?: number): string | undefined {
  if (!amount) return undefined;
  return (amount / 100).toLocaleString('nb-NO', { style: 'percent' });
}

export function numberFormatted(amount?: number): string | undefined {
  if (!amount) return undefined;
  return amount.toLocaleString('nb-NO');
}
