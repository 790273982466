import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@apollo/client';
import { UPDATE_ORDER } from './Orders.graphql';
import { Order } from '../../interfaces';

interface Props {
  order: Order;
  open: boolean;
  title: string;
  orderNumber: number;
  deny: () => void;
  denyText?: string;
  DialogProps?: any;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 500,
  },
});

export default function ConfirmWithInvoiceDialog({ order, open, title, orderNumber, deny, denyText, DialogProps }: Props) {
  const [invoiceNumber, setInvoiceNumber] = useState(0);
  const [update] = useMutation(UPDATE_ORDER, { variables: { input: { id: order.id, invoiceNumber: invoiceNumber } } });
  const classes = useStyles();

  const commit = () => {
    update();
  };

  return (
    <Dialog open={open} onClose={deny} {...DialogProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography className={classes.root}>
          {`Vil du sette status for ${orderNumber}? 
                        Hvis fakturanummer finnes, oppdateres til Faktura sendt og pdf blir forsøkt hentet`}
        </Typography>
        <TextField label="Fakturanummer i Maconomy" onChange={(e) => setInvoiceNumber(Number(e.target.value))} fullWidth type="number" />
      </DialogContent>
      <DialogActions>
        <Button onClick={deny}>{denyText || 'Avbryt'}</Button>
        <Button variant="contained" color="primary" onClick={commit}>
          {'Oppdater'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
