import { useState } from 'react';
import { IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import AddClientAuthorizationDialog from './AddClientAuthorizationDialog';
export default function NewClientAuthorizationBtn({ id, scheme, bksID }: { id: Number; scheme: string; bksID: any }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <IconButton onClick={() => setOpen(true)} title="Opprett ny Client Authorization">
        <Add />
      </IconButton>
      {open && <AddClientAuthorizationDialog id={id} onClose={() => setOpen(false)} scheme={scheme} bksID={bksID} />}
    </>
  );
}
