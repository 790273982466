import { TextField } from '@mui/material';
import { useState } from 'react';
interface Prop {
  callbackValue: (name: string) => void;
  existingTenant: string;
}
export default function TenantID(prop: Prop) {
  const { callbackValue: sendbackTenantID, existingTenant } = prop;
  const [tenantID, setTenantID] = useState(existingTenant);
  const handleTenantIDBlur = (id: string) => {
    sendbackTenantID(id);
  };
  return (
    <>
      <TextField
        label="Tenant ID"
        fullWidth
        value={tenantID}
        type="text"
        onChange={(event) => setTenantID(event.target.value)}
        onBlur={(event) => handleTenantIDBlur(event.target.value)}
        margin="normal"
        required
      />
    </>
  );
}
