import { TableCell, TableBody, Table, TableRow } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import WidgetPanel from './WidgetPanel';
import { WidgetTitle } from './WidgetTitle';
import makeStyles from '@mui/styles/makeStyles';
import LoadingSpinner from '../components/LoadingSpinner';
import { gql, useQuery } from '@apollo/client';
import { numberAsKr } from '../Formatters';
import { useState } from 'react';

const GET_SALES_DISTRIBUTION = gql`
  query ($monthOffset: Int!) {
    sales: getSalesDistribution(monthOffset: $monthOffset) {
      id
      name
      total
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  total: {
    fontWeight: 'bold',
  },
  panel: {},
  divider: {
    marginTop: 5,
  },
  btn: {
    minWidth: 24,
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export function SalesDistribution() {
  const classes = useStyles();

  const [monthOffset, setMonthOffset] = useState(0); // get statistics for current month

  const { loading, data } = useQuery(GET_SALES_DISTRIBUTION, { variables: { monthOffset: monthOffset }, fetchPolicy: 'network-only' });

  const colors = Array(23);
  colors[9] = '#71B1CC';
  colors[10] = '#287891';
  colors[11] = '#52823b';
  colors[12] = '#81710a';
  colors[16] = '#1181C4';
  colors[22] = '#830404';

  return (
    <div className={classes.panel}>
      <WidgetPanel>
        <WidgetTitle
          title="Salgsfordeling i"
          offset={monthOffset}
          forwardLimit={1}
          yearNav={true}
          stateSetter={(step) => {
            setMonthOffset(monthOffset + step);
          }}
        />

        {loading && <LoadingSpinner />}
        {!loading && !data && <div>Mangler data</div>}
        {data && (
          <div>
            <ResponsiveContainer width="100%" height={200}>
              <PieChart width={400} height={400}>
                <Pie data={data.sales} dataKey="total" nameKey="name" cx="50%" cy="50%" outerRadius={80}>
                  {data.sales.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[entry.id]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            <Table padding="none">
              <TableBody>
                {data.sales ? (
                  data.sales.map((x) => (
                    <TableRow key={x.id}>
                      <TableCell>
                        <span style={{ color: colors[x.id] }}>&#9646;</span> {x.name}:
                      </TableCell>
                      <TableCell align="right">{numberAsKr(x.total)}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableCell>no data</TableCell>
                )}
              </TableBody>
            </Table>
          </div>
        )}

        {/* <LinkToDetails>Se mer</LinkToDetails> */}
      </WidgetPanel>
    </div>
  );
}
