import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { ArrowDownward } from '@mui/icons-material';
import { DOWNGRADE_SUBSCRIPTION, GET_SUBSCRIPTIONS } from './UsersInLicense.graphql';
import { useMutation } from '@apollo/client';
import { Subscription, LicenseSubscription } from '../../interfaces';
import DowngradeSubscriptionDialog from './DowngradeSubscriptionDialog';

export function DowngradeSubscriptionButton({ access, childSubscriptions }: { access: LicenseSubscription; childSubscriptions: Subscription[] }) {
  const [open, setOpen] = useState(false);
  const [downgradeSubscription] = useMutation(DOWNGRADE_SUBSCRIPTION);
  const submit = (subscriptions: number[], effectuateNow?: boolean) => {
    downgradeSubscription({
      variables: {
        input: {
          id: access.licenseId,
          fromSubscriptionId: access.subscription.id,
          toSubscriptionIds: subscriptions,
          effectuateNow,
        },
      },
      update(cache) {
        if (!effectuateNow) return;
        const queryVariable = { id: access.licenseId };
        const result = cache.readQuery({ query: GET_SUBSCRIPTIONS, variables: queryVariable }) as any;
        const newSubscriptions = subscriptions.map((x) => {
          return { ...access, id: `${access.licenseId}:${x}`, subscription: childSubscriptions.find((c) => c.id === x) };
        });

        cache.writeQuery({
          query: GET_SUBSCRIPTIONS,
          variables: queryVariable,
          data: {
            subscriptions: result.subscriptions.filter((x) => x.subscription.id !== access.subscription.id).concat(newSubscriptions),
            paidSubscriptions: result.paidSubscriptions,
            license: result.license,
          },
        });
      },
    });
    setOpen(false);
  };
  return (
    <>
      <IconButton onClick={() => setOpen(true)} title="Nedgradér" size="large">
        <ArrowDownward />
      </IconButton>
      <DowngradeSubscriptionDialog
        open={open}
        childSubscriptions={childSubscriptions}
        onCancel={() => setOpen(false)}
        onSubmit={(s, e) => submit(s, e)}
      />
    </>
  );
}
