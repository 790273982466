import React from 'react';
import { ContactForm } from './ContactForm';
import { ADFSOverview } from './ADFSConfig/ADFSOverview';

export function Overview() {
  return (
    <>
      <ContactForm />
      <ADFSOverview />
    </>
  );
}
