import { useState, useCallback } from 'react';

import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import {
  Button,
  InputAdornment,
  TextField,
  CircularProgress,
  Box,
  Stack,
  Alert,
  AlertTitle,
  IconButton,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
  Avatar,
} from '@mui/material';
import { Check, Block, Close, Delete, Key, ContentCopy } from '@mui/icons-material';
import { debounce } from 'throttle-debounce';
import Theme from '../../Theme';
import LoadingSpinner from '../../components/LoadingSpinner';
import { formatDate } from '../../Formatters';
import ConfirmDialog from '../../components/ConfirmDialog';

interface Props {
  licenseModelId: string;
}

const LicenseApiClientsItem = gql`
  fragment LicenseApiClientsItem on LicenseApiClientsModel {
    id
    apiClients {
      clientId
      createdAt
    }
  }
`;

const CHECK_API_CLIENT_AVAILABILITY = gql`
  query ($clientId: String!) {
    checkApiClientAvailability(clientId: $clientId) {
      isAvailable
    }
  }
`;

const GET_API_CLIENTS = gql`
  query ($licenseId: ID!) {
    getLicenseApiClients(licenseId: $licenseId) {
      ...LicenseApiClientsItem
    }
  }
  ${LicenseApiClientsItem}
`;

const ADD_API_CLIENT = gql`
  mutation ($input: AddApiClientInput!) {
    addApiClient(input: $input) {
      clientId
      clientSecret
      licenseApiClientsModel {
        ...LicenseApiClientsItem
      }
    }
  }
  ${LicenseApiClientsItem}
`;

const REMOVE_API_CLIENT = gql`
  mutation ($input: RemoveApiClientInput!) {
    removeApiClient(input: $input) {
      ...LicenseApiClientsItem
    }
  }
  ${LicenseApiClientsItem}
`;

interface LicenseApiClientsModel {
  id: string;
  apiClients: {
    clientId: string;
    createdAt: string;
  }[];
}

interface AddApiClientResponse {
  clientId: string;
  clientSecret: string;
  licenseApiClientsModel: LicenseApiClientsModel;
}

interface ClientAvailability {
  checkApiClientAvailability: {
    isAvailable: boolean;
  };
}

export function ApiAccess({ licenseModelId }: Props) {
  const [showConfirmDialogForClient, setShowConfirmDialogForClient] = useState<string | undefined>(undefined);
  const [clientId, setClientId] = useState<string>('');
  const [addApiClientResponse, setAddApiClientResponse] = useState<AddApiClientResponse | undefined>(undefined);
  const [addApiClient, { loading: isAdding }] = useMutation<{ addApiClient: AddApiClientResponse }>(ADD_API_CLIENT, {
    onCompleted: (data) => {
      setClientId('');
      setAddApiClientResponse(data.addApiClient);
    },
  });
  const [removeApiClient, { loading: isRemoving }] = useMutation(REMOVE_API_CLIENT);
  const [isCheckingAvailability, setIsCheckingAvailability] = useState<boolean>(false);
  const { data, loading, refetch } = useQuery<{ getLicenseApiClients: LicenseApiClientsModel | null }>(GET_API_CLIENTS, {
    variables: { licenseId: licenseModelId },
  });
  const [checkApiClientAvailability, { data: clientAvailability }] = useLazyQuery<ClientAvailability>(CHECK_API_CLIENT_AVAILABILITY, {
    fetchPolicy: 'no-cache',
  });

  const isClientIdAvailable = clientAvailability && !isCheckingAvailability ? clientAvailability?.checkApiClientAvailability.isAvailable : false;
  const checkApiClientAvailabilityDebounced = useCallback(
    debounce(1000, async (value) => {
      await checkApiClientAvailability({ variables: { clientId: `bfs_${value}` } });
      setIsCheckingAvailability(false);
    }),
    [],
  );

  const onAddApiClientClick = async () => {
    setAddApiClientResponse(undefined);
    await addApiClient({ variables: { input: { licenseId: licenseModelId, clientId: `bfs_${clientId}` } } });
    refetch();
  };
  const onRemoveApiClientClick = async (clientId: string) => {
    setShowConfirmDialogForClient(clientId);
  };

  const onRemoveApiClientConfirmed = async (clientId: string) => {
    setShowConfirmDialogForClient(undefined);
    await removeApiClient({ variables: { input: { licenseId: licenseModelId, clientId } } });
    setClientId('');
    setAddApiClientResponse(undefined);
    refetch();
  };

  return (
    <Box sx={{ maxWidth: '550px' }}>
      <Stack sx={{ marginTop: 2, marginBottom: 2 }} gap={1}>
        <div>
          Her kan du administrere API-tilganger til{' '}
          <a href="https://api.byggforsk.no" target="_blank">
            Byggforskserien API
          </a>
          .
        </div>
        <Typography variant="h6">Legg til ny tilgang</Typography>

        <Stack direction="row" gap={2}>
          <TextField
            placeholder="unikt_klientnavn"
            size="small"
            onChange={(ev) => {
              setClientId(ev.target.value);
              if (!ev.target.value) return;
              setIsCheckingAvailability(true);
              checkApiClientAvailabilityDebounced(ev.target.value);
            }}
            value={clientId}
            InputProps={{
              startAdornment: (
                <InputAdornment disableTypography position="start" sx={{ margin: 0, color: Theme.palette.text.primary }}>
                  bfs_
                </InputAdornment>
              ),
              endAdornment: (
                <Box sx={{ marginTop: '2px' }}>
                  {isCheckingAvailability && <CircularProgress size={20} />}
                  {!isCheckingAvailability && !!clientId && (isClientIdAvailable ? <Check color="success" /> : <Block color="error" />)}
                </Box>
              ),
            }}
          />
          <Button variant="contained" disabled={!isClientIdAvailable || isAdding || !clientId} onClick={onAddApiClientClick}>
            Legg til klient
          </Button>
        </Stack>
        <Collapse in={!!addApiClientResponse}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAddApiClientResponse(undefined);
                }}>
                <Close fontSize="inherit" />
              </IconButton>
            }>
            <AlertTitle>API-klient opprettet!</AlertTitle>
            Noter ned client secret. Den vil ikke vises igjen.
            <br />
            Client ID: <strong>{addApiClientResponse?.clientId}</strong>
            <IconButton
              size="small"
              title="Kopier til utklippstavle"
              onClick={() => {
                navigator.clipboard.writeText(addApiClientResponse?.clientId ?? '');
              }}>
              <ContentCopy fontSize="small" />
            </IconButton>
            <br />
            Client secret: <strong>{addApiClientResponse?.clientSecret}</strong>{' '}
            <IconButton
              size="small"
              title="Kopier til utklippstavle"
              onClick={() => {
                navigator.clipboard.writeText(addApiClientResponse?.clientSecret ?? '');
              }}>
              <ContentCopy fontSize="small" />
            </IconButton>
          </Alert>
        </Collapse>
      </Stack>

      {loading && <LoadingSpinner />}
      <Typography variant="h6">Aktive tilganger</Typography>
      {!data?.getLicenseApiClients?.apiClients && <Alert severity="info">Det er ikke lagt opp noen API-tilganger for denne lisensen</Alert>}
      <List sx={{ maxWidth: '400px' }} dense>
        {data?.getLicenseApiClients?.apiClients?.map((client) => (
          <ListItem
            key={`client_${client.clientId}`}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                disabled={isRemoving}
                title="Fjern klient"
                onClick={() => onRemoveApiClientClick(client.clientId)}>
                <Delete />
              </IconButton>
            }>
            <ListItemAvatar>
              <Avatar>
                <Key />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={client.clientId} secondary={`Opprettet ${formatDate(client.createdAt)}`} />
          </ListItem>
        ))}
      </List>
      <ConfirmDialog
        open={!!showConfirmDialogForClient}
        title={`Fjerne API-tilgang`}
        message="Er du sikker på at du vil fjerne denne API-tilgangen?"
        confirmText="Fjern"
        deny={() => setShowConfirmDialogForClient(undefined)}
        confirm={() => onRemoveApiClientConfirmed(showConfirmDialogForClient ?? '')}
      />
    </Box>
  );
}
