import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { AdfsSetting } from '../../interfaces';
import { Table, TableRow, TableCell, TableBody, TableHead } from '@mui/material';
import { GET_ADFS } from './ADFSConfig.graphql';
import Scheme from './Scheme';
import Fuse from 'fuse.js';
import { SearchField } from '../../components/SearchField';
export default function ADFSList() {
  const queryTask = useQuery<{ adfs: AdfsSetting[] }>(GET_ADFS);
  const queryData = queryTask && queryTask.data && queryTask.data.adfs;
  const orderByAlphabet = (array) => {
    const items = array && [...array];
    const result =
      items &&
      items.sort(function (a, b) {
        var nameA = a && a.scheme && a.scheme.toLowerCase(),
          nameB = b && b.scheme && b.scheme.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
    return result;
  };
  const result = orderByAlphabet(queryData);
  let [searchTerm, setSearchTerm] = useState('');
  const filterResult = (AdfsSetting: AdfsSetting[]) => {
    var options = {
      shouldSort: false,
      tokenize: true,
      matchAllTokens: true,
      threshold: 0,
      location: 0,
      distance: 10,
      maxPatternLength: 32,
      minMatchCharLength: 3,
      keys: ['scheme'],
    };
    if (!searchTerm) {
      return result;
    } else {
      var fuse = new Fuse(result, options);
      var filtered = fuse.search(searchTerm);
      return filtered;
    }
  };
  const filteredResult: AdfsSetting[] = filterResult(result);
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <SearchField filter={setSearchTerm} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table padding="normal">
        <TableHead>
          <TableRow>
            <TableCell>Provider</TableCell>
            <TableCell>Ekstra tillgang(%)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredResult &&
            filteredResult.map((x) => {
              return <Scheme provider={x} key={x.id} />;
            })}
        </TableBody>
      </Table>
    </>
  );
}
