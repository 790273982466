export enum OrderType {
  NA = 'NA',
  Card = 'Card',
  Invoice = 'Invoice',
}

export enum OrderStatus {
  Created = 'Created',
  Cancelled = 'Cancelled',
  Failed = 'Failed',
  PaymentStarted = 'PaymentStarted',
  Completed = 'Completed',
  Fullfilled = 'Fullfilled',
  Open = 'Open',
  Credited = 'Credited',
  Reserved = 'Reserved',
}

export enum DiscountType {
  Percentage = 'Percentage',
  Fixed = 'Fixed',
}

export enum PaymentMode {
  Automatic = 'Automatic',
  Manual = 'Manual',
}
