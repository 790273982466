import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';
import App from './App';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import theme from './Theme';
import getConfig from './config';

import { ApolloLink, ApolloProvider, createHttpLink } from '@apollo/client';

import { onError } from '@apollo/client/link/error';
import 'moment/locale/nb';
import log from 'loglevel';
import { getToken, refreshToken } from './authentication/OidcClient';
import AuthProvider from './authentication/AuthProvider';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { LicenseInfo } from '@mui/x-data-grid-pro';

LicenseInfo.setLicenseKey('d6b9a3909ff8d97f2bef557807a0f6d9Tz04NTI1NyxFPTE3NDA3NDY1MTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

async function initialize() {
  const config = await getConfig();

  const httpLink = createHttpLink({ uri: `${config.apiUrl}/GraphQL` });

  const authMiddleware = new ApolloLink((operation, forward) => {
    var token = getToken();
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return forward(operation);
  });

  const errorLink = onError((error: any) => {
    let { networkError } = error;

    if (networkError && networkError.statusCode === 401) {
      refreshToken();
    }
  });

  const client = new ApolloClient({
    link: errorLink.concat(authMiddleware).concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            getUsers: {
              merge(existing, incoming) {
                return incoming;
              },
            },
          },
        },
      },
    }),
  });

  log.setDefaultLevel('debug');

  function ThemedApp() {
    return (
      <ApolloProvider client={client}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <AuthProvider>
              <App />
            </AuthProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </ApolloProvider>
    );
  }

  ReactDOM.render(<ThemedApp />, document.getElementById('root'));
}

initialize();
