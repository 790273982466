import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableBody,
  IconButton,
  CircularProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { RenewalInfo } from './Overview';
import { numberAsKr } from '../Formatters';
import { Moment } from 'moment';
import { useQuery } from '@apollo/client';
import { GET_RENEWALS_MACONOMY } from './renewals.graphql';
import { Difference, Error } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  ours: {
    color: theme.palette.primary.main,
  },
  theirs: {
    color: theme.palette.error.main,
  },
  stacked: {
    position: 'absolute',
  },
  spinnerContainer: {
    marginLeft: 100,
    marginTop: 5,
  },
}));

export function DiffMaconomyApi({ renewals, date }: { renewals: RenewalInfo[]; date: Moment }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && <DiffMaconomyDialog onClose={() => setOpen(false)} renewals={renewals} date={date} />}
      <IconButton color="secondary" onClick={(_) => setOpen(true)} title="Sammenlign med Maconomy">
        <Difference />
      </IconButton>
    </>
  );
}

interface Diff {
  ours?: number;
  theirs?: number;
}

interface DiffItem {
  subscriptionNumber: string;
  licenseId: number;
  name: string;
  bfs: Diff;
  bfsp: Diff;
  bfsb: Diff;
  bfsf: Diff;
  bfsu: Diff;
  bvn: Diff;
}

function DiffMaconomyDialog({ renewals, onClose, date }: { renewals: RenewalInfo[]; onClose(): void; date: Moment }) {
  const classes = useStyles();
  const month = date.month() + 1;
  const year = date.year();

  const { loading, error, data } = useQuery<{ renewals: RenewalInfo[] }>(GET_RENEWALS_MACONOMY, { variables: { month, year } });
  if (loading) return <CircularProgress size={15} />;
  if (error) return <Error color="error" fontSize="small" titleAccess={error.message} />;
  if (!data) return null;

  const items = data.renewals;

  const array: DiffItem[] = [];
  renewals.forEach((element) => {
    const maconomyItem = items.find((x) => x.subscriptionOrderNumber === element.subscriptionOrderNumber);

    array.push({
      subscriptionNumber: element.subscriptionOrderNumber,
      name: element.name,
      licenseId: element.licenseId,
      bfs: { ours: element.bfs, theirs: maconomyItem?.bfs },
      bfsp: { ours: element.bfsp, theirs: maconomyItem?.bfsp },
      bfsb: { ours: element.bfsb, theirs: maconomyItem?.bfsb },
      bfsf: { ours: element.bfsf, theirs: maconomyItem?.bfsf },
      bfsu: { ours: element.bfsu, theirs: maconomyItem?.bfsu },
      bvn: { ours: element.bvn, theirs: maconomyItem?.bvn },
    });
  });

  const missing = items.filter((x) => renewals.find((r) => r.subscriptionOrderNumber === x.subscriptionOrderNumber) === undefined);

  missing.forEach((item) => {
    array.push({
      subscriptionNumber: item.subscriptionOrderNumber,
      name: item.name,
      licenseId: item.licenseId,
      bfs: { theirs: item.bfs },
      bfsp: { theirs: item.bfsp },
      bfsb: { theirs: item.bfsb },
      bfsf: { theirs: item.bfsf },
      bfsu: { theirs: item.bfsu },
      bvn: { theirs: item.bvn },
    });
  });

  const diffs = array.filter((x) => hasDiff(x.bfs) || hasDiff(x.bfsp) || hasDiff(x.bfsb) || hasDiff(x.bfsf) || hasDiff(x.bfsu) || hasDiff(x.bvn));

  return (
    <Dialog open onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>Avvik i Maconomy ({date.format('MMMM YYYY')})</DialogTitle>
      <DialogContent>
        <div>
          <span className={classes.ours}>Våre tall</span>
          <span className={classes.theirs}> Deres tall</span>
        </div>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Kunde</TableCell>
              <TableCell>Abonnement</TableCell>
              <TableCell align="right">Komplett</TableCell>
              <TableCell align="right">Planlegging</TableCell>
              <TableCell align="right">Byggdetaljer</TableCell>
              <TableCell align="right">Byggforvaltning</TableCell>
              <TableCell align="right">Utførelse</TableCell>
              <TableCell align="right">BVN</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {diffs.map((s) => {
              return (
                <TableRow key={`${s.subscriptionNumber}:${s.name}`}>
                  <TableCell>{s.licenseId ? <Link to={'/license/' + s.licenseId}>{s.name}</Link> : s.name}</TableCell>
                  <TableCell>{s.subscriptionNumber}</TableCell>
                  <DiffCell diff={s.bfs} />
                  <DiffCell diff={s.bfsp} />
                  <DiffCell diff={s.bfsb} />
                  <DiffCell diff={s.bfsf} />
                  <DiffCell diff={s.bfsu} />
                  <DiffCell diff={s.bvn} />
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Lukk</Button>
      </DialogActions>
    </Dialog>
  );
}

function DiffCell({ diff }: { diff: Diff }) {
  const classes = useStyles();

  if (!hasDiff(diff)) return <TableCell />;
  return (
    <TableCell align="right">
      <span className={classes.ours}>{numberAsKr(diff.ours)}</span>
      <span className={classes.theirs}>{numberAsKr(diff.theirs)}</span>
    </TableCell>
  );
}

function hasDiff(a: Diff): boolean {
  if (!a.ours && !a.theirs) return false;

  return roundToTwo(a.ours) !== roundToTwo(a.theirs);
}

function roundToTwo(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}
