import React, { useState } from 'react';
import { Typography, IconButton, List, ListItem, ListItemText, ListItemAvatar, Avatar, ListItemSecondaryAction, Tooltip } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import { formatDate } from '../../Formatters';
import moment from 'moment';
import { GET_COMMENTS, ADD_COMMENT, REMOVE_COMMENT, UPDATE_COMMENT } from './Comments.graphql';
import MainContentPanel from '../../components/MainContentPanel';
import { UpdateValueDialog } from '../../components/UpdateValueDialog';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useMutation, useQuery } from '@apollo/client';

interface CommentsProps {
  licenseId: number;
}

interface CommentsData {
  comments: CommentInterface[];
}

interface CommentsVars {
  licenseId: number;
}

function Comments({ licenseId }: CommentsProps) {
  const [comment, setComment] = useState<CommentInterface | undefined>(undefined);

  const { loading, data } = useQuery<CommentsData, CommentsVars>(GET_COMMENTS, { variables: { licenseId } });

  const [addComment] = useMutation<{ licenseAddComment: CommentInterface }>(ADD_COMMENT, {
    update(cache, { data }) {
      if (!data) return;
      const queryData = cache.readQuery<CommentsData, CommentsVars>({ query: GET_COMMENTS, variables: { licenseId } });
      if (queryData == null) return;
      cache.writeQuery({
        query: GET_COMMENTS,
        variables: { licenseId },
        data: { comments: queryData.comments.concat([data.licenseAddComment]) },
      });
    },
  });

  if (loading || !data)
    return (
      <MainContentPanel>
        <LoadingSpinner />
      </MainContentPanel>
    );

  return (
    <MainContentPanel>
      <>
        <Typography variant="h5" component="h3">
          Kommentarer
          <IconButton onClick={() => setComment({} as CommentInterface)} aria-label="Ny kommentar" size="large">
            <Add />
          </IconButton>
        </Typography>
        <UpdateValueDialog
          open={comment !== undefined}
          value=""
          title="Ny kommentar"
          label="Kommentar"
          multiline={true}
          onClose={() => setComment(undefined)}
          update={(text) => addComment({ variables: { input: { id: licenseId, comment: text } } })}
        />
        <CommentList comments={data.comments} licenseId={licenseId} />
      </>
    </MainContentPanel>
  );
}

interface CommentListProps {
  licenseId: number;
  comments: CommentInterface[];
}

interface CommentInterface {
  id: string;
  comment: string;
  createdByName: string;
  createdAt: string;
  lastUpdatedByName: string;
  lastUpdatedAt: string;
  isLocked: boolean;
}

function CommentList({ licenseId, comments }: CommentListProps) {
  const [commentToEdit, setCommentToEdit] = useState<CommentInterface | undefined>(undefined);
  const [updateComment] = useMutation(UPDATE_COMMENT);
  const [removeComment] = useMutation(REMOVE_COMMENT, {
    update(cache, { data: { licenseRemoveComment } }) {
      const data = cache.readQuery<CommentsData, CommentsVars>({ query: GET_COMMENTS, variables: { licenseId } });
      if (data == null) return;
      cache.writeQuery({
        query: GET_COMMENTS,
        variables: { licenseId },
        data: { comments: data.comments.filter((c) => c.id !== licenseRemoveComment.id) },
      });
    },
  });

  const arrayForSort = [...comments];

  return (
    <>
      {commentToEdit && (
        <UpdateValueDialog
          open={true}
          value={commentToEdit.comment}
          title="Endre kommentar"
          label="Kommentar"
          multiline={true}
          onClose={() => setCommentToEdit(undefined)}
          update={(text) => updateComment({ variables: { input: { id: licenseId, commentId: commentToEdit.id, comment: text } } })}
        />
      )}

      <List>
        {arrayForSort.sort(sortComments).map((c) => (
          <ListItem key={c.id}>
            <ListItemAvatar style={{ alignSelf: 'flex-start' }}>
              <CommentAuthor comment={c} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography component="pre" style={{ whiteSpace: 'pre-wrap' }}>
                  {c.comment}
                </Typography>
              }
              secondary={<CommentInfo comment={c} />}
            />
            {!c.isLocked && (
              <ListItemSecondaryAction>
                <Tooltip id="tooltip-icon" title="Endre" placement="top">
                  <IconButton onClick={() => setCommentToEdit(c)} size="large">
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip id="tooltip-icon" title="Fjern" placement="top">
                  <IconButton onClick={() => removeComment({ variables: { input: { id: licenseId, commentId: c.id } } })} size="large">
                    <Delete />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>
    </>
  );
}

interface CommentProps {
  comment: CommentInterface;
}

function CommentAuthor({ comment }: CommentProps) {
  const initials = comment.createdByName
    .split(' ')
    .map((x) => x[0])
    .join('');
  return <Avatar>{initials}</Avatar>;
}

function CommentInfo({ comment }: CommentProps) {
  const createdInfo = `${comment.createdByName} ${formatDate(comment.createdAt)}`;
  const text = comment.lastUpdatedAt
    ? createdInfo + ` - Sist endret av ${comment.lastUpdatedByName} ${formatDate(comment.lastUpdatedAt)}`
    : createdInfo;
  return <span>{text}</span>;
}

function sortComments(a: CommentInterface, b: CommentInterface) {
  var date1 = a.lastUpdatedAt || a.createdAt;
  var date2 = b.lastUpdatedAt || b.createdAt;
  if (moment(date2).isAfter(date1)) {
    return 1;
  }
  if (moment(date1).isAfter(date2)) {
    return -1;
  }
  return 0;
}

export default Comments;
