import { useState } from 'react';
import { Paper, Grid, TextField, Button, Checkbox, FormControlLabel } from '@mui/material';
import { UPDATE_GENERAL_SETTING, GET_ADFS } from './ADFSConfig.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { AdfsSetting } from '../../interfaces';
import { useHistory } from 'react-router-dom';
import { GET_SINGLE_ADFS } from './ADFSConfig.graphql';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Snackbar, IconButton, SnackbarContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import LoadingSpinner from '../../components/LoadingSpinner';
import ProviderName from './ProviderName';
import EmailDomain from './EmailDomain';
import TenantID from './TenantID';
import ClientID from './ClientID';
import ClientSecret from './ClientSecret';
import { changeDateFormat, convertArrtoCommaSeparetdString } from './DomainUtils';
import ExtraUser from './ExtraUser';

const useStyles = makeStyles((theme) => ({
  snackbarStyleViaNestedContent: {
    backgroundColor: 'black',
    color: 'white',
  },
  updateButton: {
    marginLeft: 15,
  },
}));
export default function GeneralSettings(props) {
  const classes = useStyles();
  const adfsSettings: AdfsSetting = props.location.state && props.location.state.adfs;
  let emailDomains = convertArrtoCommaSeparetdString(adfsSettings && adfsSettings.emailDomains);
  const existingExtraUserPercentage = adfsSettings && adfsSettings.extraUserPercentage;
  const isRestricted = adfsSettings && adfsSettings.isRestricted;
  const [extraUserPercentage, setExtraUserPercentage] = useState(existingExtraUserPercentage);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(isRestricted);

  const bksID = adfsSettings && adfsSettings.bksID;
  const tenantIDValue = adfsSettings && adfsSettings.tenantID ? adfsSettings.tenantID : '';
  const [tenantID, setTenantID] = useState(tenantIDValue);
  const clientIDValue = adfsSettings && adfsSettings.clientID ? adfsSettings.clientID : '';
  const [clientID, setClientID] = useState(clientIDValue);
  const clientSecretValue = adfsSettings && adfsSettings.clientSecret ? adfsSettings.clientSecret : '';
  const [clientSecret, setClientSecret] = useState(clientSecretValue);
  const defaultTimeOfChange = new Date('0001-01-01');
  let dateObj = defaultTimeOfChange;
  if (adfsSettings && adfsSettings.expiryDate) {
    dateObj = new Date(changeDateFormat(adfsSettings && adfsSettings.expiryDate));
  }
  const existingProvider = adfsSettings && adfsSettings.scheme ? adfsSettings.scheme : '';
  const existingDomain = adfsSettings && adfsSettings.scheme ? adfsSettings.emailDomains : [];
  const existingTenant = adfsSettings && adfsSettings.tenantID ? adfsSettings.tenantID : '';
  const existingId = adfsSettings && adfsSettings.clientID ? adfsSettings.clientID : '';
  const existingSecret = adfsSettings && adfsSettings.clientSecret ? adfsSettings.clientSecret : '';
  const displayNameValue = adfsSettings && adfsSettings.displayName ? adfsSettings.displayName : '';
  const [expiryDate, setExpiryDate] = useState(dateObj);
  const [providerName, setProviderName] = useState(existingProvider);
  const [displayName, setDisplayName] = useState(displayNameValue);
  const [emailDomain, setEmailDomain] = useState(emailDomains);
  const [isErrorOnProviderName, setIsErrorOnProviderName] = useState(false);
  const [isErrorOnEmailDomain, setIsErrorOnEmailDomain] = useState(false);
  const ID = adfsSettings && adfsSettings.id;
  const isFormValid = () => {
    return tenantID && clientID && clientSecret && expiryDate && providerName && emailDomain && isExtraUserValid();
  };

  const isExtraUserValid = () => {
    if (isCheckboxChecked) {
      return extraUserPercentage > 0; // Validate ExtraUser only if checkbox is checked
    }
    return true; // No need to validate ExtraUser if checkbox is unchecked
  };
  const handleExtraUserChange = (value) => {
    setExtraUserPercentage(value);
  };
  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setIsCheckboxChecked(checked);
    if (!checked) {
      setExtraUserPercentage(0);
      setIsCheckboxChecked(false); // Set extraUserPercentage to 1 when checkbox is checked
    }
  };
  const [updateGeneralSetting, { loading: loadingCreate }] = useMutation(UPDATE_GENERAL_SETTING, {
    update: (cache, { data: { updateGeneralSetting } }) => {
      if (!updateGeneralSetting) return;
      const data = cache.readQuery<{ adfs: AdfsSetting }>({
        query: GET_SINGLE_ADFS,
        variables: { ID },
      });
      if (data === null) return;
      cache.writeQuery({
        query: GET_SINGLE_ADFS,
        variables: { ID },
        data: { adfs: updateGeneralSetting },
      });
    },
  });
  const allAdfs = useQuery<{ adfs: AdfsSetting[] }>(GET_ADFS);
  const result = allAdfs && allAdfs.data && allAdfs.data.adfs;
  const handleUpdateAdfs = () =>
    updateGeneralSetting({
      variables: {
        input: {
          id: ID,
          tenantID: tenantID,
          clientID: clientID,
          clientSecret: clientSecret,
          expiryDate: expiryDate,
          scheme: providerName,
          displayName: displayName,
          emailDomains: emailDomain,
          bksID: bksID,
          extraUserPercentage: extraUserPercentage,
          isRestricted: isCheckboxChecked,
        },
      },
    });
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const handleCloseSnackbar = () => setShowErrorSnackbar(false);
  const updateSnackbarStatus = () => {
    setShowErrorSnackbar(true);
  };
  let history = useHistory();
  const handleCloseGeneralSetting = () => {
    history.goBack();
  };
  const handleUpdate = async () => {
    if (!isErrorOnProviderName && !isErrorOnEmailDomain) {
      await handleUpdateAdfs();
      updateSnackbarStatus();
      backToHistoryWithDelay(1000);
    }
  };
  const backToHistoryWithDelay = (time: number) => {
    setTimeout(function () {
      history.goBack();
    }, time);
  };
  const onTimeOfChangeChanged = (value: moment.Moment | null) => {
    if (value) setExpiryDate(new Date(value.toString()));
    else setExpiryDate(defaultTimeOfChange);
  };
  const callbackProviderName = (name: string) => {
    setProviderName(name);
  };
  const callbackProviderNameError = (errorStatus: boolean) => {
    setIsErrorOnProviderName(errorStatus);
  };
  const callbackEmailDomain = (domain: string) => {
    setEmailDomain(domain);
  };
  const callbackEmailDomainError = (errorStatus: boolean) => {
    setIsErrorOnEmailDomain(errorStatus);
  };
  const callbackTenantID = (tenantID: string) => {
    setTenantID(tenantID);
  };
  const callbackClientID = (clientID: string) => {
    setClientID(clientID);
  };
  const callbackClientSecret = (clientSecret: string) => {
    setClientSecret(clientSecret);
  };
  return (
    <>
      <Paper elevation={1}>
        <Grid container spacing={1} direction="row" style={{ padding: 20 }}>
          <Grid item xs={12}>
            {' '}
            <h2>Generelle instillinger</h2>{' '}
          </Grid>
          <Grid item xs={12}>
            <h3>{adfsSettings && adfsSettings.scheme}</h3>
          </Grid>
          <Grid item xs={12}>
            <ProviderName
              result={result}
              callbackValue={callbackProviderName}
              callbackError={callbackProviderNameError}
              existingName={existingProvider}
            />
          </Grid>
          <Grid item xs={12}>
            <TenantID callbackValue={callbackTenantID} existingTenant={existingTenant} />
          </Grid>
          <Grid item xs={12}>
            <ClientID callbackValue={callbackClientID} existingId={existingId} />
          </Grid>
          <Grid item xs={12}>
            <ClientSecret callbackValue={callbackClientSecret} existingSecret={existingSecret} />
          </Grid>
          <Grid item xs={12}>
            <EmailDomain
              result={result}
              callbackValue={callbackEmailDomain}
              callbackError={callbackEmailDomainError}
              id={ID}
              domain={existingDomain}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Display Name"
              fullWidth
              value={displayName}
              type="text"
              onChange={(event) => setDisplayName(event.target.value)}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={isCheckboxChecked} onChange={handleCheckboxChange} />}
              label="Implement extra user percentage for this ADFS?"
            />
          </Grid>
          <Grid item xs={12}>
            <ExtraUser initialValue={extraUserPercentage} onChange={handleExtraUserChange} disabled={!isCheckboxChecked} />
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="ClientSecret Expiry Date (customer should renew their client secret in ADFS before this date and should update in our system.)"
                value={expiryDate}
                disablePast
                showTodayButton
                onChange={onTimeOfChangeChanged}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <Button onClick={handleCloseGeneralSetting} color="secondary" variant="contained">
              Avbryt
            </Button>
            <Button color="primary" variant="contained" disabled={!isFormValid()} onClick={handleUpdate} className={classes.updateButton}>
              ENDRE
            </Button>
            {loadingCreate && <LoadingSpinner size={20} />}
          </Grid>
          <Grid item xs={12}>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={showErrorSnackbar}
              autoHideDuration={3000}
              onClose={handleCloseSnackbar}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
            >
              <SnackbarContent
                aria-describedby="message-id2"
                className={classes.snackbarStyleViaNestedContent}
                message={
                  <span id="message-id2">
                    <div>Endringene er lagert</div>
                  </span>
                }
                action={[
                  <IconButton key="close" aria-label="close" color="inherit" onClick={handleCloseSnackbar} size="large">
                    <Close />
                  </IconButton>,
                ]}
              />
            </Snackbar>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
