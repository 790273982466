import React from 'react';
import {
  TextField,
  Button,
  Typography,
  Grid,
  Divider,
  Switch,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  List,
  ListItem,
  ListItemText,
  FormGroup,
  Checkbox,
  FormHelperText,
} from '@mui/material';

import { CREATE_DISCOUNT, GET_SUBSCRIPTIONS, GET_DISCOUNTS } from '../discount.graphql';
import { useState } from 'react';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/lab';
import MomentUtils from '@date-io/moment';
import MainContentPanel from '../../components/MainContentPanel';
import { Link, Redirect } from 'react-router-dom';
import { DiscountType } from '../../interfaces.enums';
import { Subscription, SubscriptionDiscount, Discount, DiscountOverview } from '../../interfaces';
import { Summary } from '../details/Summary';
import { useMutation, useQuery } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

const useStyles = makeStyles((theme) => ({
  timePicker: {
    marginLeft: 2,
  },
  actionsDivider: {
    marginTop: 3,
    marginBottom: 2,
  },
  percentageBox: {
    width: 135,
  },
}));

const allSubscription = { id: -1, name: 'Alle' };

interface CreateDiscountInput {
  input: {
    name: string;
    value: number;
    type: DiscountType;
    emailFilter?: string;
    confirmUrlAction: string;
    validFrom: Date;
    validTo: Date | null;

    allowRenewal: boolean;
    maxUsageCount: number | null;
    onlyFirstYear: boolean;
    subscriptions: string[];
  };
}

interface CreateDiscountOutput {
  createDiscount: Discount;
}

export function NewDiscount() {
  const classes = useStyles();

  const [name, setName] = useState('');
  const [validFrom, setValidFrom] = useState(new Date());
  const [validTo, setValidTo] = useState<Date | null>(null);
  const [discountType, setDiscountType] = useState(DiscountType.Percentage);
  const [discountValue, setDiscountValue] = useState<number | null>(null);
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<SubscriptionDiscount[]>([
    { numberOfAccesses: null, subscriptionId: allSubscription.id },
  ]);
  const [maxUsageCount, setMaxUsageCount] = useState<number | null>(null);
  const [autoRenew, setAutoRenew] = useState(true);
  const [onlyFirstYear, setOnlyFirstYear] = useState(true);
  const [emailFilter, setEmailFilter] = useState('');
  const [isAutoGenerated, setIsAutoGenerated] = useState(false);
  const [confirmUrlAction, setConfirmUrlAction] = useState('register');

  const [create, { called, loading, data }] = useMutation<CreateDiscountOutput, CreateDiscountInput>(CREATE_DISCOUNT, {
    update(cache, { data }) {
      if (!data) return;
      const queryData = cache.readQuery<{ discounts: DiscountOverview[] }>({ query: GET_DISCOUNTS });
      if (queryData === null) return;
      const discount = data.createDiscount;
      cache.writeQuery({
        query: GET_DISCOUNTS,
        data: {
          discounts: queryData.discounts.concat([
            {
              id: discount.id,
              name: discount.name,
              value: discount.value,
              type: discount.type,
              emailFilter: discount.emailFilter,
              validFrom: discount.validFrom,
              validTo: discount.validTo,
              isApplicable: discount.isApplicable,
              usages: 0,
            },
          ]),
        },
      });
    },
  });

  if (called && !loading && data) return <Redirect to={'/discount/' + data.createDiscount.id} />;

  const cancel = () => {
    setName('');
    setEmailFilter('');
    setValidFrom(new Date());
    setValidTo(null);
  };
  const submit = () => {
    create({
      variables: {
        input: {
          name,
          value: Number(discountValue),
          type: discountType,
          allowRenewal: autoRenew,
          maxUsageCount,
          onlyFirstYear,
          subscriptions: selectedSubscriptions.map((x) => x.subscriptionId + ':' + x.numberOfAccesses),
          emailFilter,
          confirmUrlAction,
          validFrom,
          validTo,
        },
      },
    });
  };

  const isAutogeneratedChanged = () => {
    setIsAutoGenerated(!isAutoGenerated);
  };

  return (
    <>
      <Typography gutterBottom variant="h5">
        Ny rabattkode
      </Typography>
      <Grid container>
        <Grid item xs={9}>
          <MainContentPanel>
            <FormGroup>
              <TextField label="Navn" value={name} onChange={(event) => setName(event.target.value)} margin="normal" required />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={<Switch onChange={isAutogeneratedChanged} checked={isAutoGenerated} />}
                label="Autogenerert via e-post"
                title="Autogenererte rabattkoder blir opprettet når en bruker verifiserer en bestemt e-postadresse i henhold til et angitt filter"
              />
            </FormGroup>
            <FormGroup>
              {isAutoGenerated && (
                <AutoGeneratedOptions
                  emailFilter={emailFilter}
                  setEmailFilter={setEmailFilter}
                  confirmUrlAction={confirmUrlAction}
                  setConfirmUrlAction={setConfirmUrlAction}
                />
              )}
            </FormGroup>
          </MainContentPanel>

          <DiscountTypes discountType={discountType} setDiscountType={setDiscountType} />

          <DiscountValue discountValue={discountValue} setDiscountValue={setDiscountValue} discountType={discountType} />

          <DiscountAppliesTo
            selectedSubscriptions={selectedSubscriptions}
            setSelectedSubscriptions={setSelectedSubscriptions}
            onlyFirstYear={onlyFirstYear}
            setOnlyFirstYear={setOnlyFirstYear}
            discountType={discountType}
          />

          <Limitations maxUsageCount={maxUsageCount} setMaxUsageCount={setMaxUsageCount} autoRenew={autoRenew} setAutoRenew={setAutoRenew} />

          <Dates validFrom={validFrom} validTo={validTo} setValidFrom={setValidFrom} setValidTo={setValidTo} />

          <div>
            <Divider className={classes.actionsDivider} />
            <Link to="/discount">
              <Button onClick={() => cancel()}>Avbryt</Button>
            </Link>
            <Button variant="contained" color="primary" onClick={() => submit()}>
              Lag rabatt
            </Button>
          </div>
        </Grid>
        <Grid item xs={3}>
          <MainContentPanel>
            <Typography gutterBottom variant="h6">
              Sammendrag
            </Typography>
            <Summary
              discount={{
                id: 'anything',
                name: name,
                value: Number(discountValue),
                type: discountType,
                subscriptions: selectedSubscriptions,
                validFrom: validFrom,
                validTo: validTo,
                allowRenewal: autoRenew,
                maxUsageCount: maxUsageCount,
                onlyFirstYear: onlyFirstYear,
                isActive: true,
                isApplicable: true,
                usages: [],
                emailFilter: isAutoGenerated ? emailFilter : undefined,
                confirmUrlAction: isAutoGenerated ? confirmUrlAction : undefined,
              }}
            />
          </MainContentPanel>
        </Grid>
      </Grid>
    </>
  );
}

interface AutoGeneratedOptionsProps {
  emailFilter: string;
  setEmailFilter(string): void;
  confirmUrlAction: string;
  setConfirmUrlAction(string): void;
}
function AutoGeneratedOptions({ emailFilter, setEmailFilter, confirmUrlAction, setConfirmUrlAction }: AutoGeneratedOptionsProps) {
  return (
    <>
      <TextField
        label="E-post-filter"
        value={emailFilter}
        onChange={(event) => setEmailFilter(event.target.value)}
        margin="normal"
        helperText="Oppgi enten ett e-post-domene (f.eks. stud.ntnu.no) eller en kommaseparert liste med e-postadresser"
        required
      />
      <FormControl component="fieldset">
        <RadioGroup aria-label="confirmUrlAction" value={confirmUrlAction} onChange={(_, value) => setConfirmUrlAction(value)}>
          <FormControlLabel value="register" control={<Radio />} label="Standard landingsside" />
          <FormControlLabel value="register/student" control={<Radio />} label="Student" />
          <FormHelperText>
            For studenter må det opprettes to rabatter pr. e-post-domene, én for Byggforskserien Komplett og én for Våtromsnormen
          </FormHelperText>
        </RadioGroup>
      </FormControl>
    </>
  );
}

interface DiscountTypeProps {
  discountType: string;
  setDiscountType(string): void;
}
function DiscountTypes({ discountType, setDiscountType }: DiscountTypeProps) {
  return (
    <MainContentPanel>
      <Typography variant="h6">Typer</Typography>
      <FormControl component="fieldset">
        <RadioGroup aria-label="discountType" value={discountType} onChange={(ev) => setDiscountType((ev.target as HTMLInputElement).value)}>
          <FormControlLabel value={DiscountType.Percentage} control={<Radio />} label="Prosentandel" />
          <FormControlLabel value={DiscountType.Fixed} control={<Radio />} label="Fast beløp" />
        </RadioGroup>
      </FormControl>
    </MainContentPanel>
  );
}

interface DiscountValueProps {
  discountValue: number | null;
  setDiscountValue(number): void;
  discountType: string;
}
function DiscountValue({ discountValue, setDiscountValue, discountType }: DiscountValueProps) {
  return (
    <MainContentPanel>
      <Typography variant="h6">Verdi</Typography>
      <FormControl component="fieldset">
        {discountType === DiscountType.Percentage && <Percentage value={discountValue} setValue={setDiscountValue} />}
        {discountType === DiscountType.Fixed && <Fixed value={discountValue} setValue={setDiscountValue} />}
      </FormControl>
    </MainContentPanel>
  );
}

interface DiscountAppliesToProps {
  selectedSubscriptions: SubscriptionDiscount[];
  setSelectedSubscriptions(sub: SubscriptionDiscount[]): void;
  onlyFirstYear: boolean;
  discountType: DiscountType;
  setOnlyFirstYear(onlyFirst: boolean): void;
}

export interface SubscriptionData {
  id: number;
  name: string;
  numberOfAccesses: number | null;
}
function DiscountAppliesTo({
  selectedSubscriptions,
  setSelectedSubscriptions,
  onlyFirstYear,
  setOnlyFirstYear,
  discountType,
}: DiscountAppliesToProps) {
  const { loading, error, data } = useQuery<any>(GET_SUBSCRIPTIONS);
  const canChooseAll = discountType === DiscountType.Percentage;
  const [appliesTo, setAppliesTo] = useState('all');

  const getAccesses = (subscription: Subscription) => {
    const selected = selectedSubscriptions.find((ss) => ss.subscriptionId === subscription.id);
    return selected && selected.numberOfAccesses ? selected.numberOfAccesses : '';
  };
  const getIsSelected = (subscription: Subscription) => {
    const selected = selectedSubscriptions.find((ss) => ss.subscriptionId === subscription.id);
    return selected ? true : false;
  };
  const appliesToChanged = (val) => {
    if (val === 'all') {
      setSelectedSubscriptions([{ numberOfAccesses: null, subscriptionId: allSubscription }]);
    } else {
      setSelectedSubscriptions([]);
    }
    setAppliesTo(val);
  };

  const updateSelected = (subscription: Subscription, checked: boolean) => {
    if (checked) {
      setSelectedSubscriptions(selectedSubscriptions.concat([{ subscriptionId: subscription.id, numberOfAccesses: null }]));
    } else {
      setSelectedSubscriptions(selectedSubscriptions.filter((x) => x.subscriptionId !== subscription.id));
    }
  };

  const updateAccesses = (subscription: Subscription, numberOfAccesses) => {
    const selected = selectedSubscriptions.find((ss) => ss.subscriptionId === subscription.id);
    if (selected) {
      setSelectedSubscriptions(
        selectedSubscriptions.map((s) => (s.subscriptionId === selected.subscriptionId ? { subscriptionId: s.subscriptionId, numberOfAccesses } : s)),
      );
    } else {
      setSelectedSubscriptions(selectedSubscriptions.concat([{ subscriptionId: subscription.id, numberOfAccesses }]));
    }
  };

  if (!canChooseAll && appliesTo === 'all') appliesToChanged('specific');

  return (
    <MainContentPanel>
      <Typography variant="h6">Gjelder</Typography>
      <FormGroup row>
        <FormControlLabel control={<Checkbox checked={onlyFirstYear} onChange={() => setOnlyFirstYear(!onlyFirstYear)} />} label="Kun første året" />
      </FormGroup>
      <FormControl component="fieldset">
        <RadioGroup aria-label="discountAppliesTo" value={appliesTo} onChange={(ev) => appliesToChanged((ev.target as HTMLInputElement).value)}>
          {canChooseAll && <FormControlLabel value="all" control={<Radio />} label="Alle produkter" />}
          <FormControlLabel value="specific" control={<Radio />} label="Spesifikke produkter" />
        </RadioGroup>
      </FormControl>
      {appliesTo === 'all' && (
        <TextField
          label="Antall tilganger"
          value={getAccesses(allSubscription)}
          variant="outlined"
          onChange={(event) => updateAccesses(allSubscription, event.target.value)}
        />
      )}
      {!loading && !error && appliesTo === 'specific' && (
        <>
          <List>
            {data.subscriptions.map((s) => (
              <ListItem key={s.id}>
                <ListItemText>
                  {s.parentId && <span>&nbsp;&nbsp;&nbsp;</span>}
                  <Checkbox checked={getIsSelected(s)} onChange={(ev, checked) => updateSelected(s, checked)} />
                  <span>{s.name}</span>
                </ListItemText>
                <TextField
                  label="Antall tilganger"
                  value={getAccesses(s)}
                  type="number"
                  variant="outlined"
                  onChange={(event) => updateAccesses(s, event.target.value)}
                />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </MainContentPanel>
  );
}

interface LimitationsProps {
  maxUsageCount: number | null;
  setMaxUsageCount(count: number | null);
  autoRenew: boolean;
  setAutoRenew(renew: boolean): void;
}
function Limitations({ maxUsageCount, setMaxUsageCount, autoRenew, setAutoRenew }: LimitationsProps) {
  const [maxUsage, setMaxUsage] = useState(false);
  const maxUsageChanged = () => {
    setMaxUsage(!maxUsage);
    setMaxUsageCount(null);
  };
  const displayMaxUsageCount = maxUsageCount === null ? '' : maxUsageCount;
  return (
    <MainContentPanel>
      <Typography variant="h6">Bruksgrenser</Typography>
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox checked={maxUsage} onChange={maxUsageChanged} />}
          label="Begrens totalt antall ganger denne rabatten kan brukes"
        />
      </FormGroup>
      {maxUsage && (
        <TextField
          label="Antall"
          value={displayMaxUsageCount}
          variant="outlined"
          onChange={(event) => setMaxUsageCount(Number(event.target.value))}
        />
      )}
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox checked={!autoRenew} onChange={() => setAutoRenew(!autoRenew)} />}
          label="Avslutt kundeforhold ved utgang"
        />
      </FormGroup>
    </MainContentPanel>
  );
}

interface DateProps {
  validFrom: Date;
  setValidFrom(Date): void;
  validTo: Date | null;
  setValidTo(date): void;
}
function Dates({ validFrom, validTo, setValidFrom, setValidTo }: DateProps) {
  const [useEndDate, setUseEndDate] = useState(false);
  const classes = useStyles();
  const useEndDateChanged = () => {
    setUseEndDate(!useEndDate);
    setValidTo(null);
  };
  return (
    <MainContentPanel>
      <Typography variant="h6">Aktive datoer</Typography>
      <div>
        <LocalizationProvider utils={MomentUtils} dateAdapter={AdapterDateFns}>
          <DatePicker
            showTodayButton
            inputFormat="d/M/yyyy"
            label="Start dato"
            value={validFrom}
            disablePast={true}
            onChange={setValidFrom}
            renderInput={(params) => <TextField {...params} helperText={params?.inputProps?.placeholder} />}
          />
          <TimePicker
            className={classes.timePicker}
            ampm={false}
            inputFormat="HH:mm"
            label="Start tidspunkt"
            value={validFrom}
            onChange={setValidFrom}
            renderInput={(params) => <TextField {...params} helperText={params?.inputProps?.placeholder} />}
          />
        </LocalizationProvider>
      </div>
      <FormControlLabel control={<Switch onChange={useEndDateChanged} checked={useEndDate} />} label="Angi sluttdato" />
      {useEndDate && (
        <div>
          <LocalizationProvider utils={MomentUtils} dateAdapter={AdapterDateFns}>
            <DatePicker
              clearable
              inputFormat="d/M/yyyy"
              label="Sluttdato"
              value={validTo}
              disablePast={true}
              onChange={setValidTo}
              renderInput={(params) => <TextField {...params} helperText={params?.inputProps?.placeholder} />}
            />
            <TimePicker
              className={classes.timePicker}
              ampm={false}
              inputFormat="HH:mm"
              label="Sluttid"
              value={validTo}
              onChange={setValidTo}
              renderInput={(params) => <TextField {...params} helperText={params?.inputProps?.placeholder} />}
            />
          </LocalizationProvider>
        </div>
      )}
    </MainContentPanel>
  );
}

interface ValueFieldProps {
  value: number | null;
  setValue(foo: number): void;
}

function Percentage({ value, setValue }: ValueFieldProps) {
  const classes = useStyles();

  const displayValue = value ? value : '';
  const onChange = (newValue: number) => {
    if (newValue >= 0 && newValue < 100) {
      setValue(newValue);
    }
  };
  return (
    <TextField
      className={classes.percentageBox}
      variant="outlined"
      placeholder="%"
      label="Prosentandel"
      type="number"
      value={displayValue}
      margin="normal"
      inputProps={{ min: 1, max: 99 }}
      required
      helperText="Mellom 1 og 99%"
      onChange={(event) => onChange(Number(event.target.value))}
    />
  );
}
function Fixed({ value, setValue }: ValueFieldProps) {
  const displayValue = value ? value : '';
  return (
    <TextField
      variant="outlined"
      placeholder="kr"
      label="Beløp"
      helperText="Beløp pr tilgang"
      type="number"
      value={displayValue}
      margin="normal"
      required
      onChange={(event) => setValue(Number(event.target.value))}
    />
  );
}
