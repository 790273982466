import { Box, Button, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';
import React from 'react';
import MainContentPanel from '../components/MainContentPanel';
import { useFormControls } from './ContactFormControls';

const inputFieldValues = [
  // {
  //   name: "replyto",
  //   label: "Reply to",
  //   id: "my-replyto"
  // },
  //
  {
    name: 'email',
    label: 'E-post adresser',
    id: 'my-email',
  },
  {
    name: 'subject',
    label: 'Subject',
    id: 'my-overskrift',
  },
  {
    name: 'message',
    label: 'E-post tekst',
    id: 'my-message',
    multiline: true,
    rows: 10,
  },
];

export const ContactForm = () => {
  const { handleInputValue, handleFormSubmit, formIsValid, isTest, errors, message, selectedMenu, handleChange, handleFormTest } = useFormControls();

  return (
    <MainContentPanel>
      <Typography variant="h5">E-post</Typography>
      <Typography variant="body1">Sender epost til kundeservice@byggforsk.no med Bcc til e-poster spesifisert under.</Typography>
      <form autoComplete="off" onSubmit={handleFormSubmit}>
        <FormControl variant="filled">
          <InputLabel id="selected_recipients-label">Gruppe</InputLabel>
          <Select labelId="selected_recipient-filled-label" id="selected_recipient-select-filled" value={selectedMenu} onChange={handleChange}>
            <MenuItem value={0}>Ingen</MenuItem>
            <MenuItem value={1}> Alle brukere</MenuItem>
            <MenuItem value={2}> Alle administratorer</MenuItem>
            <MenuItem value={3}>Brukere av Byggforskserien komplett</MenuItem>
            <MenuItem value={4}>Administratorer av Byggforkseien komplett</MenuItem>
            <MenuItem value={5}>Brukere av Byggebransjens våtromsnorm</MenuItem>
            <MenuItem value={6}>Administratorer av Byggebransjens våtromsnorm</MenuItem>
            <MenuItem value={7}>Brukere av byggdetaljer</MenuItem>
            <MenuItem value={8}>Administratorer av byggdetaljer</MenuItem>
            <MenuItem value={9}>Brukere av planlegging</MenuItem>
            <MenuItem value={10}>Administratorer av planlegging</MenuItem>
            <MenuItem value={11}>Brukere av byggforvaltning</MenuItem>
            <MenuItem value={12}>Administratorer av byggforvaltning</MenuItem>
            <MenuItem value={13}>Studenter</MenuItem>
            <MenuItem value={14}>Salg testgruppe</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel control={<Switch checked={isTest()} onChange={handleFormTest} name="testChecked" />} label="Test send" />
        {inputFieldValues.map((inputFieldValue, index) => {
          return (
            <TextField
              variant="standard"
              key={index}
              //value={initValue}
              onChange={handleInputValue}
              onBlur={handleInputValue}
              name={inputFieldValue.name}
              label={inputFieldValue.label}
              //error={errors[inputFieldValue.name]}
              multiline={inputFieldValue.multiline == null ? false : inputFieldValue.multiline}
              fullWidth
              rows={inputFieldValue.rows == null ? 1 : inputFieldValue.rows}
              //autoComplete="none"
              {...(errors[inputFieldValue.name] && {
                error: true,
                helperText: errors[inputFieldValue.name],
              })}
            />
          );
        })}
        <Button variant="contained" type="submit" color="secondary" disabled={!formIsValid()}>
          Send e-poster
        </Button>
        <Box mt={0}>
          <TextField
            variant="standard"
            label="Status"
            name="status"
            multiline
            rows={4}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
            value={message}
          ></TextField>
        </Box>
      </form>
    </MainContentPanel>
  );
};
