import React from 'react';
import { Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  right: {
    textAlign: 'right',
  },
}));

export function Counter({ list }: { list: any[] }) {
  var classes = useStyles();

  return (
    <div className={classes.right}>
      <Typography variant="caption">Antall: {list.length.toLocaleString('nb-NO')}</Typography>
    </div>
  );
}
