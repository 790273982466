import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

interface Props {
  open: boolean;
  title: string;
  message: string;
  confirm: () => void;
  deny: () => void;
  confirmText?: string;
  denyText?: string;
  DialogProps?: any;
  disableOK?: boolean;
}

const confirmDialog = ({ open, title, message, confirm, deny, confirmText, denyText, DialogProps, disableOK = false }: Props) => (
  <Dialog open={open} onClose={deny} {...DialogProps}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Typography>{message}</Typography>
    </DialogContent>
    <DialogActions>
      <Button color="secondary" variant="contained" onClick={deny}>
        {denyText || 'Avbryt'}
      </Button>
      <Button disabled={disableOK} variant="contained" color="primary" onClick={confirm}>
        {confirmText || 'OK'}
      </Button>
    </DialogActions>
  </Dialog>
);

export default confirmDialog;
