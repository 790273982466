import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useState } from 'react';

export const EmailItem = gql`
  fragment EmailItem on EmailModel {
    ok
    message
  }
`;

const initialFormValues = {
  mailgroup: 0,
  replyto: '',
  subject: '',
  email: '',
  message: '',
  formSubmitted: false,
  success: false,
  test: true,
};

const PostContactForm = async (values: any, successCallback: any, errorCallback: any, sendemail: any) => {
  // do stuff
  // if successful

  sendemail();

  if (true) successCallback();
  else errorCallback();
};

let errorValues: any = {};

export const SEND_EMAIL = gql`
  mutation SendEmail($input: SendEmailInput!) {
    sendEmail(input: $input) {
      ...EmailItem
    }
  }
  ${EmailItem}
`;

export const useFormControls = () => {
  const [message, setMessage] = useState('');

  const [values, setValues] = useState(initialFormValues);
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [errors, setErrors] = useState(errorValues);
  const [sendEmail] = useMutation(SEND_EMAIL, {
    update: (proxy, mutationResult) => {
      setMessage(mutationResult.data.sendEmail.message);
    },
    variables: {
      input: {
        message: values.message,
        email: values.email,
        mailgroup: values.mailgroup,
        replyto: values.replyto,
        subject: values.subject,
        test: values.test,
      },
    },
  });

  const validate: any = (fieldValues = values) => {
    let temp: any = { ...errors };

    if ('replyto' in fieldValues) {
      if (fieldValues.replyto) {
        temp.replyto = /^(([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+).([a-zA-Z]{2,5}){1,25}(($)|( *;+ *$)|( *;+ *(?=[a-zA-Z0-9_\-.]))))*$/.test(
          fieldValues.replyto,
        )
          ? ''
          : 'Email is not valid.';
      }
    }

    if ('email' in fieldValues) {
      if (selectedMenu === 0) {
        temp.email = fieldValues.email ? '' : 'Minst en epost krevet, semikolonseparert.';
        if (fieldValues.email)
          temp.email = /^(([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25}(($)|( *;+ *$)|( *;+ *(?=[a-zA-Z0-9_\-.]))))*$/.test(
            fieldValues.email,
          )
            ? ''
            : 'Email is not valid.';
      } else {
        temp.email = '';
      }
    }

    if ('subject' in fieldValues) temp.subject = fieldValues.subject.length !== 0 ? '' : 'Subject må fylles ut.';

    if ('message' in fieldValues) temp.message = fieldValues.message.length !== 0 ? '' : 'Tekst må fylles ut.';

    setErrors({
      ...temp,
    });
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = Object.values(errors).every((x) => x === '') && formIsValid();

    if (isValid) {
      await PostContactForm(values, handleSuccess, handleError, sendEmail);
    }
  };

  const handleFormTest = async (e: any) => {
    setValues({
      ...values,
      test: e.target.checked,
    });
  };

  const handleChange = (e: any) => {
    const { value } = e.target;
    setValues({
      ...values,
      mailgroup: value,
    });
    setSelectedMenu(e.target.value);
  };

  const handleInputValue = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const handleSuccess = () => {
    setValues({
      ...initialFormValues,
      formSubmitted: true,
      success: true,
    });
  };

  const handleError = () => {
    setValues({
      ...initialFormValues,
      formSubmitted: true,
      success: false,
    });
  };

  const formIsValid = (fieldValues = values, selectedValue = selectedMenu) => {
    const isValid = (fieldValues.email || selectedValue !== 0) && fieldValues.message && Object.values(errors).every((x) => x === '');

    return isValid;
  };

  const isTest = (fieldValues = values) => {
    return fieldValues.test;
  };

  return {
    values,
    errors,
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    isTest,
    message,
    selectedMenu,
    handleChange,
    handleFormTest,
  };
};
