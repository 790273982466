import React, { useState } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Delete } from '@mui/icons-material';
import { REMOVE_SUBSCRIPTION, GET_SUBSCRIPTIONS } from './UsersInLicense.graphql';
import { useMutation } from '@apollo/client';
import { LicenseSubscription } from '../../interfaces';

const useStyles = makeStyles((theme) => ({
  immediate: {
    background: theme.palette.error.main,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));
interface TerminationSubscriptionProps {
  access: LicenseSubscription;
  suspendValue: boolean;
}

export function TerminateSubscriptionButton({ access, suspendValue }: TerminationSubscriptionProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [removeSubscription, { loading }] = useMutation(REMOVE_SUBSCRIPTION);
  const confirm = (effectuateNow?: boolean) => {
    removeSubscription({
      variables: { input: { id: access.licenseId, subscriptionId: access.subscription.id, effectuateNow: effectuateNow === true } },
      update: (cache, { data: { addSubscriptions } }) => {
        if (!effectuateNow) return;

        const queryVariable = { id: access.licenseId };
        const result = cache.readQuery({ query: GET_SUBSCRIPTIONS, variables: queryVariable }) as any;
        cache.writeQuery({
          query: GET_SUBSCRIPTIONS,
          variables: queryVariable,
          data: {
            subscriptions: result.subscriptions.filter((x) => x.subscription.id !== access.subscription.id),
            paidSubscriptions: result.paidSubscriptions,
            license: result.license,
          },
        });
      },
    });
    setOpen(false);
  };
  return (
    <>
      <IconButton onClick={() => setOpen(true)} title="Avslutt abonnement" size="large">
        <Delete />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs" fullWidth={true}>
        <DialogTitle>Avslutt abonnement</DialogTitle>
        <DialogContent>
          <Typography>{`Vil du avslutte abonnementet ${access.subscription.name}?`}</Typography>
        </DialogContent>
        {/*console.log('TerminateSubscriptionButton', suspendValue)*/}
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Avbryt</Button>
          <Button disabled={loading} variant="contained" color="primary" onClick={() => confirm()}>
            Avslutt
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            className={classes.immediate}
            onClick={() => confirm(true)}
            title="Dette vil avslutte abonnementet med umiddelbar virkning"
          >
            Avslutt nå
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
