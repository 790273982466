import { useState, useEffect } from 'react';
import MainContentPanel from '../../components/MainContentPanel';
import { Typography, IconButton, Chip, Grid, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, TrendingDown, Delete, ArrowDownward } from '@mui/icons-material';
import {
  GET_SUBSCRIPTIONS,
  INCREASE_ACCESS,
  CANCEL_DECREASE_ACCESS,
  ADD_SUBSCRIPTIONS,
  CANCEL_REMOVE_SUBSCRIPTION,
  CANCEL_DOWNGRADE_SUBSCRIPTION,
  GET_SUBSCRIPTION_CHANGE_STATUS,
  overwriteLicenseQueryCache,
} from './UsersInLicense.graphql';

import {
  ORDER_LINES_FROM_CHANGES,
  CREATE_ORDER,
  ANNUL_INVOICE,
  SINGLE_DOC_ORDER_LINES_FROM_CHANGES,
  SINGLE_DOC_CREATE_INVOICE,
} from './Invoice.graphql';

import { Subscription, LicenseModel, Markdown, LicenseSubscription, Downgrade, OrderLine, License } from '../../interfaces';
import AddSubscriptionDialog, { SubscriptionData } from './AddSubscriptionDialog';
import { IncreaseAccessDialog } from './IncreaseAccessDialog';
import { DecreaseAccessButton } from './DecreaseAccessButton';
import LoadingSpinner from '../../components/LoadingSpinner';
import { TerminateSubscriptionButton } from './TerminateSubscriptionButton';
import { DowngradeSubscriptionButton } from './DowngradeSubscriptionButton';
import { UpgradeSubscriptionButton } from './UpgradeSubscriptionButton';
import CreateInvoiceDialog from './CreateInvoiceDialog';
import CreateSingleDocInvoiceDialog from './CreateSingleDocInvoiceDialog';
import { useConfig } from '../../components/Configuration';
import { useLazyQuery, useMutation, MutationTuple, useQuery } from '@apollo/client';
import { SingleDocOrderLine } from '../../interfaces'; // Import the correct interface

const useStyles = makeStyles((theme) => ({
  actionButton: {
    marginTop: 20,
  },
}));
type QueryProps = {
  subscriptions: LicenseSubscription[];
  paidSubscriptions: Subscription[];
  license: LicenseModel;
};

export function LicenseAccess({ license, bksLicense, suspendValue }: { license: LicenseModel; bksLicense: License; suspendValue: boolean }) {
  const config = useConfig();
  const pollIntervall = config ? config.defaultPollingInterval : 0;
  const [open, setOpen] = useState(false);
  const { data, loading } = useQuery<QueryProps>(GET_SUBSCRIPTIONS, { variables: { id: bksLicense.id } });

  const changeStatusResult = useQuery(GET_SUBSCRIPTION_CHANGE_STATUS, { variables: { id: bksLicense.id }, pollInterval: pollIntervall });
  const [addSubscriptions] = useMutation(ADD_SUBSCRIPTIONS);

  const handleSubmitAddSubscriptions = (subscriptionData: SubscriptionData[]) => {
    addSubscriptions({
      variables: {
        input: {
          id: bksLicense.id,
          subscriptions: subscriptionData.map((s) => `${s.id}:${s.numberOfAccesses}`),
        },
      },
      update: (cache, { data: { addSubscriptions } }) => overwriteLicenseQueryCache(cache, bksLicense.id, addSubscriptions.paidSubscriptions),
    });
    setOpen(false);
  };

  const availableSubscriptions = ({ paidSubscriptions, subscriptions }) =>
    paidSubscriptions.filter((s) => subscriptions.every((ss) => ss.subscription.id !== s.id));

  return (
    <MainContentPanel>
      <Typography gutterBottom variant="h5">
        Abonnement
        <IconButton title="Legg til nytt abonnement" onClick={() => setOpen(true)} size="large">
          <Add />
        </IconButton>
      </Typography>
      {loading && <LoadingSpinner />}
      {!loading && data && (
        <>
          <LicenseAccessList
            accessList={data.subscriptions}
            license={data.license}
            subscriptions={data.paidSubscriptions}
            suspendValue={suspendValue}
          />
          <AddSubscriptionDialog
            open={open}
            availableSubscriptions={availableSubscriptions(data)}
            onCancel={() => setOpen(false)}
            onSubmit={(data) => handleSubmitAddSubscriptions(data)}
          />
          {changeStatusResult.data &&
            changeStatusResult.data.status &&
            changeStatusResult.data.status.isConnectedToMaconomy &&
            changeStatusResult.data.status.hasPendingChanges &&
            data.subscriptions && <CreateInvoiceButton licenseId={bksLicense.id} />}
        </>
      )}
    </MainContentPanel>
  );
}

interface ListProps {
  accessList: LicenseSubscription[];
  license: LicenseModel;
  subscriptions: Subscription[];
  suspendValue: boolean;
}

function LicenseAccessList({ accessList, license, subscriptions, suspendValue }: ListProps) {
  return (
    <Grid container direction="column">
      {accessList.map((access) => {
        return (
          <Grid item key={access.subscription.id}>
            <LicenseSubscriptionLine access={access} license={license} subscriptions={subscriptions} suspendValue={suspendValue} />
          </Grid>
        );
      })}
    </Grid>
  );
}

function LicenseSubscriptionLine({
  access,
  license,
  subscriptions,
  suspendValue,
}: {
  access: LicenseSubscription;
  license: LicenseModel;
  subscriptions: Subscription[];
  suspendValue: boolean;
}) {
  const parentSubscription = subscriptions.find((x) => x.id === access.subscription.parentId);
  const childSubscriptions = subscriptions.filter((x) => x.parentId === access.subscription.id);
  const pendingMarkdown = license ? license.pendingSubscriptionMarkdowns.find((x) => x.subscriptionId === access.subscription.id) : undefined;
  const pendingRemoval = license ? license.pendingSubscriptionRemovals.find((x) => x === access.subscription.id) : undefined;
  const pendingDowngrade = license ? license.pendingSubscriptionDowngrades.find((x) => x.fromSubscriptionId === access.subscription.id) : undefined;
  const pendingChange = pendingMarkdown || pendingRemoval || pendingDowngrade;
  const availableSeats = access.numberOfUsers - access.userAccess.length;

  return (
    <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
      <Grid item>
        <Typography noWrap>
          {access.subscription.name}, {access.numberOfUsers} stk
        </Typography>
        {availableSeats > 0 ? <Typography>({availableSeats} ledige)</Typography> : null}
      </Grid>
      {!pendingChange && (
        <Grid item>
          {parentSubscription && <UpgradeSubscriptionButton access={access} parentSubscription={parentSubscription} />}
          {childSubscriptions.length > 0 && <DowngradeSubscriptionButton access={access} childSubscriptions={childSubscriptions} />}
          {access.numberOfUsers > 1 && <DecreaseAccessButton access={access} />}
          <IncreaseAccessButton access={access} />
          {/*console.log('licenseAccess suspendValue', suspendValue) */}
          <TerminateSubscriptionButton access={access} suspendValue={suspendValue} />
        </Grid>
      )}
      {pendingChange && (
        <PendingChange
          access={access}
          pendingRemoval={pendingRemoval}
          pendingMarkdown={pendingMarkdown}
          pendingDowngrade={pendingDowngrade}
          subscriptions={subscriptions}
        />
      )}
    </Grid>
  );
}

function PendingChange({
  access,
  pendingRemoval,
  pendingMarkdown,
  pendingDowngrade,
  subscriptions,
}: {
  access: LicenseSubscription;
  pendingRemoval?: number;
  pendingMarkdown?: Markdown;
  pendingDowngrade?: Downgrade;
  subscriptions: Subscription[];
}) {
  return (
    <>
      {pendingRemoval && <PendingRemoval access={access} />}
      {pendingMarkdown && <PendingDecrease access={access} pendingMarkdown={pendingMarkdown} />}
      {pendingDowngrade && <PendingDowngrade access={access} pendingDowngrade={pendingDowngrade} subscriptions={subscriptions} />}
    </>
  );
}

function PendingDecrease({ access, pendingMarkdown }: { access: LicenseSubscription; pendingMarkdown: Markdown }) {
  const [cancel] = useMutation(CANCEL_DECREASE_ACCESS, { variables: { input: { id: access.licenseId, subscriptionId: access.subscription.id } } });
  const remainingAccesCount = access.numberOfUsers + pendingMarkdown.markdown;
  const text = remainingAccesCount > 0 ? 'Vil bli justert ned til ' + remainingAccesCount + ' stk ved fornyelse.' : 'Vil ikke bli fornyet!';
  return <Chip icon={<TrendingDown />} label={text} onDelete={() => cancel()} />;
}
function PendingRemoval({ access }: { access: LicenseSubscription }) {
  const [cancel] = useMutation(CANCEL_REMOVE_SUBSCRIPTION, {
    variables: { input: { id: access.licenseId, subscriptionId: access.subscription.id } },
  });
  const text = 'Vil ikke bli fornyet';
  return <Chip icon={<Delete />} label={text} onDelete={() => cancel()} />;
}

function PendingDowngrade({
  access,
  pendingDowngrade,
  subscriptions,
}: {
  access: LicenseSubscription;
  pendingDowngrade: any;
  subscriptions: Subscription[];
}) {
  const [cancel] = useMutation(CANCEL_DOWNGRADE_SUBSCRIPTION, {
    variables: { input: { id: access.licenseId, fromSubscriptionId: access.subscription.id } },
  });
  const childSubscriptions = pendingDowngrade.toSubscriptionIds.map((id) => subscriptions.find((s) => s.id === id) as Subscription);
  const names =
    childSubscriptions.length > 1
      ? `${childSubscriptions
          .slice(0, -1)
          .map((s) => s.name)
          .join(', ')} og ${childSubscriptions.slice(-1)[0].name}`
      : childSubscriptions[0].name;
  const text = `Vil bli nedgradert til ${names} ved fornyelse`;
  return <Chip icon={<ArrowDownward />} label={text} onDelete={() => cancel()} />;
}

function IncreaseAccessButton({ access }: { access: LicenseSubscription }) {
  const [open, setOpen] = useState(false);
  const [increaseAccess] = useMutation(INCREASE_ACCESS);
  const submit = (increase) => {
    increaseAccess({ variables: { input: { id: access.licenseId, subscriptionId: access.subscription.id, addNumberOfAccesses: increase } } });
  };

  return (
    <>
      <IconButton onClick={() => setOpen(true)} title="Legg til nye tilganger" size="large">
        <Add />
      </IconButton>
      <IncreaseAccessDialog open={open} subscriptionName={access.subscription.name} onClose={() => setOpen(false)} onSubmit={submit} />
    </>
  );
}

function CreateOrderMutaion(): [boolean, any] {
  const [createOrder, { called }] = useMutation(CREATE_ORDER);
  return [called, createOrder];
}

function SingleDocCreateInvoiceMutation(): MutationTuple<any, any> {
  const [singleDocCreateInvoice, result] = useMutation(SINGLE_DOC_CREATE_INVOICE);

  return [singleDocCreateInvoice, result];
}

function AnnulInvoiceMutation(licenseId: number): [boolean, any] {
  const [annulInvoice, { called }] = useMutation(ANNUL_INVOICE, { variables: { input: { licenseId } } });
  return [called, annulInvoice];
}

export function CreateInvoiceButton({ licenseId }: { licenseId: number }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [getOrderLines, { data }] = useLazyQuery(ORDER_LINES_FROM_CHANGES, { variables: { id: licenseId }, fetchPolicy: 'network-only' });

  const [loadingCreateOrder, createOrder] = CreateOrderMutaion();
  const [loadingAnnulInvoice, annulInvoice] = AnnulInvoiceMutation(licenseId);

  function openDialog() {
    getOrderLines();
    setOpen(true);
  }
  function submitCreateOrder(discardedOrderLines: OrderLine[]) {
    setOpen(false);
    if (!loadingCreateOrder) {
      createOrder({ variables: { input: { licenseId, discardedOrderlines: discardedOrderLines.map((x) => `${x.itemId}:${x.amount}`) } } });
    }
  }
  function submitAnnulInvoice() {
    setOpen(false);
    if (!loadingAnnulInvoice) {
      annulInvoice();
    }
  }
  return (
    <>
      <Button className={classes.actionButton} variant="contained" color="primary" onClick={openDialog} title="Opprett faktura for endringer">
        <Add /> Opprett faktura
      </Button>
      <CreateInvoiceDialog
        open={open}
        orderlines={data && data.orderLinesFromChanges}
        onSubmit={submitCreateOrder}
        onAnnul={submitAnnulInvoice}
        canSubmit={!loadingCreateOrder && data && data.orderLinesFromChanges && data.orderLinesFromChanges.length > 0}
        onCancel={() => setOpen(false)}
      />
    </>
  );
}

export function CreateSingleDocInvoiceButton({ licenseId }: { licenseId: number }) {
  const [open, setOpen] = useState(false);
  const { data, refetch } = useQuery(SINGLE_DOC_ORDER_LINES_FROM_CHANGES, { variables: { id: licenseId }, fetchPolicy: 'network-only' });
  const [singleDocCreateInvoice, result] = SingleDocCreateInvoiceMutation();
  const { loading } = result;
  const [dialogOrderlines, setDialogOrderlines] = useState<SingleDocOrderLine[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (data && data.singleDocOrderLineFromChanges) {
      setDialogOrderlines(data.singleDocOrderLineFromChanges);
      setHasChanges(data.singleDocOrderLineFromChanges.length > 0);
    }
  }, [data]);

  function openDialog() {
    refetch();
    setDialogOrderlines(data.singleDocOrderLineFromChanges);
    setHasChanges(data.singleDocOrderLineFromChanges.length > 0);
    setOpen(true);
  }

  async function submitCreateInvoice() {
    setOpen(false);
    if (!loading) {
      try {
        const items = dialogOrderlines.map((item) => `${item.docId}:${item.priceIncVat}`);
        await singleDocCreateInvoice({ variables: { input: { licenseId, orderLines: items } } });
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }

  const handleRemoveItem = (indexToRemove: number) => {
    const updatedOrderlines = [...dialogOrderlines];
    updatedOrderlines.splice(indexToRemove, 1);
    setDialogOrderlines(updatedOrderlines);
    setHasChanges(updatedOrderlines.length > 0);
  };

  return (
    <>
      <Button className={classes.actionButton} variant="contained" color="primary" onClick={openDialog} title="Opprett faktura for endringer">
        <Add /> Opprett faktura
      </Button>
      {loading && <LoadingSpinner />}
      <CreateSingleDocInvoiceDialog
        open={open}
        singleDocOrderlines={dialogOrderlines}
        onRemoveItem={handleRemoveItem}
        onSubmit={async () => await submitCreateInvoice()}
        onCancel={() => setOpen(false)}
        disabled={!hasChanges || loading}
      />
    </>
  );
}
