import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';
import { DELETE_CUSTOMER as DELETE_USER } from '../user.graphql';
import { User } from '../../interfaces';
import ConfirmDialog from '../../components/ConfirmDialog';

interface DeleteUsersProps {
  open: boolean;
  onClose: () => void;
  userId: number[];
  onlyActive: boolean;
  activeSingleDocument: boolean;
  nonActiveSingleDocument: boolean;
  nonActiveForNumberYears: number;
  setSelected(val: string[]): void;
  users: User[];
  removeFromList: (id: number) => void;
}

function DeleteUsers({ open, onClose, userId: userIdList, setSelected, users, removeFromList }: DeleteUsersProps) {
  const brukere = userIdList.length > 1 ? 'brukere' : 'bruker';
  const askMessage = userIdList.length > 0 ? `Vil du slette og anonymisere ` + userIdList.length + ` ` + brukere + `?` : `Ingen brukere valgt!`;

  const [message, setMessage] = React.useState<string>(askMessage);
  const [disableOk, setDisableOk] = React.useState<boolean>(false);
  const [denyText, setDenyText] = React.useState<string>('Lukk!');

  console.log('Test: ' + askMessage + ' ' + userIdList.length);

  const mutationInput = (parameters) => {
    return { variables: { input: parameters } };
  };

  const continueDelete = React.useRef(false);
  const numberDeleted = React.useRef(0);
  const errors = React.useRef('');

  const [deleteUser] = useMutation(DELETE_USER, {
    onCompleted: () => {},
    onError: (error) => {
      setMessage('Feil ved sletting av bruker: ' + error.message);
    },
  });

  const handleDelete = useCallback(async () => {
    continueDelete.current = true;
    setDenyText('Avbryt');
    setDisableOk(true);

    for (const userId of userIdList) {
      if (!continueDelete.current) {
        const cancelMessage = 'Sletting avbrutt ' + (errors.current === '' ? 'uten feil' : 'med feil på bruker(e) med id: ' + errors.current);
        setMessage(cancelMessage);
        setDisableOk(false);
        setSelected([]);
        return;
      }
      numberDeleted.current++;
      var userIndeletion = users.find((x) => x.id === userId);
      setMessage(
        'Sletter bruker: ' + userIndeletion?.displayName + ' med id ' + userId + ' (' + numberDeleted.current + ' av ' + userIdList.length + ')',
      );
      await deleteUser(mutationInput({ id: userId }));
      removeFromList(userId);
      console.log('Deleting user done ' + userId);
    }
    const finishMessage = 'Sletting ferdig ' + (errors.current === '' ? 'uten feil' : 'med feil på bruker(e) med id: ' + errors.current);
    setMessage(finishMessage);
    setDisableOk(false);
    setDenyText('Lukk!');
    continueDelete.current = false;
  }, [deleteUser, removeFromList, setSelected, userIdList, users]);

  const stopDeletionProcess = useCallback(() => {
    setDenyText('Lukk!');

    if (!continueDelete.current) {
      onClose();
      return;
    }
    continueDelete.current = false;
  }, [onClose]);

  return (
    <>
      <ConfirmDialog
        open={open}
        title={`Slett og anonymiser ` + brukere}
        message={message}
        confirmText="Slett!"
        denyText={denyText}
        deny={stopDeletionProcess}
        confirm={handleDelete}
        disableOK={disableOk}
      />
    </>
  );
}

export default DeleteUsers;
