import { useState } from 'react';
import { Paper, Grid, Button } from '@mui/material';
import { AdfsSetting } from '../../interfaces';
import { UPDATE_ADVANCE_SETTING, GET_SINGLE_ADFS, GET_ADFS } from './ADFSConfig.graphql';
import { useMutation, useQuery } from '@apollo/client';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useHistory } from 'react-router-dom';
import { Snackbar, IconButton, SnackbarContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import CallbackPath from './CallbackPath';
const useStyles = makeStyles((theme) => ({
  snackbarStyleViaNestedContent: {
    backgroundColor: 'black',
    color: 'white',
  },
  updateButton: {
    marginLeft: 15,
  },
}));
export default function AdvanceSettings(props) {
  const classes = useStyles();
  const adfsSetting: AdfsSetting = props.location.state && props.location.state.adfs;
  const id = adfsSetting && adfsSetting.id;
  const existingName = adfsSetting && adfsSetting.callbackPath;
  const callbackPathValue = adfsSetting && adfsSetting.callbackPath ? adfsSetting.callbackPath : '';
  const [callbackPath, setCallbackPath] = useState(callbackPathValue.replace(/\//g, ''));
  const [isErrorOnCallbackPath, setIsErrorOnCallbackPath] = useState(false);
  const callbackCallbackPath = (path: string) => {
    setCallbackPath(path);
  };
  const callbackCallbackPathError = (errorStatus: boolean) => {
    setIsErrorOnCallbackPath(errorStatus);
  };
  const isFormValid = () => {
    return callbackPath && !isErrorOnCallbackPath;
  };
  const [create, { loading: loadingCreate }] = useMutation<any>(UPDATE_ADVANCE_SETTING, {
    variables: {
      input: {
        id: id,
        callbackPath: callbackPath,
        scheme: adfsSetting && adfsSetting.scheme,
      },
    },
    update: (cache, { data: { getSingleAdfs } }) => {
      if (!getSingleAdfs) return;
      const data = cache.readQuery<{ adfs: AdfsSetting }>({ query: GET_SINGLE_ADFS, variables: { id } });
      if (data === null) return;
      cache.writeQuery({
        query: GET_SINGLE_ADFS,
        variables: { id },
        data: { adfs: getSingleAdfs },
      });
    },
  });
  const allAdfs = useQuery<{ adfs: AdfsSetting[] }>(GET_ADFS);
  const result = allAdfs && allAdfs.data && allAdfs.data.adfs;
  let history = useHistory();
  const handleCloseAdvanceSetting = () => {
    history.goBack();
  };
  const handleSave = async () => {
    if (!isErrorOnCallbackPath) {
      await create();
      updateSnackbarStatus();
      backToHistoryWithDelay(1000);
    }
  };
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const handleCloseSnackbar = () => setShowErrorSnackbar(false);
  const updateSnackbarStatus = () => {
    setShowErrorSnackbar(true);
  };
  const backToHistoryWithDelay = (time) => {
    setTimeout(function () {
      history.goBack();
    }, time);
  };

  return (
    <>
      <Paper elevation={2}>
        <Grid container spacing={1} direction="row" style={{ padding: 20 }}>
          <Grid item xs={12}>
            {' '}
            <h2>Avanserte instillinger</h2>{' '}
          </Grid>
          <Grid item xs={12}>
            <h3>{adfsSetting && adfsSetting.scheme}</h3>
          </Grid>
          <Grid item xs={12}>
            <CallbackPath
              result={result}
              callbackValue={callbackCallbackPath}
              callbackError={callbackCallbackPathError}
              existingName={existingName}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <Button onClick={handleCloseAdvanceSetting} color="secondary" variant="contained">
              Avbryt
            </Button>
            <Button color="primary" variant="contained" className={classes.updateButton} disabled={!isFormValid()} onClick={() => handleSave()}>
              ENDRE
            </Button>
            {loadingCreate && <LoadingSpinner size={20} />}
          </Grid>
          <Grid item xs={12}>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={showErrorSnackbar}
              autoHideDuration={3000}
              onClose={handleCloseSnackbar}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
            >
              <SnackbarContent
                aria-describedby="message-id2"
                className={classes.snackbarStyleViaNestedContent}
                message={
                  <span id="message-id2">
                    <div>Endringene er lagert</div>
                  </span>
                }
                action={[
                  <IconButton key="close" aria-label="close" color="inherit" onClick={handleCloseSnackbar} size="large">
                    <Close />
                  </IconButton>,
                ]}
              />
            </Snackbar>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
