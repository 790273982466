import { AdfsSetting } from '../../interfaces';
import { DELETE_CLIENT_AUTHORIZATION, GET_SINGLE_ADFS_BY_BKS_ID } from './ADFSConfig.graphql';
import { useMutation } from '@apollo/client';
import ConfirmDialog from '../../components/ConfirmDialog';
export default function DeleteClientDialog({ open, id, onClose, name, scheme, bksID }) {
  const intBksID = bksID && parseInt(bksID);
  const [remove] = useMutation<any>(DELETE_CLIENT_AUTHORIZATION, {
    variables: {
      input: {
        id: id,
        name: name,
        scheme: scheme,
      },
    },
    update: (cache, { data: { deleteClientAuthorization } }) => {
      try {
        if (!deleteClientAuthorization) return;
        const data = cache.readQuery<{ adfs: AdfsSetting }>({
          query: GET_SINGLE_ADFS_BY_BKS_ID,
          variables: { intBksID },
        });
        if (data === null) return;
        cache.writeQuery({
          query: GET_SINGLE_ADFS_BY_BKS_ID,
          variables: { intBksID },
          data: { adfs: deleteClientAuthorization },
        });
      } catch (error) {
        console.error(error);
      }
    },
  });
  const onSave = async () => {
    await remove();
    onClose();
  };
  let msg = 'Vil du slette ' + name;
  return (
    <ConfirmDialog
      title="Slett client authorization"
      message={msg}
      open={open}
      confirm={onSave}
      deny={() => onClose()}
      confirmText="Slett"
      DialogProps={{ fullWidth: true, maxWidth: 'xs' }}
    />
  );
}
