import { TextField } from '@mui/material';
import { useState } from 'react';
interface Prop {
  callbackValue: (name: string) => void;
  existingSecret: string;
}
export default function ClientSecret(prop: Prop) {
  const { callbackValue: sendbackClientSecret, existingSecret } = prop;
  const [clientSecret, setClientSecret] = useState(existingSecret);
  const handleClientSecretBlur = (secret: string) => {
    sendbackClientSecret(secret);
  };
  return (
    <>
      <TextField
        label="Client Secret"
        fullWidth
        value={clientSecret}
        type="text"
        onChange={(event) => setClientSecret(event.target.value)}
        onBlur={(event) => handleClientSecretBlur(event.target.value)}
        margin="normal"
        required
      />
    </>
  );
}
