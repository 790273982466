import React from 'react';
import QueryWithLoading from '../components/QueryWithLoading';
import { Typography, Grid, Divider } from '@mui/material';
import WidgetPanel from './WidgetPanel';

export class NumberWidget extends React.PureComponent<NumberWidgetProps, any> {
  render() {
    let { heading, icon, query } = this.props;
    return (
      <WidgetPanel>
        <Typography variant="h5">{heading}</Typography>
        <Grid container>
          <Grid item xs={3} style={{ paddingTop: 6 }}>
            {icon}
          </Grid>
          <Grid item xs={9} style={{ paddingTop: 10 }}>
            <Typography align="right" variant="h5" color="primary">
              <QueryWithLoading query={query}>{({ data: { number } }) => number}</QueryWithLoading>
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        {this.props.children}
      </WidgetPanel>
    );
  }
}

export interface NumberWidgetProps {
  heading: string;
  query: string;
  icon: any;
}
