import { TableCell, TableBody, Table, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { numberAsKr } from '../Formatters';

export interface RenewalSummary {
  quantity: number;
  products: RenewalProduct[];
}

interface RenewalProduct {
  id: number;
  name: string;
  total: number;
}

const useStyles = makeStyles((theme) => ({
  total: {
    fontWeight: 'bold',
  },
  indent0: {},
  indent1: {
    paddingLeft: 8,
  },
  indent2: {
    paddingLeft: 16,
  },
}));

export function Summary({ summary }: { summary: RenewalSummary }) {
  const classes = useStyles();
  return (
    <Table padding="none">
      <TableBody>
        <TableRow>
          <TableCell>Antall kunder </TableCell>
          <TableCell align="right">{summary.quantity}</TableCell>
        </TableRow>
        {summary.products
          .filter((x) => x.total > 0)
          .map((product) => {
            return (
              <TableRow key={product.name}>
                <TableCell className={product.id === 9 ? classes.indent1 : product.id >= 10 && product.id <= 13 ? classes.indent2 : classes.indent0}>
                  {product.name}
                </TableCell>
                <TableCell align="right">{numberAsKr(product.total)}</TableCell>
              </TableRow>
            );
          })}
        <TableRow>
          <TableCell>Totalt </TableCell>
          <TableCell align="right">
            <span className={classes.total}>
              {numberAsKr(summary.products.filter((x) => x.id !== 0).reduce((sum, product) => sum + product.total, 0))}
            </span>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
