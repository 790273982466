import { Typography } from '@mui/material';
import { UserSearchableTable } from './UserSearchableTable';
import MainContentPanel from '../../components/MainContentPanel';

export default function Overview() {
  return (
    <MainContentPanel>
      <Typography gutterBottom variant="h5">
        Brukere
      </Typography>
      {<UserSearchableTable />}
    </MainContentPanel>
  );
}
