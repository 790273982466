import React from 'react';
import { List, Typography, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { ListItemAddress } from '../../components/ListItemAddress';
import { ListItemPhoneNumber } from '../../components/ListItemPhoneNumber';
import { ListItemEmail } from '../../components/ListItemEmail';
import { ListItemOrgNo } from './ListItemOrgNo';
import { ListItemCompanyName } from './ListItemCompanyName';
import Loading from '../../components/LoadingSpinner';
import { GET_MACONOMY_CUSTOMER_BY_LICENSE } from './Maconomy.graphql';
import { Link } from '@mui/icons-material';
import { useConfig } from '../../components/Configuration';
import { useQuery } from '@apollo/client';

interface Props {
  licenseId: number;
  display: boolean;
  setHasDiff(value: boolean): void;
}

export function MaconomyCustomer({ licenseId, display, setHasDiff }: Props) {
  const config = useConfig();
  const pollIntervall = config ? config.defaultPollingInterval * 2 : 0;
  const { loading, error, data } = useQuery(GET_MACONOMY_CUSTOMER_BY_LICENSE, { variables: { licenseId }, pollInterval: pollIntervall });

  setHasDiff(data && data.maconomyCustomerByLicense && !data.maconomyCustomerByLicense.hasSameDataAsUs);

  if (!display) return null;
  if (loading) return <Loading />;
  if (error || !data) return <Typography>Fikk ikke kontakt med Maconomy</Typography>;
  if (!data.maconomyCustomerByLicense) return <Typography>Ingen data funnet</Typography>;

  return <MaconomyCustomerCard customer={data.maconomyCustomerByLicense} />;
}

function MaconomyCustomerCard({ customer }) {
  return (
    <List>
      <ListItemCompanyName companyName={customer.companyName} />
      <ListItemOrgNo orgNumber={customer.orgNumber} />
      <ListItemEmail email={customer.email} />
      <ListItemPhoneNumber phone={customer.phone} />
      <ListItemAddress address={customer.address} />
      <ListItem>
        <ListItemIcon title="Kundenummer">
          <Link />
        </ListItemIcon>
        <ListItemText>
          <Typography>{customer.customerNumber}</Typography>
        </ListItemText>
      </ListItem>
    </List>
  );
}
