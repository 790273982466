import gql from 'graphql-tag';
import { UserItem } from '../interfaces.graphql';

export const GET_USER = gql`
  query ($id: Int!) {
    user: getUser(id: $id) {
      ...UserItem
    }
  }
  ${UserItem}
`;

export const GET_USERS = gql`
  query (
    $page: Int!
    $pageSize: Int!
    $onlyActive: Boolean!
    $activeSingleDocument: Boolean!
    $nonActiveSingleDocument: Boolean!
    $nonActiveForNumberYears: Int!
    $filter: String!
  ) {
    users: getUsers(
      page: $page
      pageSize: $pageSize
      onlyActive: $onlyActive
      activeSingleDocument: $activeSingleDocument
      nonActiveSingleDocument: $nonActiveSingleDocument
      nonActiveForNumberYears: $nonActiveForNumberYears
      filter: $filter
    ) {
      users {
        id
        displayName
        loginId
        email
        lastLoggedIn
        isActive
        isAdminuserOnActiveLicense
      }
      count
    }
  }
`;

export const GET_USERS_FROM_LICENSE = gql`
  query ($licenseId: String!) {
    users: getUsersFromLicense(licenseId: $licenseId) {
      id
      name
      numberOfUsers
      users {
        id
        userSubscriptionId
        firstName
        lastName
        email
        lastLoggedIn
        displayName
      }
    }
  }
`;

export const GET_USE_LOG = gql`
  query ($userId: String!, $fromDate: DateTimeOffset!, $toDate: DateTimeOffset) {
    logs: getUseLog(userId: $userId, fromDate: $fromDate, toDate: $toDate) {
      docId
      docName
      docNumber
      title
      publishedYear
      price
      visitCount
      medium
    }
  }
`;

export const UPDATE_NAME = gql`
  mutation ($input: UserUpdateNameInput!) {
    userUpdateName(input: $input) {
      id
      firstName
      lastName
      displayName
    }
  }
`;

export const UPDATE_LOGIN = gql`
  mutation ($input: UserUpdateLoginIdInput!) {
    userUpdateLoginId(input: $input) {
      id
      loginId
    }
  }
`;

export const UPDATE_EMAIL = gql`
  mutation ($input: UserUpdateEmailInput!) {
    userUpdateEmail(input: $input) {
      id
      email
    }
  }
`;

export const UPDATE_PHONE = gql`
  mutation ($input: UserUpdatePhoneInput!) {
    userUpdatePhone(input: $input) {
      id
      phone
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation ($input: UserUpdateAddressInput!) {
    userUpdateAddress(input: $input) {
      id
      address {
        id
        address1
        address2
        postalCode
        city
        country
      }
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation ($input: UserDeleteInput!) {
    userDelete(input: $input) {
      id
      error
    }
  }
`;

export const DELETE_CUSTOMERS = gql`
  mutation UsersDelete($input: UsersDeleteInput!) {
    usersDelete(input: $input) {
      ids
    }
  }
`;

export const GET_ACCESSES = gql`
  query ($id: Int!) {
    userAccess: getUserAccess(userId: $id) {
      id
      isDeleted
      license {
        id
        companyName
      }
      subscription {
        id
        name
      }
      createdAt
    }
  }
`;

export const GET_LAST_RESET_PASSWORD_URL = gql`
  query ($id: Int!) {
    url: getLastResetPasswordUrl(userId: $id)
  }
`;

export const GET_BOUNCED = gql`
  query ($id: Int!) {
    bounced: getUserBounced(id: $id) {
      id
      isBounced
      reason
    }
  }
`;

export const REMOVE_BOUNCE = gql`
  mutation ($input: UserRemoveBounceInput!) {
    userRemoveBounce(input: $input) {
      id
      isBounced
      reason
    }
  }
`;

export const GET_LICENSES_WHERE_IS_ADMIN = gql`
  query ($id: Int!) {
    licenses: getLicensesForAdministrator(userId: $id) {
      id
      companyName
    }
  }
`;

export const GET_USER_ACCESS_HISTORY = gql`
  query ($id: Int!) {
    accesses: getUserAccessHistory(userId: $id) {
      subscriptionId
      subscriptionName
      licenseId
      licenseName
      from
      to
    }
  }
`;
