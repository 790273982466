import gql from 'graphql-tag';
import { UserItem } from '../../interfaces.graphql';
import { DataProxy } from '@apollo/client/cache';
import { LicenseSubscription } from '../../interfaces';

export const GET_SUBSCRIPTIONS = gql`
  query ($id: Int!) {
    subscriptions: licenseAccess(licenseId: $id) {
      id
      licenseId
      subscription {
        id
        name
        parentId
      }
      numberOfUsers
      userAccess {
        id
        createdAt
        user {
          ...UserItem
        }
      }
    }

    paidSubscriptions: allSubscriptions(onlyPaid: true) {
      id
      name
      parentId
    }

    license: licenseModel(licenseId: $id) {
      id
      pendingSubscriptionMarkdowns {
        id
        subscriptionId
        markdown
      }
      pendingSubscriptionRemovals
      pendingSubscriptionDowngrades {
        id
        fromSubscriptionId
        toSubscriptionIds
      }
    }
  }
  ${UserItem}
`;

export const ADD_USERS_TO_SUBSCRIPTIONS = gql`
  mutation ($input: AddUserAccessInput!) {
    subscriptions: addUserAccess(input: $input) {
      id
      licenseId
      subscription {
        id
        name
        parentId
      }
      numberOfUsers
      userAccess {
        id
        createdAt
        user {
          ...UserItem
        }
      }
    }
  }
  ${UserItem}
`;

export const REMOVE_USER_ACCESS = gql`
  mutation ($input: RemoveUserAccessInput!) {
    removeUserAccess(input: $input) {
      id
    }
  }
`;

export const REMOVE_USER_ACCESSES = gql`
  mutation ($input: RemoveUserAccessesInput!) {
    removeUserAccesses(input: $input) {
      id
    }
  }
`;

export const INCREASE_ACCESS = gql`
  mutation IncreaseAccess($input: IncreaseSubscriptionInput!) {
    increaseSubscription(input: $input) {
      id
      licenseId
      subscription {
        id
        name
      }
      numberOfUsers
      userAccess {
        id
        createdAt
        user {
          ...UserItem
        }
      }
    }
  }
  ${UserItem}
`;

export const DECREASE_ACCESS = gql`
  mutation DecreaseAccess($input: DecreaseSubscriptionInput!) {
    decreaseSubscription(input: $input) {
      id
      pendingSubscriptionMarkdowns {
        id
        subscriptionId
        markdown
      }
    }
  }
`;

export const CANCEL_DECREASE_ACCESS = gql`
  mutation DecreaseAccess($input: CancelDecreaseSubscriptionInput!) {
    cancelDecreaseSubscription(input: $input) {
      id
      pendingSubscriptionMarkdowns {
        id
        subscriptionId
        markdown
      }
    }
  }
`;

export const ADD_SUBSCRIPTIONS = gql`
  mutation AddSubscriptions($input: AddSubscriptionsInput!) {
    addSubscriptions(input: $input) {
      id
      isActive
      licenseStatus
      paidSubscriptions {
        id
        licenseId
        subscription {
          id
          name
          parentId
        }
        numberOfUsers
        userAccess {
          id
          createdAt
          user {
            ...UserItem
          }
        }
      }
    }
  }
  ${UserItem}
`;

export const UPGRADE_SUBSCRIPTION = gql`
  mutation UpgradeSubscription($input: UpgradeSubscriptionInput!) {
    upgradeSubscription(input: $input) {
      id
      licenseId
      subscription {
        id
        name
        parentId
      }
      numberOfUsers
      userAccess {
        id
        createdAt
        user {
          ...UserItem
        }
      }
    }
  }
  ${UserItem}
`;

export const REMOVE_SUBSCRIPTION = gql`
  mutation RemoveSubscription($input: RemoveSubscriptionInput!) {
    removeSubscription(input: $input) {
      id
      pendingTermination
      terminationReason
      pendingSubscriptionRemovals
      pendingSubscriptionDowngrades {
        id
        fromSubscriptionId
        toSubscriptionIds
      }
      pendingSubscriptionMarkdowns {
        id
        subscriptionId
        markdown
      }
    }
  }
`;

export const CANCEL_REMOVE_SUBSCRIPTION = gql`
  mutation CancelRemoveSubscription($input: CancelRemoveSubscriptionInput!) {
    cancelRemoveSubscription(input: $input) {
      id
      pendingSubscriptionRemovals
    }
  }
`;

export const DOWNGRADE_SUBSCRIPTION = gql`
  mutation DowngradeSubscription($input: DowngradeSubscriptionInput!) {
    downgradeSubscription(input: $input) {
      id
      pendingSubscriptionDowngrades {
        id
        fromSubscriptionId
        toSubscriptionIds
      }
    }
  }
`;

export const CANCEL_DOWNGRADE_SUBSCRIPTION = gql`
  mutation CancelDowngradeSubscription($input: CancelDowngradeSubscriptionInput!) {
    cancelDowngradeSubscription(input: $input) {
      id
      pendingSubscriptionDowngrades {
        id
        fromSubscriptionId
        toSubscriptionIds
      }
    }
  }
`;

export const UPDATE_TWO_FACTOR_AUTHENTICATION = gql`
  mutation UpdateTwoFactorAuthentication($input: UpdateTwoFactorAuthenticationInput!) {
    updateTwoFactorAuthentication(input: $input) {
      id
      requireTwoFactorAuthentication
    }
  }
`;

export const UPGRADE_DIALOG_QUERY = gql`
  query ($id: Int!, $subscriptionId: Int!) {
    upgradeDialog(licenseId: $id, subscriptionId: $subscriptionId) {
      message
      accessCount
      sumFreeSeats
      maxFreeSeats
    }
  }
`;

export const GET_SUBSCRIPTION_CHANGE_STATUS = gql`
  query ($id: Int!) {
    status: getSubscriptionChangeStatus(licenseId: $id) {
      isConnectedToMaconomy
      hasPendingChanges
    }
  }
`;

export const GET_USER_DATA_FOR_SUBSCRIPTION = gql`
  query ($licenseId: Int!, $subscriptionId: Int!) {
    users: getUserDataForSubscription(licenseId: $licenseId, subscriptionId: $subscriptionId) {
      id
      userId
      guideViewsCount
    }
  }
`;

export let overwriteLicenseQueryCache = (cache: DataProxy, licenseId: number, subscriptions: LicenseSubscription[]) => {
  let queryVariable = { id: licenseId };
  let result = cache.readQuery({ query: GET_SUBSCRIPTIONS, variables: queryVariable }) as any;
  cache.writeQuery({
    query: GET_SUBSCRIPTIONS,
    variables: queryVariable,
    data: { subscriptions, paidSubscriptions: result.paidSubscriptions, license: result.license },
  });
};
