import React from 'react';
import { ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, IconButton } from '@mui/material';
import { Person, Edit } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { UpdateNameDialog } from './UpdateNameDialog';

interface Props {
  userId: number;
  firstName: string;
  lastName: string;
  displayName: string;
  update?(firstName: string, lastName): void;
  disableLink?: boolean;
}

interface State {
  open: boolean;
}

export class ListItemName extends React.PureComponent<Props, State> {
  state = {
    open: false,
  };

  edit(edit: boolean) {
    this.setState({ open: edit });
  }

  render() {
    let { userId, firstName, lastName, displayName, update, disableLink } = this.props;
    let { open } = this.state;
    let firstNameValue = firstName ? firstName : '';
    let lastNameValue = lastName ? lastName : '';

    return (
      <ListItem>
        <ListItemIcon title="Navn">
          <Person />
        </ListItemIcon>
        <ListItemText>
          {disableLink && displayName}
          {!disableLink && <Link to={`/user/${userId}`}>{displayName}</Link>}
        </ListItemText>
        {update && (
          <>
            <ListItemSecondaryAction>
              <IconButton onClick={() => this.edit(true)} size="large">
                <Edit />
              </IconButton>
            </ListItemSecondaryAction>
            <UpdateNameDialog firstName={firstNameValue} lastName={lastNameValue} open={open} onClose={() => this.edit(false)} update={update} />
          </>
        )}
      </ListItem>
    );
  }
}
