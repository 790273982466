import React, { useState } from 'react';
import { ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { UpdateValueDialog } from './UpdateValueDialog';

interface Props {
  value: string;
  icon: React.ReactElement<any>;
  update?(value: string): void;
  title?: string;
  label?: string;
  type?: string;
}

export function ListItemWithEdit({ value, update, title, label, icon, type }: Props) {
  const [open, setOpen] = useState(false);

  return (
    <ListItem>
      <ListItemIcon title={label}>{icon}</ListItemIcon>
      <ListItemText>{value}</ListItemText>
      {update && (
        <>
          <ListItemSecondaryAction>
            <IconButton title={title} onClick={() => setOpen(true)} size="large">
              <Edit />
            </IconButton>
          </ListItemSecondaryAction>
          <UpdateValueDialog title={title} label={label} type={type} value={value} open={open} onClose={() => setOpen(false)} update={update} />
        </>
      )}
    </ListItem>
  );
}
