import React from 'react';
import { Business, Lock } from '@mui/icons-material';
import { NumberWidget } from './NumberWidget';
import { Link } from 'react-router-dom';

var query = `
    query { 
        number: lockedLicenses
    }`;

export class LockedLicenses extends React.PureComponent<any, any> {
  render() {
    return (
      <NumberWidget
        heading="Låste kunder"
        query={query}
        icon={
          <span>
            <Lock fontSize="large" />
            <Business fontSize="large" />
          </span>
        }
      >
        <Link to="/license?filter=locked">Se kundene</Link>
      </NumberWidget>
    );
  }
}
