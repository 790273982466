import { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
} from '@mui/material';
import { OrderLine } from '../../interfaces';
import { Delete } from '@mui/icons-material';

interface Props {
  open: boolean;
  orderlines: OrderLine[];
  canSubmit: boolean;
  onCancel: () => void;
  onSubmit: (discardedOrderLines: OrderLine[]) => void;
  onAnnul: () => void;
}

export default function CreateInvoiceDialog({ open, orderlines, onCancel, onSubmit, onAnnul, canSubmit }: Props) {
  const [discardedOrderLines, setDiscardedOrderLines] = useState<OrderLine[]>([]);

  function close() {
    setDiscardedOrderLines([]);
    onCancel();
  }

  function submit() {
    onSubmit(discardedOrderLines);
    setDiscardedOrderLines([]);
  }

  function formatAmount(num) {
    return num.toLocaleString('nb-NO', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  }

  function discard(orderLine: OrderLine) {
    setDiscardedOrderLines([orderLine, ...discardedOrderLines]);
  }

  const orderlinesToInvoice = orderlines ? orderlines.filter((x) => !discardedOrderLines.includes(x)) : undefined;

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="md">
      <DialogTitle>Opprett faktura</DialogTitle>
      <DialogContent>
        {orderlinesToInvoice && orderlinesToInvoice.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Antall</TableCell>
                <TableCell>Beskrivelse</TableCell>
                <TableCell align="right">Pris</TableCell>
                <TableCell align="right">Rabatt</TableCell>
                <TableCell align="right">Total</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderlinesToInvoice.map((ol, index) => (
                <TableRow key={index}>
                  <TableCell>{ol.numberOfAccesses}</TableCell>
                  <TableCell>{ol.description}</TableCell>
                  <TableCell align="right">{formatAmount(ol.amount)}</TableCell>
                  <TableCell align="right">{formatAmount(ol.discount)}</TableCell>
                  <TableCell align="right">{formatAmount(ol.amount - ol.discount)}</TableCell>
                  <TableCell>
                    <IconButton title="Slett fra faktura" onClick={() => discard(ol)} size="large">
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {orderlinesToInvoice && orderlinesToInvoice.length === 0 && <Typography>Ingen endringer å fakturere</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Avbryt</Button>
        {canSubmit && (
          <Button variant="contained" color="secondary" onClick={onAnnul}>
            Ikke opprett faktura
          </Button>
        )}
        {canSubmit && (
          <Button variant="contained" color="primary" disabled={!orderlinesToInvoice || orderlinesToInvoice.length === 0} onClick={submit}>
            Opprett faktura
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
