import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography } from '@mui/material';

interface Props {
  open: boolean;
  message: string;
  accessCount: number;
  sumFreeSeats: number;
  maxFreeSeats: number;
  parentName: string;
  onCancel: () => void;
  onSubmit: (numberOfAccesses: number) => void;
}

export default function UpgradeSubscriptionDialog({ open, message, accessCount, sumFreeSeats, maxFreeSeats, parentName, onSubmit, onCancel }: Props) {
  const [freeSeats, setFreeSeats] = useState(sumFreeSeats);

  const cancel = () => {
    setFreeSeats(sumFreeSeats);
    onCancel();
  };

  const submit = () => {
    onSubmit(accessCount + freeSeats);
    setFreeSeats(sumFreeSeats);
  };

  return (
    <Dialog open={open} onClose={cancel} maxWidth="sm" fullWidth>
      <DialogTitle>Oppgradering</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>{message}</Typography>
        <Typography gutterBottom>
          {accessCount > 0 && `${accessCount > 1 ? `${accessCount} tilordnede brukere` : '1 tilordnet bruker'} vil bli oppgradert. `}
          {sumFreeSeats > 0 &&
            `Det er ${sumFreeSeats > 1 ? `til sammen ${sumFreeSeats} ledige tilganger` : '1 ledig tilgang'} på oppgraderte serier. ` +
              `Hvor mange ledige tilganger er ønsket på ${parentName}?`}
        </Typography>
        {sumFreeSeats > 0 && (
          <>
            <br />
            <TextField
              label="Ledige tilganger"
              value={freeSeats}
              type="number"
              onChange={(e) => setFreeSeats(Number(e.target.value))}
              inputProps={{ min: maxFreeSeats }}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>Avbryt</Button>
        <Button variant="contained" color="primary" onClick={submit}>
          Oppgradér
        </Button>
      </DialogActions>
    </Dialog>
  );
}
