import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Cancel as CancelIcon } from '@mui/icons-material'; // Import Cancel icon
import { SingleDocOrderLine } from '../../interfaces';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: 'center',
  },
  tableCell: {
    fontWeight: 'bold',
    position: 'relative',
  },
  cancelIcon: {
    position: 'absolute',
    right: 0,
    cursor: 'pointer',
  },
}));

interface PropsSingleDoc {
  open: boolean;
  singleDocOrderlines: SingleDocOrderLine[];
  onCancel: () => void;
  onSubmit: () => void;
  onRemoveItem: (index: number) => void;
  disabled: boolean;
  // New prop to handle item removal
}

export default function CreateSingleDocInvoiceDialog({ open, singleDocOrderlines, onCancel, onSubmit, onRemoveItem, disabled }: PropsSingleDoc) {
  const classes = useStyles();
  function formatAmount(num) {
    return num.toLocaleString('nb-NO', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  }

  return (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth="md">
      <DialogTitle className={classes.dialogTitle}>Opprett faktura</DialogTitle>
      <DialogContent>
        {singleDocOrderlines && singleDocOrderlines.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell} style={{ width: '80%' }}>
                  Anvisning
                </TableCell>
                <TableCell className={classes.tableCell} align="left" style={{ width: '5%' }}>
                  Pris
                </TableCell>
                <TableCell className={classes.tableCell} align="left" style={{ width: '15%' }}>
                  Pris inkl. mva
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {singleDocOrderlines.map((ol, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.tableCell}>
                    <span>{ol.docName}</span>
                    <span style={{ marginLeft: '10px' }}>{ol.title}</span>
                  </TableCell>
                  <TableCell align="left">{formatAmount(ol.price)}</TableCell>
                  <TableCell align="left">
                    {formatAmount(ol.priceIncVat)}
                    <IconButton className={classes.cancelIcon} onClick={() => onRemoveItem(index)}>
                      <CancelIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {singleDocOrderlines && singleDocOrderlines.length === 0 && <Typography>Ingen endringer å fakturere</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Avbryt</Button>
        <Button variant="contained" color="primary" onClick={onSubmit} disabled={disabled}>
          Opprett faktura
        </Button>
      </DialogActions>
    </Dialog>
  );
}
