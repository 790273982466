import React, { useState } from 'react';
import { SearchField } from '../../components/SearchField';
import Fuse from 'fuse.js';
import { LicenseTable } from './LicenseTable';
import { ISettings } from './ColumnDefinitions';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  List,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  useTheme,
} from '@mui/material';
import { Counter } from '../../components/Counter';
import { LicenseOverviewModel } from './ColumnDefinitions';
import { ExcelExport } from './ExcelExport';

export interface Props {
  licenses: LicenseOverviewModel[];
  filter: any;
  report: any;
  setOnlyActive(val: boolean): void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

interface SearchableLicenseTableProps {
  licenses: LicenseOverviewModel[];
  onlyActive: boolean;
  onlyLocked: boolean;
  onlyPendTerm: boolean;
  onlyADFS: boolean;
  onlyInformed: boolean;
  setSearchTermParent(val: string);
  setOnlyActive(val: boolean): void;
  setOnlyLocked(val: boolean): void;
  setOnlyPendTerm(val: boolean): void;
  setOnlyADFS(val: boolean): void;
  setOnlyInformed(val: boolean): void;
  setColumns(ids: boolean[]): void;
  columnlist: ISettings[];
  searchTermParent: string;
}

export function SearchableLicenseTable({
  licenses,
  onlyActive,
  onlyLocked,
  onlyPendTerm,
  onlyADFS,
  onlyInformed,
  setSearchTermParent,
  setOnlyActive,
  setOnlyLocked,
  setOnlyPendTerm,
  setOnlyADFS,
  setOnlyInformed,
  setColumns,
  columnlist,
  searchTermParent,
}: SearchableLicenseTableProps) {
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState(searchTermParent);
  const [checkList, setCheckList] = useState(columnlist.map((c) => c.showColumn));
  const [licenseColumnsNameValues, setLicenseColumnsNameValues] = useState<string[]>(
    columnlist.filter((c) => c.showColumn === true).map((c) => c.name),
  );

  const handleChange = (event: SelectChangeEvent<string[]>, child?) => {
    const {
      target: { value },
    } = event;

    const id = child.props.value;

    const index = columnlist.findIndex((col) => col.name === id);

    const newArr = [...checkList];
    newArr[index] = !newArr[index];
    setCheckList(newArr);

    setLicenseColumnsNameValues(typeof value === 'string' ? value.split(',') : value);
  };

  const handleClose = () => {
    setColumns(checkList);
    setSearchTermParent(searchTerm);
  };

  const filterLicenses = (): LicenseOverviewModel[] => {
    if (searchTerm) sessionStorage.setItem('searchTerm', searchTerm);

    const options = {
      shouldSort: false,
      tokenize: true,
      matchAllTokens: true,
      threshold: 0,
      location: 0,
      distance: 10,
      maxPatternLength: 32,
      minMatchCharLength: 3,
      keys: columnlist.filter((c) => c.showColumn === true).map((c) => c.columnName),
    };
    if (!searchTerm) {
      let cachedSearchTerm = sessionStorage.getItem('searchTerm');
      if (cachedSearchTerm) {
        setSearchTerm(cachedSearchTerm);
      }
      return licenses;
    } else {
      var fuse = new Fuse(licenses, options);
      var filtered = fuse.search(searchTerm.trim());
      return filtered;
    }
  };

  const filteredLicenses = filterLicenses();
  return (
    <div>
      <SearchField filter={setSearchTerm} parentSearchTerm={searchTerm} />

      <List component={Stack} direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <FormControlLabel control={<Switch onChange={() => setOnlyActive(!onlyActive)} checked={onlyActive} />} label="Kun aktive" />
          <FormControlLabel control={<Switch onChange={() => setOnlyLocked(!onlyLocked)} checked={onlyLocked} />} label="Kun låste" />
          <FormControlLabel
            control={<Switch onChange={() => setOnlyPendTerm(!onlyPendTerm)} checked={onlyPendTerm} />}
            label="Kun under oppsigelse"
          />
          <FormControlLabel control={<Switch onChange={() => setOnlyADFS(!onlyADFS)} checked={onlyADFS} />} label="ADFS" />
          <FormControlLabel
            control={<Switch onChange={() => setOnlyInformed(!onlyInformed)} checked={onlyInformed} />}
            label="Kun informet for ekstra bruker"
          />
        </Stack>
        <Stack direction="row" alignItems="center">
          <div>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-name-label">Valgte kolonner: </InputLabel>
              <Select
                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={licenseColumnsNameValues}
                onChange={handleChange}
                onClose={handleClose}
                input={<OutlinedInput label="Valgte kolonner:" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {columnlist.map((column, index) => (
                  <MenuItem key={column.name} value={column.name} style={getStyles(column.name, licenseColumnsNameValues, theme)}>
                    <Checkbox checked={checkList[index]} />
                    <ListItemText primary={column.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <ExcelExport items={filteredLicenses} columnlist={columnlist} />
          <Counter list={filteredLicenses} />
        </Stack>
      </List>

      <LicenseTable personName={licenseColumnsNameValues} items={columnlist} licenses={filteredLicenses} />
    </div>
  );
}
