import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { UPDATE_PRICE } from './pdf.graphql';
import moment from 'moment';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MomentUtils from '@date-io/moment';
import { GuidePriceModel, useActionStyles } from './overview';

export function UpdatePriceDialog({ pdf, open, onClose, setBusy }: { pdf: GuidePriceModel; open: boolean; onClose(); setBusy(busy: boolean) }) {
  const classes = useActionStyles();
  const defaultTimeOfChange = moment().add(1, 'years').startOf('year').toDate();

  const [price, setPrice] = useState(pdf.price);
  const [timeOfChange, setTimeOfChange] = useState(defaultTimeOfChange);

  const [updateValue, { loading }] = useMutation(UPDATE_PRICE, { variables: { input: { price, timeOfChange } } });

  useEffect(() => {
    setBusy(loading);
  }, [loading, setBusy]);

  const priceChanged = price !== pdf.price;

  const onCommit = () => {
    updateValue();
    onClose();
  };

  const close = () => {
    setPrice(pdf.price);
    onClose();
  };

  if (!pdf) return null;

  const onTimeOfChangeChanged = (newValue) => {
    if (newValue) setTimeOfChange(newValue);
    else setTimeOfChange(defaultTimeOfChange);
  };

  return (
    <Dialog onClose={close} aria-labelledby="dialog-title" open={open}>
      <DialogTitle id="dialog-title">
        Endre pris
        <IconButton aria-label="close" className={classes.closeButton} onClick={close} size="large">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <form>
          <div className={classes.timePanel}>
            <Typography>Når skal endringen gjelde fra?</Typography>
            <div>
              <LocalizationProvider utils={MomentUtils} dateAdapter={AdapterDateFns}>
                <DatePicker
                  disablePast
                  showTodayButton
                  inputFormat="d/M/yyyy"
                  label="dato"
                  value={timeOfChange}
                  onChange={onTimeOfChangeChanged}
                  renderInput={(params) => <TextField {...params} helperText={params?.inputProps?.placeholder} />}
                />
                <TimePicker
                  className={classes.timePicker}
                  ampm={false}
                  inputFormat="HH:mm"
                  label="tidspunkt"
                  value={timeOfChange}
                  onChange={onTimeOfChangeChanged}
                  renderInput={(params) => <TextField {...params} helperText={params?.inputProps?.placeholder} />}
                />
              </LocalizationProvider>
            </div>
          </div>
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
              <TextField
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">kr</InputAdornment>,
                  inputProps: { min: 0 },
                }}
                label="Ny pris"
                helperText="Beløp for 1 pdf"
                type="number"
                margin="normal"
                required
                value={price}
                onChange={(ev) => setPrice(Number(ev.target.value))}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="secondary">
          Avbryt
        </Button>
        <Button onClick={onCommit} disabled={!priceChanged} color="primary" variant="contained">
          Endre pris
        </Button>
      </DialogActions>
    </Dialog>
  );
}
