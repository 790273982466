import React from 'react';
import { Typography, Button } from '@mui/material';
import { Banner } from '../../components/Banner';
import { Lock } from '@mui/icons-material';
import { UNLOCK } from './LicenseInfo.graphql';
import { License, LicenseModel } from '../../interfaces';
import { useMutation } from '@apollo/client';

export function LockedBanner({ license, bksLicense }: { bksLicense: License; license: LicenseModel }) {
  const [lock] = useMutation(UNLOCK, { variables: { input: { id: bksLicense.id } } });
  if (!bksLicense.isLocked) return null;

  const reason = license ? license.lockedReason : null;
  const header = reason ? `Låst: ${reason}` : 'Låst';
  const action = <Button onClick={() => lock()}>Gjenåpne</Button>;
  return (
    <Banner icon={<Lock />} action={action}>
      <Typography variant="body2">{header}</Typography>
    </Banner>
  );
}
