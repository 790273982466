import React from 'react';
import { Group } from '@mui/icons-material';
import { NumberWidget } from './NumberWidget';

var query = `
    query { 
        number: pendingMarkdowns
    }`;

export function PendingMarkdowns() {
  return <NumberWidget heading="Tilganger under oppsigelse" query={query} icon={<Group fontSize="large" />}></NumberWidget>;
}
