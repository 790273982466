import React from 'react';
import { Typography, Button } from '@mui/material';
import MainContentPanel from '../../components/MainContentPanel';
import QueryWithLoading from '../../components/QueryWithLoading';
import { ChangeLog } from '../../components/ChangeLog';
import { withConfiguration } from '../../components/Configuration';
import { Config } from '../../config';

interface Props {
  userId: number;
  config?: Config;
}

interface State {
  offset: number;
  moreToLoad: boolean;
}

let query = `
query ($id: Int!, $offset: Int, $limit: Int) { 
    changes: getUserChangeLog(id: $id, offset: $offset, limit: $limit)  {
        id
        isExternal
        description
        createdAt      
        createdBy 
    }
}`;

class ChangeLogComponent extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      offset: 0,
      moreToLoad: true,
    };
  }

  render() {
    let { userId, config } = this.props;
    let { offset, moreToLoad } = this.state;
    const pollIntervall = config ? config.defaultPollingInterval : 0;
    return (
      <MainContentPanel>
        <Typography variant="h5">Endringer</Typography>
        <QueryWithLoading query={query} variables={{ id: userId, offset, limit: 20 }} pollInterval={pollIntervall}>
          {({ data, fetchMore }) => (
            <>
              <ChangeLog changes={data.changes} />
              {data.changes && (
                <Button
                  disabled={!moreToLoad}
                  onClick={() =>
                    fetchMore({
                      variables: { offset: data.changes.length },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult.changes || fetchMoreResult.changes.length === 0) {
                          this.setState({ moreToLoad: false });
                          return prev;
                        }

                        return Object.assign({}, prev, {
                          changes: [...prev.changes, ...fetchMoreResult.changes],
                        });
                      },
                    })
                  }
                >
                  Last flere
                </Button>
              )}
            </>
          )}
        </QueryWithLoading>
      </MainContentPanel>
    );
  }
}

export let ChangeLogCard = withConfiguration()(ChangeLogComponent);
