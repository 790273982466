import React, { useState } from 'react';
import { ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, IconButton } from '@mui/material';
import { LocationOn, Edit } from '@mui/icons-material';
import { UpdateAddressDialog } from './UpdateAddressDialog';
import { Address } from '../interfaces';

interface AddressProps {
  address?: Address;
  update?(address: Address): void;
}

export function ListItemAddress({ address, update }: AddressProps) {
  const [open, setOpen] = useState(false);

  if (!address) return null;

  return (
    <ListItem>
      <ListItemIcon title="Adresse">
        <LocationOn />
      </ListItemIcon>
      <ListItemText>
        <div>
          <div>{address.address1}</div>
          <div>{address.address2}</div>
          <div>
            {address.postalCode} {address.city}
          </div>
          <div>{address.country}</div>
        </div>
      </ListItemText>
      {update && (
        <>
          <ListItemSecondaryAction>
            <IconButton title="Endre adresse" onClick={() => setOpen(true)} size="large">
              <Edit />
            </IconButton>
          </ListItemSecondaryAction>
          <UpdateAddressDialog address={address} open={open} onClose={() => setOpen(false)} updateAddress={update} />
        </>
      )}
    </ListItem>
  );
}
