import React from 'react';
import { Grid } from '@mui/material';
import { UserInfo } from './UserInfo';
import { UserAccessCard } from './UserAccessCard';
import { ChangeLogCard } from './ChangeLogCard';
import { Notifications } from './Notifications';
import Comments from './Comments';
import { BouncedBanner } from './BouncedBanner';
import { UserAdministratorCard } from './UserAdministratorCard';

export default function Details(props) {
  const userId = Number(props.match.params.id);

  return (
    <Grid container direction="row">
      <Grid item container xs={12}>
        <BouncedBanner userId={userId} />
      </Grid>
      <Grid item container xs={9} alignItems="stretch">
        <Grid item xs={6}>
          <UserInfo userId={userId} />
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="row">
            <Grid item xs={12}>
              <UserAccessCard userId={userId} />
            </Grid>
            <Grid item xs={12}>
              <UserAdministratorCard userId={userId} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Comments userId={userId} />
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <ChangeLogCard userId={userId} />
      </Grid>
      <Grid item xs={3}>
        <Notifications userId={userId} />
      </Grid>
    </Grid>
  );
}
