import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Task } from '../../interfaces';
import Property from '../../components/Property';
import { formatDate } from '../../Formatters';

interface Props {
  open: boolean;
  task: Task;
  onClose: () => void;
}

export default class DetailsDialog extends React.PureComponent<Props, {}> {
  render() {
    const { open, task, onClose } = this.props;
    const labelWidth = 120;
    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Detaljer</DialogTitle>
        <DialogContent>
          <Property label="Kunde:" text={task.licenseName} labelWidth={labelWidth} />
          <Property label="Opprettet av:" text={`${task.createdByName} ${formatDate(task.createdAt)}`} labelWidth={labelWidth} />
          {task.lastUpdatedAt !== task.createdAt && (
            <Property label="Sist endret av:" text={`${task.lastUpdatedByName} ${formatDate(task.lastUpdatedAt)}`} labelWidth={labelWidth} />
          )}
          <Property
            label="Beskrivelse:"
            text={task.description}
            fragment={task.markup ? <div dangerouslySetInnerHTML={{ __html: task.markup }} /> : undefined}
            labelWidth={labelWidth}
            preWrap
            multiline
          />
          {task.comment && <Property label="Kommentar:" text={task.comment} labelWidth={labelWidth} preWrap multiline />}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={onClose}>
            Lukk
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
