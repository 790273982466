import React from 'react';
import state from './../state';

export interface UserProps {
  profile: Profile;
}

interface Profile {
  name: string;
}

export function withUser(): <P extends object>(WrappedComponent: React.ComponentType<P>) => React.ComponentType<P> {
  return <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    class BadgedComponent extends React.Component<P> {
      render() {
        var propsWithUser = {
          ...this.props,
          user: { profile: state.userProfile },
        };
        return <WrappedComponent {...propsWithUser} />;
      }
    };
}

export function useUserProfile(): Profile {
  return state.userProfile;
}
