import React from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Switch, FormControlLabel } from '@mui/material';
import { Task } from '../../interfaces';
import Property from '../../components/Property';
import { formatDate } from '../../Formatters';

interface Props {
  open: boolean;
  task: Task;
  onCancel: () => void;
  onSubmit: (isComplete: boolean, comment?: string) => void;
}

interface State {
  isComplete: boolean;
  comment?: string;
}

export default class EditDialog extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isComplete: false, comment: props.task.comment };
  }
  render() {
    const { open, task, onCancel, onSubmit } = this.props;
    const { isComplete, comment } = this.state;
    const labelWidth = 120;
    return (
      <Dialog open={open} onClose={onCancel} maxWidth="sm" fullWidth>
        <DialogTitle>Redigér oppgave</DialogTitle>
        <DialogContent>
          <form id="task-form" onSubmit={() => onSubmit(isComplete, comment)}>
            <Property label="Kunde:" text={task.licenseName} labelWidth={labelWidth} />
            <Property label="Opprettet av:" text={`${task.createdByName} ${formatDate(task.createdAt)}`} labelWidth={labelWidth} />
            {task.lastUpdatedAt !== task.createdAt && (
              <Property label="Sist endret av:" text={`${task.lastUpdatedByName} ${formatDate(task.lastUpdatedAt)}`} labelWidth={labelWidth} />
            )}
            <Property
              label="Beskrivelse:"
              text={task.description}
              fragment={task.markup ? <div dangerouslySetInnerHTML={{ __html: task.markup }} /> : undefined}
              labelWidth={labelWidth}
              preWrap
              multiline
            />
            <br />
            <TextField
              label="Kommentar"
              value={comment ?? ''}
              onChange={(event) => this.setState({ comment: event.target.value })}
              multiline
              fullWidth
            />
            <FormControlLabel
              control={<Switch onChange={() => this.setState({ isComplete: !isComplete })} checked={isComplete} autoFocus />}
              label="Merk som fullført"
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Avbryt</Button>
          <Button type="submit" variant="contained" color="primary" form="task-form">
            Lagre
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
