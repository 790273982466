import { Paper, Grid, Typography, Button } from '@mui/material';
import { AdfsSetting } from '../../interfaces';
import NewClientAuthorizationBtn from './NewClientAuthorizationBtn';
import AuthorizationBox from './AuthorizationBox';
import { useQuery } from '@apollo/client';
import { GET_SINGLE_ADFS_BY_BKS_ID } from './ADFSConfig.graphql';
import { useHistory } from 'react-router-dom';

export default function ADFSClientAuthorization(props) {
  const adfs: AdfsSetting = props.location.state && props.location.state.adfs;
  const id = adfs && adfs.id;
  const scheme = adfs ? adfs.scheme : '';
  const bksID = adfs && adfs.bksID;
  const { data } = useQuery<{ adfs: AdfsSetting }>(GET_SINGLE_ADFS_BY_BKS_ID, { variables: { bksID } });
  const adfsSetting = data && data.adfs;
  let history = useHistory();
  const handleCloseClientAuthorizationSetting = () => {
    history.goBack();
  };
  return (
    <>
      <Paper elevation={2}>
        <Grid container spacing={1} direction="row" style={{ padding: 20 }}>
          <Grid item xs={12}>
            <h2>{adfsSetting && adfsSetting.scheme}</h2>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: 600 }}>
                Client Authorization
                <NewClientAuthorizationBtn id={id} scheme={scheme} bksID={bksID} />
              </Typography>
              <Paper elevation={4} style={{ marginTop: 40 }}>
                <Grid container spacing={1} direction="column" style={{ padding: 20 }}>
                  {adfsSetting &&
                    adfsSetting.clientAuthorizations &&
                    adfsSetting.clientAuthorizations.map((_item, _index) => {
                      return <AuthorizationBox key={_index} clientAuthorization={_item} id={id} scheme={scheme} bksID={bksID} />;
                    })}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={handleCloseClientAuthorizationSetting} color="secondary" variant="contained">
              Avbryt
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
