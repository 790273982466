import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_BUSINESS_CODE, GET_EMPLOYEE, GET_COMPANY, GET_INCORP_YEAR_ALL, GET_INCORP_YEAR_DETAIL_ALL } from './reports.graphql';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
  Typography,
  Paper,
  IconButton,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Link,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LoadingSpinner from '../components/LoadingSpinner';
import Grid from '@mui/material/Grid';
import XLSX from 'xlsx';
import { ArrowBack, Group, DoubleArrow, Business } from '@mui/icons-material';
import { SearchField } from '../components/SearchField';
import { Counter } from '../components/Counter';
import Fuse from 'fuse.js';
import { Code, EmployeeModel, CompanyModel, IncorporationYearCompanyModel, IncorporationYearModel } from '../interfaces';
import { ColWidths } from '../components/TableComponents';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import IncorporationIcon from '@mui/icons-material/DateRange';
import HomeWorkIcon from '@mui/icons-material/HomeWork';

const useStyles = makeStyles((theme) => ({
  excelIcon: {
    width: 24,
    height: 24,
  },
  right: {
    textAlign: 'right',
  },
  title: {
    textAlign: 'left',
    marginLeft: 64,
    marginTop: 15,
    marginRight: 64,
  },
  fitwidth: {
    width: 1,
    whiteSpace: 'nowrap' as 'nowrap',
    lineHeight: 1.0,
  },
  table: {
    position: 'relative' as 'relative',
  },
  clickableLink: {
    marginLeft: 10,
  },
  link: {
    textDecoration: 'none' as 'none',
    marginLeft: 10,
  },
  invisible: { display: 'none' },
  excelButton: {
    position: 'absolute' as 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(35),
  },
  excelButtonUp: {
    position: 'absolute' as 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(30),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

//#region submenu
export default function BrregSubMenu() {
  const classes = useStyles();
  let [isSubMenuVisible, setIsSubMenuVisible] = useState(true);
  let [isMenuCodeListVisible, setIsCodeListVisible] = useState(true);
  let [isMenuEmployeeListVisible, setIsEmployeeListVisible] = useState(true);
  const [filterOption, setFilterOption] = useState('');
  const handleLinkNæringsKodeClick = () => {
    setIsSubMenuVisible(false);
    setIsCodeListVisible(true);
    setIsEmployeeListVisible(true);
  };
  const handleLinkAntallAnsatteClick = () => {
    setIsSubMenuVisible(false);
    setIsCodeListVisible(false);
    setIsEmployeeListVisible(true);
  };
  const handleLinkIncorporationYearClick = () => {
    setIsSubMenuVisible(false);
    setIsCodeListVisible(false);
    setIsEmployeeListVisible(false);
  };
  const handleBackToMenuClick = () => {
    setIsSubMenuVisible(true);
  };
  const handleFilterOption = (option) => {
    setFilterOption(option);
  };
  const { loading, data } = useQuery(GET_BUSINESS_CODE, { variables: { filterOption: filterOption } });
  if (isSubMenuVisible) {
    return (
      <>
        <Paper elevation={1}>
          <Grid container direction="row" style={{ padding: 20 }}>
            <Grid item className={classes.right} style={{ marginLeft: 20 }}>
              <SettingsEthernetIcon htmlColor="#757575" />{' '}
            </Grid>
            <Grid item>
              <Typography gutterBottom variant="body2">
                <Link component="button" onClick={() => handleLinkNæringsKodeClick()} className={classes.link}>
                  Næringskode
                </Link>
              </Typography>
            </Grid>
            <Grid item style={{ marginLeft: 40 }}>
              <Group htmlColor="#757575" />
            </Grid>
            <Grid item>
              <Typography variant="body2" gutterBottom>
                <Link component="button" onClick={() => handleLinkAntallAnsatteClick()} className={classes.link}>
                  Antall ansatte
                </Link>
              </Typography>
            </Grid>
            <Grid item style={{ marginLeft: 60 }}>
              <IncorporationIcon htmlColor="#757575" />
            </Grid>
            <Grid item>
              <Typography variant="body2" gutterBottom>
                <Link component="button" onClick={() => handleLinkIncorporationYearClick()} className={classes.link}>
                  Stiftelses år
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  } else if (isMenuCodeListVisible) {
    return (
      <>
        {(loading || !data) && <LoadingSpinner />}
        {data && data.businesses && (
          <CodeTable
            codeCollection={data.businesses}
            handleBackToSubMenuClick={handleBackToMenuClick}
            filterOption={filterOption}
            handleFilter={handleFilterOption}
          />
        )}
      </>
    );
  } else if (isMenuEmployeeListVisible) {
    return <>{<EmployeeTable code={'ingen kode'} handleBack={handleBackToMenuClick} allFlag={true} />}</>;
  } else {
    return <>{<IncorporationYearTable code={'Ingen kode'} handleBack={handleBackToMenuClick} allFlag={true} />}</>;
  }
}

//#endregion
//#region codetable
function CodeTable(props) {
  let [searchTerm, setSearchTerm] = useState('');
  let [isCodeListVisible, setIsCodeListVisible] = useState(true);
  let [code, setCode] = useState('');
  let [description, setDescription] = useState('');
  const classes = useStyles();
  const { codeCollection, handleBackToSubMenuClick, handleFilter, filterOption } = props;
  const filterCodes = (Code: Code[]) => {
    var options = {
      shouldSort: false,
      tokenize: true,
      matchAllTokens: true,
      threshold: 0,

      location: 0,
      distance: 10,
      maxPatternLength: 32,
      minMatchCharLength: 3,
      keys: ['code', 'description'],
    };
    if (!searchTerm) {
      return codeCollection;
    } else {
      var fuse = new Fuse(codeCollection, options);
      var filtered = fuse.search(searchTerm);
      return filtered;
    }
  };
  const filteredCodes = filterCodes(codeCollection);
  const handleCodeClick = (code, description) => {
    setIsCodeListVisible(false);
    setCode(code);
    setDescription(description);
  };
  const handleBack = () => {
    setIsCodeListVisible(true);
  };
  const handleBackToMenuClick = () => {
    handleBackToSubMenuClick(true);
  };
  const handleDropDownChange = (event) => {
    handleFilter(event.target.value);
  };
  const renderSearchBar = () => {
    const arrHeading: string[] = ['Næringskode', 'Antall firma', 'Antall licens(kunde)', 'Antall bruker'];
    const data: Code[] = filteredCodes.map((s) => s);
    const dataSet = data.map((x) => [x.code + ' ' + x.description, x.companyCount, x.licenseCount, x.userCount]);
    var stringArrHeading = JSON.stringify(arrHeading);
    const workBook = 'BRREG_ANTALL';
    const fileName = 'brregantall.xlsx';
    var stringDataSet = JSON.stringify(dataSet);
    return (
      <>
        <Paper elevation={1}>
          <Grid container spacing={1} direction="row" alignItems="center" justifyContent="space-between">
            <Grid item>
              <Grid container spacing={1} direction="row" alignItems="center">
                <Grid item>
                  <IconButton onClick={() => handleBackToMenuClick()} title="Gå tilbake til undermeny" size="large">
                    <ArrowBack />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography gutterBottom variant="h5">
                    Rapport basert på: Næringskode
                  </Typography>
                </Grid>
                <Grid item className={classes.excelButton}>
                  {filteredCodes && (
                    <GeneralExcelExport dataSet={stringDataSet} headings={stringArrHeading} workBookName={workBook} fileName={fileName} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row">
            <Grid item xs={12}>
              <Grid item style={{ marginLeft: 15 }}>
                <SearchField filter={setSearchTerm} />
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginLeft: 8 }}>
              {renderDropDown()}
            </Grid>
            <Grid item xs={12} style={{ marginRight: 15 }}>
              <Counter list={filterCodes(codeCollection)} />
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  };
  const renderTable = () => {
    return (
      <>
        <Paper elevation={1}>
          <Table className={classes.table}>
            <ColWidths widths={[null, 300, 300, null]}></ColWidths>
            <TableHead>
              <TableRow>
                <TableCell>Næringskode</TableCell>
                <TableCell>Antall firma</TableCell>
                <TableCell>Antall lisens(kunde)</TableCell>
                <TableCell>Antall bruker</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCodes &&
                filteredCodes.map((c) => {
                  const key = c.code;
                  return (
                    <TableRow key={key}>
                      <TableCell>
                        <Grid container spacing={1} direction="row">
                          <Grid item>{c.code}</Grid>
                          <Grid item>{c.description}</Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid container spacing={0} direction="row">
                          <Grid item>
                            <HomeWorkIcon htmlColor="#757575" />
                          </Grid>
                          <Grid item>
                            <Link component="button" className={classes.clickableLink} onClick={() => handleCodeClick(c.code, c.description)}>
                              {c.companyCount}
                            </Link>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid container spacing={0} direction="row">
                          <Grid item>
                            <Business htmlColor="#757575" />
                          </Grid>
                          <Grid item className={classes.link}>
                            {c.licenseCount}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid container spacing={0} direction="row">
                          <Grid item>
                            <Group htmlColor="#757575" />
                          </Grid>
                          <Grid item className={classes.link}>
                            {c.userCount}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Paper>
      </>
    );
  };
  const renderDropDown = () => {
    return (
      <>
        <FormControl className={classes.formControl}>
          <InputLabel id="lblFilter">Filtrere på</InputLabel>
          <Select labelId="lblFilter" id="dropDownFilter" value={filterOption} onChange={handleDropDownChange}>
            <MenuItem value={'all'}>Ingen</MenuItem>
            <MenuItem value={'9'}>BFS</MenuItem>
            <MenuItem value={'16'}>BVN</MenuItem>
          </Select>
        </FormControl>
      </>
    );
  };
  if (isCodeListVisible) {
    return (
      <>
        {renderSearchBar()}
        {renderTable()}
      </>
    );
  } else {
    return <EmployeeTable code={code} handleBack={handleBack} description={description} filterOption={filterOption} allFlag={false} />;
  }
}
//#endregion
//#region employeetable
function EmployeeTable(props) {
  const { code, handleBack, description, allFlag, filterOption } = props;
  const classes = useStyles();
  let [isEmployeeListVisible, setIsEmployeeListVisible] = useState(true);
  let [filter, setFilter] = useState(filterOption);

  let [keyString, setKeyString] = useState('');
  const handleBackClick = () => {
    handleBack();
  };
  const handleBackToEmployeeTable = () => {
    setIsEmployeeListVisible(true);
  };
  const handleEmployeeClick = (keyString) => {
    setKeyString(keyString);
    setIsEmployeeListVisible(false);
  };
  const handleDropDownChange = (event) => {
    setFilter(event.target.value);
  };
  const { data, loading } = useQuery<{ employees: EmployeeModel[] }>(GET_EMPLOYEE, { variables: { code: code, filterOption: filter } });
  const renderBreadCrumb = () => {
    const arrHeading: string[] = ['Antall ansatte', 'Antall firma', 'Antall license(kunde)', 'Antall bruker'];
    const dataSet = data && data.employees && data.employees.map((x) => [x.key, x.companyCount, x.licenseCount, x.userCount]);
    var stringArrHeading = JSON.stringify(arrHeading);
    const workBook = 'BRREG_ANSATTE_ANTALL';
    const fileName = 'brregansatteantall.xlsx';
    var stringDataSet = JSON.stringify(dataSet);
    let title = allFlag ? 'Antall ansatte' : 'Næringskode';
    let backTitle = allFlag ? 'Gå tilbake til undermeny' : 'Gå tilbake til liste over næringskode';
    let filterBox = allFlag ? (
      <>
        <FormControl className={classes.formControl}>
          <InputLabel id="lblFilterLabel">Filtrere på</InputLabel>
          <Select labelId="lblFilterLabel" id="dropDownFilterId" value={filterOption} onChange={handleDropDownChange}>
            <MenuItem value={'all'}>Ingen</MenuItem>
            <MenuItem value={'9'}>BFS</MenuItem>
            <MenuItem value={'16'}>BVN</MenuItem>
          </Select>
        </FormControl>
      </>
    ) : (
      ' '
    );
    return (
      <>
        <Paper elevation={3}>
          <Grid container direction="row" spacing={1} alignItems="center">
            <Grid item xs={12} className={classes.title}>
              <Typography gutterBottom variant="h5">
                Rapport basert på: {title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography gutterBottom variant="subtitle1">
                <IconButton onClick={() => handleBackClick()} title={backTitle} size="large">
                  <ArrowBack />
                </IconButton>
              </Typography>{' '}
            </Grid>
            <Grid item>
              <Typography variant="body2" gutterBottom>
                Næringskode:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" gutterBottom>
                {code}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" gutterBottom>
                {description}
              </Typography>
            </Grid>

            <Grid item className={classes.excelButtonUp}>
              {data && <GeneralExcelExport dataSet={stringDataSet} headings={stringArrHeading} workBookName={workBook} fileName={fileName} />}
            </Grid>
            <Grid item xs={12} style={{ marginLeft: 8 }}>
              {filterBox}
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  };
  const renderTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={300}>Antall ansatte</TableCell>
              <TableCell width={300}>Antall firma</TableCell>
              <TableCell width={300}>Antall lisens(kunde)</TableCell>
              <TableCell width={300}>Antall bruker</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.employees.map((c) => {
                const key = c.key;
                return (
                  <TableRow key={key}>
                    <TableCell>{c.key}</TableCell>
                    <TableCell>
                      <Grid container spacing={0} direction="row">
                        <Grid item>
                          <HomeWorkIcon htmlColor="#757575" />
                        </Grid>
                        <Grid item className={classes.link}>
                          {c.companyCount}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container spacing={0} direction="row">
                        <Grid item>
                          <Business htmlColor="#757575" />
                        </Grid>
                        <Grid item className={classes.clickableLink}>
                          <Link component="button" onClick={() => handleEmployeeClick(c.key)}>
                            {c.licenseCount}
                          </Link>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container spacing={0} direction="row">
                        <Grid item className={classes.clickableLink}>
                          {c.userCount}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {loading && <LoadingSpinner />}
      </TableContainer>
    );
  };

  if (isEmployeeListVisible) {
    return (
      <>
        {renderBreadCrumb()}
        {renderTable()}
      </>
    );
  } else {
    return <CompanyTable code={code} keyString={keyString} filterOption={filterOption} handleBack={handleBackToEmployeeTable} withCode={true} />;
  }
}
//#endregion
//#region incorporationyeartable
function IncorporationYearTable(props) {
  const { code, handleBack, filterOption } = props;
  const classes = useStyles();
  let [isIncorporationYearListVisible, setIsIncorporationYearListVisible] = useState(true);
  let [keyString, setKeyString] = useState('');
  let [noCodeFilterOption, setNoCodeFilterOption] = useState('');
  const handleBackClick = () => {
    handleBack();
  };
  const handleBackIncorporationTable = () => {
    setIsIncorporationYearListVisible(true);
  };
  const handleCorporationClick = (keyString) => {
    setKeyString(keyString);
    setIsIncorporationYearListVisible(false);
  };
  const handleDropDownChange = (event) => {
    setNoCodeFilterOption(event.target.value);
  };
  const { data, loading } = useQuery<{ incorporationscomp: IncorporationYearModel[] }>(GET_INCORP_YEAR_ALL, {
    variables: { noCodeFilterOption: noCodeFilterOption },
  });
  const renderBreadCrumb = () => {
    const arrHeading: string[] = ['Stiftelsesår', 'Antall firma', 'Antall kunder (lisenser)'];
    const dataSet = data && data.incorporationscomp && data.incorporationscomp.map((x) => [x.key, x.companyCount, x.licenseCount]);
    var stringArrHeading = JSON.stringify(arrHeading);
    const workBook = 'BRREG_STIFTELSES_AAR_ANTALL';
    const fileName = 'brregstiftelsesaarantall.xlsx';
    var stringDataSet = JSON.stringify(dataSet);
    let title = 'Stiftelsesår';
    let filterBox = (
      <>
        <FormControl className={classes.formControl}>
          <InputLabel id="lblFilterLabel">Filtrere på</InputLabel>
          <Select labelId="lblFilterLabel" id="dropDownFilterId" value={filterOption} onChange={handleDropDownChange}>
            <MenuItem value={'all'}>Ingen</MenuItem>
            <MenuItem value={'9'}>BFS</MenuItem>
            <MenuItem value={'16'}>BVN</MenuItem>
          </Select>
        </FormControl>
      </>
    );
    return (
      <>
        <Paper elevation={3}>
          <Grid container direction="row" spacing={1} alignItems="center">
            <Grid item xs={12} className={classes.title}>
              <Typography gutterBottom variant="h5">
                Rapport basert på: {title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography gutterBottom variant="subtitle1">
                <IconButton onClick={() => handleBackClick()} title="Gå tilbake til undermeny" size="large">
                  <ArrowBack />
                </IconButton>
              </Typography>{' '}
            </Grid>

            <Grid item className={classes.excelButtonUp}>
              {data && <GeneralExcelExport dataSet={stringDataSet} headings={stringArrHeading} workBookName={workBook} fileName={fileName} />}
            </Grid>
            <Grid item xs={12} style={{ marginLeft: 8 }}>
              {filterBox}
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  };
  const renderTable = () => {
    return (
      <>
        <Paper elevation={1}>
          <Table>
            <ColWidths widths={[300, 300, null]}></ColWidths>
            <TableHead>
              <TableRow>
                <TableCell>Stiftelsesår</TableCell>
                <TableCell>Antall firma</TableCell>
                <TableCell>Antall lisens(kunde)</TableCell>

                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.incorporationscomp &&
                data.incorporationscomp.map((c) => {
                  const key = c.key;
                  return (
                    <TableRow key={key}>
                      <TableCell>{c.key}</TableCell>
                      <TableCell>
                        <Grid container spacing={0} direction="row">
                          <Grid item>
                            <HomeWorkIcon htmlColor="#757575" />
                          </Grid>
                          <Grid item className={classes.link}>
                            {c.companyCount}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid container spacing={0} direction="row">
                          <Grid item>
                            <Business htmlColor="#757575" />
                          </Grid>
                          <Grid item className={classes.clickableLink}>
                            <Link component="button" onClick={() => handleCorporationClick(c.key)}>
                              {c.licenseCount}
                            </Link>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {loading && <LoadingSpinner />}
        </Paper>
      </>
    );
  };

  if (isIncorporationYearListVisible) {
    return (
      <>
        {renderBreadCrumb()}
        {renderTable()}
      </>
    );
  } else {
    return (
      <IncorporationComopanyTable code={code} keyString={keyString} filterOption={noCodeFilterOption} handleBack={handleBackIncorporationTable} />
    );
  }
}

//#endregion

//#region incorpotationyear
function IncorporationComopanyTable(props) {
  const { keyString, handleBack, description, filterOption } = props;
  const classes = useStyles();
  const handleBackClick = () => {
    handleBack();
  };
  const { loading, data } = useQuery<{ inccompanies: IncorporationYearCompanyModel[] }>(GET_INCORP_YEAR_DETAIL_ALL, {
    variables: { incorporationYearRange: keyString, noCodeFilterOption: filterOption },
  });
  const renderBreadCrumb = () => {
    const arrHeading: string[] = ['Næringskode', 'Firma', 'Org. nummer', 'Kundenummer (Maconomy)', 'Stiftet'];
    const dataSet =
      data &&
      data.inccompanies &&
      data.inccompanies.map((x) => [x.code + ' ' + x.codeDescription, x.companyName, x.orgNumber, x.maconomyCustomerNumber, x.incorporationDate]);
    var stringArrHeading = JSON.stringify(arrHeading);
    const workBook = 'BRREG_FIRMA_INFO';
    const fileName = 'brregfirmainfo.xlsx';
    var stringDataSet = JSON.stringify(dataSet);
    let title = 'Stiftelsesår';
    return (
      <>
        <Paper elevation={3}>
          <Grid container direction="row" spacing={1} alignItems="center">
            <Grid item xs={12} className={classes.title}>
              <Typography gutterBottom variant="h5">
                Rapport basert på: {title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography gutterBottom variant="subtitle1">
                <IconButton onClick={() => handleBackClick()} title="Gå tilbake til overikt over stiftelsesår" size="large">
                  <ArrowBack />
                </IconButton>
              </Typography>{' '}
            </Grid>
            <Grid item>
              <Typography variant="body2" gutterBottom>
                {description}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" gutterBottom>
                Stiftelsesår : {keyString}
              </Typography>
            </Grid>
            <Grid item className={classes.excelButtonUp}>
              {data && <GeneralExcelExport dataSet={stringDataSet} headings={stringArrHeading} workBookName={workBook} fileName={fileName} />}
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  };
  const renderTable = () => {
    return (
      <>
        <Paper elevation={1}>
          <Table>
            <ColWidths widths={[null, null, null, null]}></ColWidths>
            <TableHead>
              <TableRow>
                <TableCell>Næringskode</TableCell>
                <TableCell>Firma</TableCell>
                <TableCell>Org. nummer</TableCell>
                <TableCell>Kundenummer (Maconomy)</TableCell>
                <TableCell>Stiftelsesdato</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.inccompanies.map((c) => {
                  const key = c.orgNumber;
                  return (
                    <TableRow key={key}>
                      <TableCell>
                        <Grid container spacing={1} direction="row">
                          <Grid item>{c.code}</Grid>
                          <Grid item>{c.codeDescription}</Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>{c.companyName}</TableCell>
                      <TableCell>{c.orgNumber}</TableCell>
                      <TableCell>{c.maconomyCustomerNumber}</TableCell>
                      <TableCell>{c.incorporationDate}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {loading && <LoadingSpinner />}
        </Paper>
      </>
    );
  };
  return (
    <>
      {renderBreadCrumb()}
      {renderTable()}
    </>
  );
}

//#endregion

//#region companytable
function CompanyTable(props) {
  const { keyString, code, handleBack, description, withCode, filterOption } = props;
  const classes = useStyles();
  const handleBackClick = () => {
    handleBack();
  };
  const { loading, data } = useQuery<{ companies: CompanyModel[] }>(GET_COMPANY, {
    variables: { employeeRange: keyString, code: code, filterOption: filterOption },
  });
  const renderBreadCrumb = () => {
    const arrHeading: string[] = ['Næringskode', 'Firma', 'Org. nummer', 'Kundenummer (Maconomy)', 'Antall bruker', 'Opprettet'];
    const dataSet =
      data &&
      data.companies &&
      data.companies.map((x) => [x.code + ' ' + x.codeDescription, x.companyName, x.orgNumber, x.maconomyCustomerNumber, x.userCount, x.createDate]);
    var stringArrHeading = JSON.stringify(arrHeading);
    const workBook = 'BRREG_FIRMA_INFO';
    const fileName = 'brregfirmainfo.xlsx';
    var stringDataSet = JSON.stringify(dataSet);
    let title = withCode ? 'Antall ansatte' : 'Næringskode';
    return (
      <>
        <Paper elevation={3}>
          <Grid container direction="row" spacing={1} alignItems="center">
            <Grid item xs={12} className={classes.title}>
              <Typography gutterBottom variant="h5">
                Rapport basert på: {title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography gutterBottom variant="subtitle1">
                <IconButton onClick={() => handleBackClick()} title="Gå tilbake til oversikt over antall ansatte" size="large">
                  <ArrowBack />
                </IconButton>
              </Typography>{' '}
            </Grid>
            <Grid item>
              <Typography variant="body2" gutterBottom>
                Næringskode:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" gutterBottom>
                {code}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" gutterBottom>
                {description}
              </Typography>
            </Grid>
            <Grid item>
              <DoubleArrow htmlColor="#757575" />
            </Grid>
            <Grid item>
              <Typography variant="body2" gutterBottom>
                Antall ansatte : {keyString}
              </Typography>
            </Grid>
            <Grid item className={classes.excelButtonUp}>
              {data && <GeneralExcelExport dataSet={stringDataSet} headings={stringArrHeading} workBookName={workBook} fileName={fileName} />}
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  };
  const renderTable = (withCode) => {
    return (
      <>
        <Paper elevation={1}>
          <Table>
            <ColWidths widths={[null, null, null, null]}></ColWidths>
            <TableHead>
              <TableRow>
                <TableCell className={!withCode ? classes.invisible : ''}>Næringskode</TableCell>
                <TableCell>Firma</TableCell>
                <TableCell>Org. nummer</TableCell>
                <TableCell>Kundenummer (Maconomy)</TableCell>
                <TableCell>Antall bruker</TableCell>
                <TableCell>Opprettet</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.companies.map((c, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className={!withCode ? classes.invisible : ''}>
                        <Grid container spacing={1} direction="row">
                          <Grid item>{c.code}</Grid>
                          <Grid item>{c.codeDescription}</Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>{c.companyName}</TableCell>
                      <TableCell>{c.orgNumber}</TableCell>
                      <TableCell>{c.maconomyCustomerNumber}</TableCell>
                      <TableCell>{c.userCount}</TableCell>
                      <TableCell>{c.createDate}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {loading && <LoadingSpinner />}
        </Paper>
      </>
    );
  };
  return (
    <>
      {renderBreadCrumb()}
      {renderTable(withCode)}
    </>
  );
}
//#endregion

export interface GeneralExcelExportProps {
  dataSet: string;
  headings: string;
  workBookName: string;
  fileName: string;
}
export function GeneralExcelExport(props: GeneralExcelExportProps) {
  const { dataSet, headings, workBookName, fileName } = props;
  const classes = useStyles();
  const exportFile = () => {
    const workbook = XLSX.utils.book_new();
    const inputHeading = JSON.parse(headings);
    const finalData = JSON.parse(dataSet);
    const input = [inputHeading, ...finalData];
    const sheet = XLSX.utils.aoa_to_sheet(input);
    XLSX.utils.book_append_sheet(workbook, sheet, workBookName);
    XLSX.writeFile(workbook, fileName);
  };
  return (
    <div className={classes.right}>
      <IconButton onClick={exportFile} title="Eksport til Excel" size="large">
        <img src="/microsoft-excel.svg" className={classes.excelIcon} alt="Excel icon" />
      </IconButton>
    </div>
  );
}
