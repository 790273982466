import React from 'react';
import { Divider, Grid, Box, Avatar } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
}));

interface BannerProps {
  icon?: any;
  action?: any;
  children: any;
}

export function Banner({ icon, action, children }: BannerProps) {
  const classes = useStyles();

  return (
    <>
      <Box py={2} px={2}>
        <Grid container alignItems="center">
          {icon && (
            <Grid item xs={1}>
              <Avatar className={classes.avatar}>{icon}</Avatar>
            </Grid>
          )}
          <Grid item xs>
            {children}
          </Grid>
          {action && (
            <Grid item xs={2}>
              {action}
            </Grid>
          )}
        </Grid>
      </Box>
      <Divider className={classes.divider} />
    </>
  );
}
