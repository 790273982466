import React from 'react';
import { CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = (theme: any) => ({
  progress: {
    margin: `0 ${theme.spacing(2)}`,
  },

  center: {
    width: 100,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
  },
});

let Loading = (props: any) => {
  const { classes, size } = props;
  return (
    <div className={classes.center}>
      <CircularProgress className={classes.progress} size={size || 100} />
    </div>
  );
};

export default withStyles(styles)(Loading);
