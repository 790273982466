import { TextField } from '@mui/material';
import { useState } from 'react';
import { oidcOnAppSettingsFile } from './DomainUtils';
import { AdfsSetting } from '../../interfaces';
interface Prop {
  callbackValue: (name: string) => void;
  callbackError: (error: boolean) => void;
  result: AdfsSetting[] | undefined;
  existingName: string | undefined;
}
export default function ProviderName(prop: Prop) {
  const { callbackValue: sendbackName, result, callbackError: sendbackError, existingName } = prop;
  const [invalidProviderNameErrorText, setInvalidProviderNameErrorText] = useState('');
  const [duplicateErrorText, setDuplicateErrorText] = useState('');
  const [providerName, setProviderName] = useState(existingName);
  const specialCharacters = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
  const handleProviderNameBlur = (name: string) => {
    if (!isProviderNameValid(name)) {
      setInvalidError();
    } else {
      if (existingName) {
        checkDuplicateProviderNameWhileUpdate(name) ? setDuplicateError() : sendbackName(name);
      } else {
        checkDuplicateProvider(name) ? setDuplicateError() : sendbackName(name);
      }
    }
  };
  const setInvalidError = () => {
    setInvalidProviderNameErrorText('Special characters or duplicate values are not allowed in the provider name');
    sendbackError(true);
  };
  const setDuplicateError = () => {
    setDuplicateErrorText('duplicate value');
    sendbackError(true);
  };
  const clearDuplicateError = () => {
    setDuplicateErrorText('');
    sendbackError(false);
  };
  const clearInvalidError = () => {
    setInvalidProviderNameErrorText('');
    sendbackError(false);
  };
  const handleProviderNameChange = (name: string) => {
    clearDuplicateError();
    clearInvalidError();
    setProviderName(name);
  };
  const isProviderNameValid = (name: string) => {
    let result = name.match(specialCharacters);
    return result || isProviderNameExistOnAppSettingsFile(name) ? false : true;
  };
  const isProviderNameExistOnAppSettingsFile = (name: string) => {
    let result = oidcOnAppSettingsFile.includes(name.toLowerCase());
    return result;
  };
  const removeSpecialCharacter = (providerName: string) => {
    let result = providerName && providerName.replace(/\s/g, '');
    result = result.replace(specialCharacters, '');
    return result;
  };
  const washArray = (array: AdfsSetting[] | undefined) => {
    return array && array.map((x) => removeSpecialCharacter(x.scheme));
  };
  const checkDuplicateProvider = (name: string) => {
    let providerName = name && removeSpecialCharacter(name);
    let cleanArray = washArray(result);
    let isDuplicate =
      cleanArray &&
      cleanArray.find((obj) => {
        return obj.toLowerCase() === providerName.toLowerCase();
      });
    return isDuplicate ? true : false;
  };
  const checkDuplicateProviderNameWhileUpdate = (name: string) => {
    let inputValue = removeSpecialCharacter(name);
    const cleanExistingName = existingName && removeSpecialCharacter(existingName);
    if (inputValue !== cleanExistingName) {
      return checkDuplicateProvider(name) ? true : false;
    } else {
      return false;
    }
  };
  return (
    <>
      <TextField
        label="Provider Name"
        error={duplicateErrorText.length === 0 && invalidProviderNameErrorText.length === 0 ? false : true}
        helperText={invalidProviderNameErrorText.length === 0 ? duplicateErrorText : invalidProviderNameErrorText}
        fullWidth
        value={providerName}
        type="text"
        onBlur={(event) => handleProviderNameBlur(event.target.value)}
        onChange={(event) => handleProviderNameChange(event.target.value)}
        margin="normal"
        required
      />
    </>
  );
}
