import React, { useState, useCallback } from 'react';
import Moment from 'moment';
import MainContentPanel from '../components/MainContentPanel';
import { Typography, IconButton, Table, TableHead, TableRow, TableCell, TableFooter, Grid, TableBody } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import LoadingSpinner from '../components/LoadingSpinner';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroller';
import { GET_RENEWALS } from './renewals.graphql';
import { numberAsKr, numberAsPercentage } from '../Formatters';
import { debounce } from 'throttle-debounce';
import moment from 'moment';
import { SearchField } from '../components/SearchField';
import Fuse from 'fuse.js';
import { Counter } from '../components/Counter';
import { ColWidths } from '../components/TableComponents';
import { DiffNotification } from './DiffNotificationDialog';
import { DiffMaconomy } from './DiffMaconomyDialog';
import { DiffMaconomyApi } from './DiffMaconomyApiDialog';
import { RenewalExcelExport } from './RenewalExcelExport';

const useStyles = makeStyles((theme) => ({
  overviewTable: {
    marginTop: 10,
  },
  syncButton: {
    padding: 0,
    marginRight: 10,
  },
  syncing: {
    marginRight: 10,
  },
  error: {
    //color: theme.palette.error.main
  },
}));

export interface RenewalInfo {
  id: string;
  licenseId: number;
  subscriptionOrderNumber: string;
  name: string;
  bfs?: number;
  bfsp?: number;
  bfsb?: number;
  bfsf?: number;
  bfsu?: number;
  bvn?: number;
  total: number;
}

export interface ProductDefinition {
  name: string;
  indent: number;
  selector(row: RenewalInfo): number | undefined;
}

export function RenewalOverview() {
  const now = Moment(Date.now());
  const [dateString, setDateString] = useState(now.add(1, 'months').format());
  const date = Moment(dateString);
  const month = date.month() + 1;
  const year = date.year();

  const { loading, error, data } = useQuery<{ renewals: RenewalInfo[]; renewalsNewPrices: RenewalInfo[] }>(GET_RENEWALS, {
    variables: { month, year },
  });

  return (
    <MainContentPanel>
      <RenewalNavigation startDate={dateString} notifyChange={setDateString} />
      {loading && <LoadingSpinner />}
      {error && <span>{error.message}</span>}
      {data && <RenewalTable renewals={data.renewals} notificationsNewPrices={data.renewalsNewPrices} date={date} />}
    </MainContentPanel>
  );
}

function RenewalNavigation({ startDate, notifyChange }: { startDate: string; notifyChange(date: string): void }) {
  const now = Moment(Date.now()).add(1, 'months');

  const [dateString, setDateString] = useState(startDate);
  const date = Moment(dateString);
  const delayedChangeMonth = useCallback((s) => debounce(1000, notifyChange(s)), [notifyChange]);

  const handleChange = (s: string) => {
    setDateString(s);
    delayedChangeMonth(s);
  };

  const next = () => handleChange(date.add(1, 'months').format());
  const prev = () => handleChange(date.subtract(1, 'months').format());

  const canGoBack = date > moment('2020-05-01');
  const canGoForward = date.year() === now.year() || date.month() < now.month() - 1;

  return (
    <>
      <Typography gutterBottom variant="h5">
        {canGoBack && (
          <IconButton title="Forrige måned" onClick={prev} size="large">
            <ArrowBack />
          </IconButton>
        )}
        <span>Fornyelser i {date.format('MMMM YYYY')}</span>
        {canGoForward && (
          <IconButton title="Neste måned" onClick={next} size="large">
            <ArrowForward />
          </IconButton>
        )}
      </Typography>
    </>
  );
}

function RenewalTable({
  renewals,
  notificationsNewPrices,
  date,
}: {
  renewals: RenewalInfo[];
  notificationsNewPrices: RenewalInfo[];
  date: Moment.Moment;
}) {
  const classes = useStyles();
  const [numberOfItems, setNumberOfItems] = useState(50);
  const [searchTerm, setSearchTerm] = useState('');

  const products: { name: string; indent: number; selector(item: RenewalInfo): number | undefined }[] = [
    { name: 'Komplett', indent: 10, selector: (x) => x.bfs },
    { name: 'Planlegging', indent: 20, selector: (x) => x.bfsp },
    { name: 'Byggdetaljer', indent: 20, selector: (x) => x.bfsb },
    { name: 'Byggforvaltning', indent: 20, selector: (x) => x.bfsf },
    { name: 'Utførelse', indent: 20, selector: (x) => x.bfsu },
    { name: 'BVN', indent: 0, selector: (x) => x.bvn },
  ];

  const filteredRenewals = filterRenewals(renewals, searchTerm);

  return (
    <>
      <OverviewSummary renewals={renewals} notificationsNewPrices={notificationsNewPrices} products={products} date={date} />
      <SearchField filter={setSearchTerm} />
      <Counter list={filteredRenewals} />
      <Table className={classes.overviewTable}>
        <TableHead>
          <TableRow>
            <TableCell>Kunde</TableCell>
            <TableCell>Abonnement</TableCell>
            {products.map((p) => (
              <TableCell key={`HEADER-${p.name}`} align="right">
                {p.name}
              </TableCell>
            ))}
            <TableCell align="right">Sum</TableCell>
          </TableRow>
        </TableHead>
        <InfiniteScroll element="tbody" loadMore={() => setNumberOfItems(numberOfItems + 500)} hasMore={renewals.length > numberOfItems}>
          {filteredRenewals.slice(0, numberOfItems).map((r) => (
            <RenewalRow key={r.id} r={r} products={products} />
          ))}
        </InfiniteScroll>
      </Table>
    </>
  );
}

function filterRenewals(renewals: RenewalInfo[], searchTerm: string) {
  var options = {
    shouldSort: false,
    tokenize: true,
    matchAllTokens: true,
    threshold: 0,
    location: 0,
    distance: 10,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ['name', 'subscriptionOrderNumber'],
  };
  if (!searchTerm) {
    return renewals;
  } else {
    var fuse = new Fuse(renewals, options);
    var filtered = fuse.search(searchTerm);
    return filtered;
  }
}

function RenewalRow({ r, products }: { r: RenewalInfo; products: { name: string; selector(item: RenewalInfo): number | undefined }[] }) {
  return (
    <TableRow key={r.licenseId}>
      <TableCell>
        <Link to={'/license/' + r.licenseId}>{r.name}</Link>
      </TableCell>
      <TableCell>{r.subscriptionOrderNumber}</TableCell>
      {products.map((p) => (
        <TableCell key={`${r.id}${p.name}`} align="right">
          {numberAsKr(p.selector(r))}
        </TableCell>
      ))}
      <TableCell align="right">{numberAsKr(r.total)}</TableCell>
    </TableRow>
  );
}

const useOverviewStyles = makeStyles((theme) => ({
  cell: {
    borderBottom: 'none',
    paddingTop: 3,
    paddingBottom: 3,
  },
  summary: {
    borderTop: 1,
    borderTopStyle: 'solid',
    borderTopColor: '#e0e0e0',
    fontWeight: 'bold',
  },
  positive: {
    color: theme.palette.primary.main,
  },
  negative: {
    color: theme.palette.error.main,
  },
}));

interface OverviewSummaryInterface {
  renewals: RenewalInfo[];
  notificationsNewPrices: RenewalInfo[];
  products: ProductDefinition[];
  date: Moment.Moment;
}

function OverviewSummary({ renewals, notificationsNewPrices, products, date }: OverviewSummaryInterface) {
  const classes = useOverviewStyles();

  const sumNullable = (...values: (number | undefined)[]): number | undefined => {
    const onlyNumbers = values.filter((x) => x !== undefined) as number[];
    return onlyNumbers.reduce((sum, current) => sum + current, 0);
  };
  const sumProduct = (array: RenewalInfo[], sel: any) => array.reduce((sum, renewal) => sum + (sel(renewal) ? sel(renewal) : 0), 0);
  const productsWithBfs = [{ name: 'BFS', indent: 0, selector: (x) => sumNullable(x.bfs, x.bfsp, x.bfsb, x.bfsf, x.bfsu) }, ...products];

  const totalAtRenewal = renewals.reduce((sum, product) => sum + product.total, 0);

  const totalAtNotificationNewPrices = notificationsNewPrices.reduce((sum, product) => sum + product.total, 0);
  return (
    <Grid container spacing={8}>
      <Grid item>
        <Table size="small">
          <ColWidths widths={[165, 115, null, 165, 115, 115]} />
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>Ved fornyelse</TableCell>
              <TableCell className={classes.cell} align="right">
                <DiffMaconomyApi renewals={renewals} date={date} />
              </TableCell>
              <TableCell className={classes.cell}></TableCell>
              <TableCell className={classes.cell}></TableCell>
              <TableCell className={classes.cell}>Varsel med nye priser</TableCell>
              <TableCell className={classes.cell} align="right">
                <DiffNotification renewals={renewals} notifications={notificationsNewPrices} products={products} date={date} />{' '}
                <RenewalExcelExport renewals={renewals} notificationsNewPrices={notificationsNewPrices} products={products} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.cell}>Antall kunder</TableCell>
              <TableCell className={classes.cell} align="right">
                {renewals.length}
              </TableCell>
              <TableCell className={classes.cell} />

              <TableCell className={classes.cell}>Antall kunder</TableCell>
              <TableCell className={classes.cell} align="right">
                {notificationsNewPrices.length}
              </TableCell>
              <TableCell className={classes.cell} align="right">
                <ColorNumber value={renewals.length - notificationsNewPrices.length} isCurrency={false} />
              </TableCell>

              <TableCell className={classes.cell} align="right">
                <ColorPecentage valueRenewal={renewals.length} valueNotification={notificationsNewPrices.length} />
              </TableCell>
            </TableRow>
            {productsWithBfs.map((p) => (
              <TableRow key={p.name}>
                <TableCell className={classes.cell} style={{ paddingLeft: p.indent + 16 }}>
                  {p.name}
                </TableCell>
                <TableCell className={classes.cell} align="right">
                  {numberAsKr(sumProduct(renewals, p.selector))}
                </TableCell>
                <TableCell className={classes.cell} />

                <TableCell className={classes.cell} style={{ paddingLeft: p.indent + 16 }}>
                  {p.name}
                </TableCell>
                <TableCell className={classes.cell} align="right">
                  {numberAsKr(sumProduct(notificationsNewPrices, p.selector))}
                </TableCell>
                <TableCell className={classes.cell} align="right">
                  <ColorNumber value={sumProduct(renewals, p.selector) - sumProduct(notificationsNewPrices, p.selector)} />
                </TableCell>

                <TableCell className={classes.cell} align="right">
                  <ColorPecentage
                    valueRenewal={sumProduct(renewals, p.selector)}
                    valueNotification={sumProduct(notificationsNewPrices, p.selector)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell className={`${classes.cell} ${classes.summary}`}>Totalt</TableCell>
              <TableCell className={`${classes.cell} ${classes.summary}`} align="right">
                {numberAsKr(totalAtRenewal)}
              </TableCell>
              <TableCell className={classes.cell} />

              <TableCell className={`${classes.cell} ${classes.summary}`}>Totalt</TableCell>

              <TableCell className={`${classes.cell} ${classes.summary}`} align="right">
                {numberAsKr(totalAtNotificationNewPrices)}
              </TableCell>
              <TableCell className={`${classes.cell} ${classes.summary}`} align="right">
                <ColorNumber value={totalAtRenewal - totalAtNotificationNewPrices} />
              </TableCell>

              <TableCell className={`${classes.cell} ${classes.summary}`} align="right">
                <ColorPecentage valueRenewal={totalAtRenewal} valueNotification={totalAtNotificationNewPrices} />
              </TableCell>
            </TableRow>
            ,
          </TableFooter>
        </Table>
      </Grid>
      <Grid item>
        <DiffMaconomy renewals={renewals} date={date} />
      </Grid>
    </Grid>
  );
}

function ColorNumber({ value, isCurrency = true }: { value: number; isCurrency?: boolean }) {
  const classes = useOverviewStyles();

  const display = isCurrency ? numberAsKr(value) : value !== 0 ? value : null;
  if (value < 0) return <span className={classes.negative}>{display}</span>;

  return <span className={classes.positive}>{display}</span>;
}

function ColorPecentage({ valueRenewal, valueNotification }: { valueRenewal: number; valueNotification: number }) {
  const classes = useOverviewStyles();

  const value = ((valueRenewal - valueNotification) * 100) / valueNotification;

  const display = numberAsPercentage(value);
  if (value < 0) return <span className={classes.negative}>{display}</span>;

  return <span className={classes.positive}>{display}</span>;
}
