import React from 'react';
import { UserTable } from './UserTable';
import { SearchableUserTable } from './SearchableUserTable';
import { LicenseSubscription } from '../../interfaces';

interface Props {
  subscription: LicenseSubscription;
}

export function UsersInSubscription({
  subscription: {
    subscription: { id },
    numberOfUsers,
    userAccess,
    licenseId,
  },
}: Props) {
  if (userAccess.length < 15) {
    return <UserTable users={userAccess} subscriptionId={id} licenseId={licenseId} />;
  }

  return <SearchableUserTable users={userAccess} subscriptionId={id} licenseId={licenseId} />;
}
