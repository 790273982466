import React, { useState } from 'react';
import {
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Divider,
  List,
  ListItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close } from '@mui/icons-material';
import { ADD_USERS_TO_SUBSCRIPTIONS, GET_SUBSCRIPTIONS } from './UsersInLicense.graphql';
import { License, LicenseModel, LicenseSubscription } from '../../interfaces';
import { validateEmail } from '../../components/SetEmailDialog';
import { DataProxy, useMutation, useQuery } from '@apollo/client';

import { GET_LICENSE } from './LicenseInfo.graphql';

const useAddUsersStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',

    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  subscriptionHeading: {
    marginTop: 5,
  },
}));

export function AddUsers({ subscriptions }: { subscriptions: LicenseSubscription[] }) {
  const arrayForSort = [...subscriptions];
  const subscriptionsOverall = arrayForSort.sort((x) => x.subscription.id);

  const licenseId = subscriptionsOverall[0]?.licenseId;

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [checkedSubscriptions, setCheckedSubscriptions] = useState([0]);

  const [addCustomer] = useMutation(ADD_USERS_TO_SUBSCRIPTIONS, {
    update: (cache: DataProxy, { data: { subscriptions } }) => {
      const data = cache.readQuery<{ subscriptions: LicenseSubscription[] }, { id: number }>({
        query: GET_SUBSCRIPTIONS,
        variables: { id: licenseId },
      });
      if (data === null) return;
      cache.writeQuery({
        query: GET_SUBSCRIPTIONS,
        variables: { id: licenseId },
        data: { ...data, subscriptions: subscriptions },
      });
    },
  });

  const classes = useAddUsersStyles();

  const { loading, data } = useQuery<{ license: { bksLicense: License; license: LicenseModel } }>(GET_LICENSE, {
    variables: { id: licenseId },
    onCompleted: (data) => {
      const { bksLicense } = data!.license;
      const canAddUsersWithoutSeats: boolean = bksLicense.providerId != null;
      const subscriptionsWithSeats = !canAddUsersWithoutSeats
        ? subscriptions.filter((x) => x.numberOfUsers > x.userAccess.length).sort((x) => x.subscription.id)
        : subscriptionsOverall;
      setCheckedSubscriptions(subscriptionsWithSeats.map((x) => x.subscription.id));
    },
  });

  if (loading || !data) return null;

  const { bksLicense } = data!.license;
  const canAddUsersWithoutSeats: boolean = bksLicense.providerId != null;
  //TODO: Simplyfy
  const subscriptionsWithSeats = !canAddUsersWithoutSeats
    ? subscriptions.filter((x) => x.numberOfUsers > x.userAccess.length).sort((x) => x.subscription.id)
    : subscriptionsOverall;
  const hasAvailableSeats = subscriptionsWithSeats.length > 0;

  const numberOfNewUsers = value.split(',').length - (!value ? 1 : 0);
  const enoughFreeSeats = !subscriptionsWithSeats
    .filter((x) => checkedSubscriptions.includes(x.subscription.id))
    .some((x) => x.numberOfUsers < x.userAccess.length + numberOfNewUsers);
  const anyCheckedSubscriptions = checkedSubscriptions.length > 0;

  const onChange = (v) => setValue(v);
  const onClose = () => setOpen(false);
  const onCommit = () => {
    addCustomer({ variables: { input: { id: licenseId, subscriptionIds: checkedSubscriptions, emails: value.split(',') } } });
    onClose();
  };

  const handleEmailChange = () => setError(validateEmail(value));
  const isSubscriptionChecked = (id) => checkedSubscriptions.some((x) => x === id);
  const onSubscriptionCheckedChange = (id) => {
    if (isSubscriptionChecked(id)) {
      setCheckedSubscriptions(checkedSubscriptions.filter((x) => x !== id));
    } else {
      setCheckedSubscriptions(checkedSubscriptions.concat([id]));
    }
  };

  return (
    <>
      <IconButton disabled={!hasAvailableSeats && !canAddUsersWithoutSeats} onClick={() => setOpen(true)} title="Legg til brukere" size="large">
        <Add />
      </IconButton>
      <Dialog onClose={onClose} aria-labelledby="dialog-title" open={open}>
        <DialogTitle id="dialog-title">
          Legg til brukere
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} size="large">
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <form>
            <TextField
              label="E-post(er)"
              fullWidth
              value={value}
              type="email"
              onChange={(ev) => onChange(ev.target.value)}
              onBlur={handleEmailChange}
              margin="normal"
              multiline
              error={error}
            />
            <Divider />
            <Typography variant="overline" className={classes.subscriptionHeading}>
              Skal legges til i:
            </Typography>
            <List dense>
              {subscriptionsWithSeats.map((s) => {
                const sub = s.subscription;
                const isChecked = isSubscriptionChecked(sub.id);
                const remaining = s.numberOfUsers - s.userAccess.length - (isChecked ? numberOfNewUsers : 0);
                return (
                  <ListItem key={sub.id}>
                    <Checkbox checked={isChecked} onChange={() => onSubscriptionCheckedChange(s.subscription.id)} />
                    <ListItemText>
                      {s.subscription.name}{' '}
                      <Typography color={remaining >= 0 ? undefined : 'error'} display="inline">
                        ({remaining} stk igjen)
                      </Typography>
                    </ListItemText>
                  </ListItem>
                );
              })}
            </List>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Avbryt
          </Button>
          <Button
            onClick={onCommit}
            disabled={!value || error || (!enoughFreeSeats && !canAddUsersWithoutSeats) || !anyCheckedSubscriptions}
            color="primary"
            variant="contained"
          >
            Legg til brukere
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
