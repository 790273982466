import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  DialogActions,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { formatDate } from '../../Formatters';
import InfiniteScroll from 'react-infinite-scroller';
import { Task } from '../../interfaces';
import { Delete, Check, Edit, Visibility, FilterList } from '@mui/icons-material';
import ConfirmDialog from '../../components/ConfirmDialog';
import EditDialog from './EditDialog';
import DetailsDialog from './DetailsDialog';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Counter } from '../../components/Counter';
import { compareFn, TableCellSort } from '../../components/TableComponents';
import { SortDirection } from '@mui/material/TableCell';

const useStyles = makeStyles(() => ({
  tableCell: {
    padding: '4px 8px',
    whiteSpace: 'nowrap' as 'nowrap',
  },
}));

interface Props {
  tasks: Task[];
  deleteTask: (task: Task) => void;
  updateTask: (any) => void;
  licenseId?: number;
  descriptionWidth?: number;
}

export default function TaskTable({ tasks, licenseId, descriptionWidth, updateTask, deleteTask }: Props) {
  const classes = useStyles();
  const [numberOfItems, setNumberOfItems] = useState(50);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState<Task | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState<SortDirection>(false);
  const [orderBy, setOrderBy] = useState<string>('Opprettet');
  const [typeFilter, setTypeFilter] = useState<string[]>([]);

  const loadMore = (): void => setNumberOfItems(numberOfItems + 50);
  const hasMore = (): boolean => tasks.length > numberOfItems;

  const handleDeleteClicked = (task: Task): void => {
    setCurrentTask(task);
    setConfirmDialogOpen(true);
  };

  const closeConfirmDialog = (): void => {
    setCurrentTask(undefined);
    setConfirmDialogOpen(false);
  };

  const doDeleteTask = (): void => {
    if (currentTask === undefined) return;
    deleteTask(currentTask);
    closeConfirmDialog();
  };

  const handleEditClicked = (task: Task): void => {
    setCurrentTask(task);
    setEditDialogOpen(true);
  };

  const closeEditDialog = (): void => {
    setCurrentTask(undefined);
    setEditDialogOpen(false);
  };

  const handleDetailsClicked = (task: Task): void => {
    setCurrentTask(task);
    setDetailsDialogOpen(true);
  };

  const closeDetailsDialog = (): void => {
    setCurrentTask(undefined);
    setDetailsDialogOpen(false);
  };

  const doUpdateTask = (isComplete: boolean, comment?: string): void => {
    if (currentTask === undefined) return;
    updateTask({ variables: { input: { id: currentTask.id, isComplete, comment } } });
    closeEditDialog();
  };

  const changeSort = (header: string) => {
    if (orderBy === header) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      return;
    }
    setOrderBy(header);
    setSortOrder('asc');
  };

  const stringSort = (a: string, b: string) => {
    if (!a && b) {
      return -1;
    }
    if (a && !b) {
      return 1;
    }
    if (!a && !b) {
      return 0;
    }

    return b.localeCompare(a);
  };

  const getSorter = () => {
    const fn = (getter: (x: Task) => any) => compareFn<Task>(getter, sortOrder);

    if (orderBy === 'Kunde') return fn((x) => x.licenseName);
    if (orderBy === 'Type') return fn((x) => x.typeName);
    if (orderBy === 'Beskrivelse') return fn((x) => x.description);
    if (orderBy === 'Opprettet') return fn((x) => (x.createdAt ? moment(x.createdAt) : null));
    if (orderBy === 'Sist endret av') return fn((x) => x.lastUpdatedByName);
    if (orderBy === 'Fullført') return fn((x) => (x.completedAt ? moment(x.completedAt) : null));

    return () => 0;
  };

  if (tasks.length === 0) {
    return <Typography>Ingen oppgaver</Typography>;
  }

  const TableHeader = ({ children, header }: { children: any; header?: string }) => {
    const label = header ? header : children;
    return (
      <TableCellSort active={orderBy === label} onClick={() => changeSort(label)} sortDirection={sortOrder}>
        {header}
        {children}
      </TableCellSort>
    );
  };

  const filteredTasks = tasks.filter((x) => !typeFilter.some((f) => f === x.typeName));
  return (
    <>
      <Counter list={filteredTasks} />
      <Table padding="none">
        <TableHead>
          <TableRow>
            {!licenseId && <TableHeader>Kunde</TableHeader>}
            <TableHeader header="Type">
              <ColumnFilter
                values={Array.from(new Set(tasks.map((x) => x.typeName).sort(stringSort)))}
                selectedValues={typeFilter}
                setSelectedValues={setTypeFilter}
              />
            </TableHeader>
            <TableHeader>Beskrivelse</TableHeader>
            <TableHeader>Opprettet</TableHeader>
            <TableHeader>Sist endret av</TableHeader>
            <TableHeader>Fullført</TableHeader>
            <TableCell className={classes.tableCell}></TableCell>
          </TableRow>
        </TableHead>
        <InfiniteScroll element="tbody" loadMore={() => loadMore()} hasMore={hasMore()}>
          {filteredTasks
            .sort(getSorter())
            .slice(0, numberOfItems)
            .map((t) => (
              <TableRow key={t.id}>
                {!licenseId && (
                  <TableCell className={classes.tableCell}>
                    <LicenseName id={t.bksLicenseId} companyName={t.licenseName} />
                  </TableCell>
                )}
                <TableCell className={classes.tableCell}>{t.typeName}</TableCell>
                <TableCell className={classes.tableCell} style={{ maxWidth: descriptionWidth, whiteSpace: 'pre-wrap' }}>
                  {t.description}
                </TableCell>
                <TableCell className={classes.tableCell}>{formatDate(t.createdAt)}</TableCell>
                <TableCell className={classes.tableCell}>{t.lastUpdatedByName || t.createdByName}</TableCell>
                <TableCell className={classes.tableCell} title={t.comment}>
                  {t.completedAt ? <Check style={{ color: 'green' }} /> : null}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {!t.completedAt && (
                    <IconButton title="Redigér oppgave" onClick={() => handleEditClicked(t)} size="large">
                      <Edit />
                    </IconButton>
                  )}
                  {t.completedAt && (
                    <IconButton title="Vis detaljer" onClick={() => handleDetailsClicked(t)} size="large">
                      <Visibility />
                    </IconButton>
                  )}
                  {t.isCreatedManually && (
                    <IconButton title="Slett" onClick={() => handleDeleteClicked(t)} size="large">
                      <Delete />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </InfiniteScroll>
      </Table>
      <ConfirmDialog
        open={confirmDialogOpen}
        title="Slette oppgave"
        message="Vil du slette oppgaven?"
        confirmText="Slett"
        denyText="Avbryt"
        confirm={() => doDeleteTask()}
        deny={() => closeConfirmDialog()}
        DialogProps={{ maxWidth: 'xs', fullWidth: true }}
      />
      {currentTask && !currentTask.completedAt && (
        <EditDialog
          open={editDialogOpen}
          task={currentTask}
          onCancel={() => closeEditDialog()}
          onSubmit={(isComplete, comment) => doUpdateTask(isComplete, comment)}
        />
      )}
      {currentTask && currentTask.completedAt && <DetailsDialog open={detailsDialogOpen} task={currentTask} onClose={() => closeDetailsDialog()} />}
    </>
  );
}

function LicenseName({ id, companyName }: { id: number; companyName: string }) {
  return <Link to={'/license/' + id}>{companyName ? companyName : '<Mangler navn>'}</Link>;
}

function ColumnFilter({
  values,
  selectedValues,
  setSelectedValues,
}: {
  values: string[];
  selectedValues: string[];
  setSelectedValues(values: string[]): void;
}) {
  const [open, setOpen] = useState(false);
  const [workingTypeFilters, setWorkingTypeFilters] = useState<string[]>(selectedValues);

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setOpen(true);
    e.stopPropagation();
  };
  const handleClose = () => {
    setOpen(false);
    setWorkingTypeFilters(selectedValues);
  };
  const handleOk = () => {
    setSelectedValues(workingTypeFilters);
    handleClose();
  };

  const handleFilterTypeChange = (type: string) => {
    if (workingTypeFilters.some((x) => x === type)) {
      setWorkingTypeFilters(workingTypeFilters.filter((x) => x !== type));
    } else {
      setWorkingTypeFilters([type, ...workingTypeFilters]);
    }
  };

  const handleAllChange = () => {
    if (workingTypeFilters.length === 0) {
      setWorkingTypeFilters(values);
    } else {
      setWorkingTypeFilters([]);
    }
  };

  const filterActive = selectedValues.length > 0;

  return (
    <>
      <IconButton onClick={handleOpen} title={filterActive ? 'Filtrer liste' : 'Listen er filtrert'} size="large">
        <FilterList color={filterActive ? 'error' : 'inherit'} />
      </IconButton>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown onClick={(e) => e.stopPropagation()}>
        <DialogTitle>Endre filter</DialogTitle>
        <DialogContent>
          <form>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={workingTypeFilters.length === 0} onChange={handleAllChange} value="Alle" />}
                  label="Alle"
                />
                <hr />
                {values.map((t) => (
                  <FormControlLabel
                    key={t}
                    control={<Checkbox checked={!workingTypeFilters.some((x) => x === t)} onChange={() => handleFilterTypeChange(t)} value={t} />}
                    label={t}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Avbryt</Button>
          <Button onClick={handleOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
