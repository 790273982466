import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@apollo/client';
import { DECREASE_ACCESS, GET_SUBSCRIPTIONS } from './UsersInLicense.graphql';
import { Remove } from '@mui/icons-material';
import { LicenseSubscription } from '../../interfaces';

interface DecreaseAccessButtonInterface {
  access: LicenseSubscription;
}

const useStyles = makeStyles((theme) => ({
  immediate: {
    background: theme.palette.error.main,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

export function DecreaseAccessButton({ access }: DecreaseAccessButtonInterface) {
  const { licenseId, numberOfUsers, subscription } = access;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [decrease, setDecrease] = useState(0);
  const [decreaseAccess] = useMutation(DECREASE_ACCESS);

  const abort = () => {
    setOpen(false);
    setDecrease(0);
  };

  const submit = (effectuateNow?: boolean) => {
    decreaseAccess({
      variables: {
        input: { id: licenseId, subscriptionId: subscription.id, removeNumberOfAccesses: decrease, effectuateNow: effectuateNow === true },
      },
      update(cache) {
        if (!effectuateNow) return;

        const queryVariable = { id: licenseId };
        const result = cache.readQuery({ query: GET_SUBSCRIPTIONS, variables: queryVariable }) as any;
        let subs = result.subscriptions.slice();
        const index = subs.findIndex((x) => (x) => x.subscription.id === subscription.id);
        subs.splice(index, 1, { ...access, numberOfUsers: numberOfUsers - decrease });

        cache.writeQuery({
          query: GET_SUBSCRIPTIONS,
          variables: queryVariable,
          data: {
            subscriptions: subs,
            paidSubscriptions: result.paidSubscriptions,
            license: result.license,
          },
        });
      },
    });
    setOpen(false);
    setDecrease(0);
  };

  const catchReturn = (ev) => {
    if (ev.key === 'Enter') {
      submit();
      ev.preventDefault();
    }
  };

  return (
    <>
      <IconButton onClick={() => setOpen(true)} title="Fjern tilganger" size="large">
        <Remove />
      </IconButton>
      <Dialog open={open} onClose={abort}>
        <DialogTitle>{`Fjern tilganger til ${subscription.name}`}</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              autoFocus
              type="number"
              label="Antall tilganger som skal bort"
              fullWidth
              value={decrease}
              InputProps={{ inputProps: { min: 1, max: numberOfUsers } }}
              onChange={(ev) => setDecrease(Number(ev.target.value))}
              margin="normal"
              onKeyPress={catchReturn}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={abort}>Avbryt</Button>
          <Button disabled={decrease <= 0} variant="contained" color="primary" onClick={() => submit()}>
            Fjern
          </Button>
          <Button
            disabled={decrease <= 0}
            variant="contained"
            className={classes.immediate}
            color="primary"
            onClick={() => submit(true)}
            title="Dette vil fjerne tilgangene med umiddelbar virkning"
          >
            Fjern nå
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
