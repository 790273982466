import React, { useState, ReactNode } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import DatePicker from '@mui/lab/DatePicker';
import ViewsPerDoc from './ViewsPerDoc';
import ViewsPerUser from './ViewsPerUser';
import DownloadsPerUser from './DownloadsPerUser';
import { makeStyles } from '@mui/styles';
import { SelectChangeEvent, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import DateFnsAdapter from '@mui/lab/AdapterDateFns';

interface CompanyDetailsProps {
  onBackClick: () => void;
  orgNumber: string;
  companyName: string;
}
const useStyles = makeStyles({
  container: {
    padding: '16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    marginBottom: '16px',
    marginLeft: '16px',
    marginRight: '16px',
    display: 'flex',
  },
  select: {
    marginRight: '16px',
    width: '200px',
  },
  datePicker: {
    marginRight: '16px',
    width: '200px',
  },
  excelIcon: {
    fontSize: '32px',
    color: '#2E7D32',
    marginLeft: 'auto',
  },
  topContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
  },
});

export default function CompanyDetail(props: CompanyDetailsProps) {
  const { onBackClick, orgNumber, companyName } = props;
  const classes = useStyles();

  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  const [fromDate, setFromDate] = useState<Date | null>(oneYearAgo);
  const fromDateStr = fromDate?.toDateString();

  const [toDate, setToDate] = useState<Date | null>(new Date());
  const toDateStr = toDate?.toDateString();

  const [reportType, setReportType] = useState('perDocument');
  const [productType, setProductType] = useState('alle');

  const handleReportTypeChange = (event: SelectChangeEvent<string>, child: ReactNode) => {
    setReportType(event.target.value as string);
  };

  const handleProductTypeChange = (event: SelectChangeEvent<string>, child: ReactNode) => {
    setProductType(event.target.value as string);
  };

  const handleFromDateChange = (date: Date | null) => {
    setFromDate(date);
  };

  const handleToDateChange = (date: Date | null) => {
    setToDate(date);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={DateFnsAdapter}>
        <Box className={classes.topContainer}>
          <IconButton onClick={onBackClick}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5">{companyName}</Typography>
        </Box>
        <Box className={classes.container}>
          <FormControl sx={{ mr: 2, minWidth: '300px' }}>
            <InputLabel id="report-type-label">Velg rapport type</InputLabel>
            <Select labelId="report-type-label" id="report-type" value={reportType} onChange={handleReportTypeChange}>
              <MenuItem value="perDocument">Sidevisning per dokument</MenuItem>
              <MenuItem value="perUser">Sidevisning per bruker</MenuItem>
              <MenuItem value="perUserDownload">Nedlasting per bruker</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ mr: 2, minWidth: '300px' }}>
            <InputLabel id="report-type-label">Velg produkt type</InputLabel>
            <Select labelId="report-type-label" id="report-type" value={productType} onChange={handleProductTypeChange}>
              <MenuItem value="alle">Alle</MenuItem>
              <MenuItem value="bfs">Byggforskserien</MenuItem>
              <MenuItem value="bfsp">Planlegging</MenuItem>
              <MenuItem value="bfsb">Byggdetaljer</MenuItem>
              <MenuItem value="bfsf">Byggforvaltning</MenuItem>
              <MenuItem value="bvn">Våtromsnormen</MenuItem>
            </Select>
          </FormControl>

          <Box sx={{ marginRight: '5px' }}>
            <DatePicker label="Fra dato" value={fromDate} onChange={handleFromDateChange} renderInput={(params) => <TextField {...params} />} />
          </Box>
          <DatePicker
            className={classes.datePicker}
            label="Til dato"
            value={toDate}
            onChange={handleToDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        {reportType === 'perDocument' ? (
          <ViewsPerDoc orgNumber={orgNumber} fromDate={fromDateStr} toDate={toDateStr} productType={productType} />
        ) : reportType === 'perUserDownload' ? (
          <DownloadsPerUser orgNumber={orgNumber} fromDate={fromDateStr} toDate={toDateStr} productType={productType} />
        ) : (
          <ViewsPerUser orgNumber={orgNumber} fromDate={fromDateStr} toDate={toDateStr} productType={productType} />
        )}
      </LocalizationProvider>
    </>
  );
}
