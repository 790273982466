import React, { useState, useEffect } from 'react';
import getConfiguration, { Config } from '../config';

export function withConfiguration(): <P extends object>(WrappedComponent: React.ComponentType<P>) => React.ComponentType<P> {
  return <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    class ComponentWithConfiguration extends React.Component<P, any> {
      state = {
        config: {},
      };

      async componentDidMount() {
        let config = await getConfiguration();
        this.setState({ config: config });
      }

      render() {
        var propsWithConfig = {
          ...this.props,
          config: this.state.config,
        };
        return <WrappedComponent {...propsWithConfig} />;
      }
    };
}

export function useConfig(): Config | undefined {
  const [config, setConfig] = useState<Config | undefined>(undefined);
  async function getConf() {
    setConfig(await getConfiguration());
  }
  useEffect(() => {
    getConf();
  }, []);

  return config;
}
