import React from 'react';
import Button from '@mui/material/Button';

import { login, getToken } from './OidcClient';
import Loading from '../components/LoadingSpinner';

class AuthProvider extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { loading: true };
  }

  async componentDidMount() {
    await login();
    this.setState({ loading: false });
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    } else {
      if (getToken()) {
        return React.Children.only(this.props.children);
      } else {
        return (
          <Button onClick={login} variant="outlined">
            Log in
          </Button>
        );
      }
    }
  }
}

export default AuthProvider;
