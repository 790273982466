import React from 'react';
import { Typography, Button } from '@mui/material';
import { Banner } from '../../components/Banner';
import { Block } from '@mui/icons-material';
import { UserBouced } from '../../interfaces';
import { GET_BOUNCED, REMOVE_BOUNCE } from '../user.graphql';
import { useConfig } from '../../components/Configuration';
import { useMutation, useQuery } from '@apollo/client';

export function BouncedBanner({ userId }: { userId: number }) {
  const config = useConfig();
  const { loading, data } = useQuery<{ bounced: UserBouced }>(GET_BOUNCED, { variables: { id: userId } });
  const [removeBounce] = useMutation(REMOVE_BOUNCE, { variables: { input: { id: userId } } });

  if (loading || !data) return null;
  const bounced = data.bounced;
  if (!bounced.isBounced) return null;

  const action = (
    <Button disabled={!config || !config.featureToggleRemoveBounce} onClick={() => removeBounce()}>
      Fjern blokkering
    </Button>
  );
  return (
    <Banner icon={<Block fontSize="large" />} action={action}>
      <div>
        <Typography>E-post addressen er blokkert</Typography>
        <Typography variant="body2" color="textSecondary">
          {bounced.reason}
        </Typography>
      </div>
    </Banner>
  );
}
