import React, { useState } from 'react';
import { Button, CircularProgress, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Edit, Delete } from '@mui/icons-material';
import { useMutation, useQuery } from '@apollo/client';
import LoadingSpinner from '../components/LoadingSpinner';
import MainContentPanel from '../components/MainContentPanel';
import { formatDate, numberAsKr } from '../Formatters';
import { CANCEL_PRICE_UPDATE, GET_CHANGES, GET_SUBSCRIPTIONS } from './subscriptions.graphql';
import { Alert } from '@mui/material';
import { ChangeLog } from '../components/ChangeLog';
import { useConfig } from '../components/Configuration';
import { Change, SubscriptionModel, SubscriptionPrice } from '../interfaces';
import { UpdatePriceDialog } from './UpdatePriceDialog';
import { PdfPriceCard } from '../pdf/overview';

export function Overview() {
  return (
    <>
      <SubscriptionsCard />
      <PdfPriceCard />
      <ChangeLogCard />
    </>
  );
}

function SubscriptionsCard() {
  const { loading, data } = useQuery<{ subscriptions: SubscriptionModel[] }>(GET_SUBSCRIPTIONS);

  return (
    <MainContentPanel>
      <Typography variant="h5">Abonnement</Typography>
      {loading && <LoadingSpinner />}
      {data && <SubscriptionTable subscriptions={data.subscriptions} />}
    </MainContentPanel>
  );
}

function SubscriptionTable({ subscriptions }: { subscriptions: SubscriptionModel[] }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell colSpan={3} align="center">
            Priser
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Navn</TableCell>
          <TableCell align="right">1 bruker</TableCell>
          <TableCell align="right">Ekstra brukere</TableCell>
          <TableCell style={{ width: 355 }} />
        </TableRow>
      </TableHead>
      <TableBody>
        {subscriptions.map((x) => (
          <TableRow key={x.id}>
            <SubscriptionItem subscription={x} />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function SubscriptionItem({ subscription }: { subscription: SubscriptionModel }) {
  return (
    <>
      <TableCell>{subscription.name}</TableCell>
      <TableCell align="right">
        <Price subscription={subscription} priceGetter={(p) => p.singleUser} newPriceGetter={(p) => p.singleUser} />
      </TableCell>
      <TableCell align="right">
        <Price subscription={subscription} priceGetter={(p) => p.extraUser} newPriceGetter={(p) => p.extraUser} />
      </TableCell>
      <TableCell>
        <SubscriptionAction subscription={subscription} />
      </TableCell>
    </>
  );
}

const usePriceStyles = makeStyles((theme) => ({
  deleted: {
    textDecoration: 'line-through',
  },
}));

function Price({
  subscription,
  priceGetter,
  newPriceGetter,
}: {
  subscription: SubscriptionModel;
  priceGetter(p: SubscriptionPrice): number;
  newPriceGetter(p: SubscriptionPrice): number;
}) {
  const classes = usePriceStyles();
  const price = subscription.price;
  if (!price) return null;
  if (!subscription.pendingPriceChange) return <span>{numberAsKr(priceGetter(price))}</span>;
  return (
    <>
      <Typography className={classes.deleted} color="error" variant="body1" display="inline">
        {numberAsKr(priceGetter(price))}
      </Typography>
      <Typography color="primary" variant="body1" display="inline">
        {numberAsKr(newPriceGetter(subscription.pendingPriceChange.newPrice))}
      </Typography>
    </>
  );
}

export const useActionStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    width: 250,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  timePanel: {
    background: 'rgb(232, 244, 253)',
    borderRadius: 4,
    padding: 5,
    marginBottom: 25,
  },
  timePicker: {
    marginLeft: theme.spacing(2),
  },
  alert: {
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  progress: {
    verticalAlign: `middle`,
  },
}));

function SubscriptionAction({ subscription }: { subscription: SubscriptionModel }) {
  const classes = useActionStyles();
  const [open, setOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const [cancelUpdate, { loading }] = useMutation(CANCEL_PRICE_UPDATE, { variables: { input: { subscriptionId: subscription.bksId } } });
  if (!subscription.price) return null;

  if (subscription.pendingPriceChange) {
    return (
      <>
        <IconButton disabled={loading} title="Forkast endring" onClick={() => cancelUpdate()} size="large">
          <Delete />
        </IconButton>
        <Alert className={classes.alert} severity="info">
          Prisen vil bli oppdatert {formatDate(subscription.pendingPriceChange.timeOfChange)}
        </Alert>
      </>
    );
  } else {
    return (
      <>
        <IconButton disabled={busy} title="Oppdater prisen" onClick={() => setOpen(true)} size="large">
          <Edit />
        </IconButton>
        <UpdatePriceDialog subscription={subscription} open={open} onClose={() => setOpen(false)} setBusy={setBusy} />
        {busy && <CircularProgress className={classes.progress} size={30} />}
      </>
    );
  }
}

function ChangeLogCard() {
  const config = useConfig();
  const [limit, setLimit] = useState(5);
  const pollIntervall = config ? config.defaultPollingInterval : 0;

  const { loading, data, refetch } = useQuery<{ queryResult: { totalCount: number; changes: Change[] } }>(GET_CHANGES, {
    variables: { limit },
    pollInterval: pollIntervall,
  });
  const moreToLoad = data && data.queryResult.changes && data.queryResult.changes.length < data.queryResult.totalCount;

  const doFetchMore = () => {
    if (!data) return;
    setLimit(limit + 5);
    refetch();
  };

  return (
    <MainContentPanel>
      <Typography variant="h5">Endringer</Typography>
      {(!data || loading) && <LoadingSpinner />}
      {data && <ChangeLog changes={data.queryResult.changes} />}
      {moreToLoad && (
        <Button disabled={loading} onClick={doFetchMore}>
          Last flere
        </Button>
      )}
    </MainContentPanel>
  );
}
