import React from 'react';
import Moment from 'moment';
import makeStyles from '@mui/styles/makeStyles';
import WidgetPanel from './WidgetPanel';
import { Link } from 'react-router-dom';
import { WidgetTitle } from './WidgetTitle';

import gql from 'graphql-tag';
import LoadingSpinner from '../components/LoadingSpinner';
import { Summary, RenewalSummary } from '../renewals/Summary';
import { useQuery } from '@apollo/client';
import { useState } from 'react';

var query = gql`
  query ($month: Int!, $year: Int!) {
    summary: getRenewalSummary(month: $month, year: $year) {
      quantity
      products {
        id
        name
        total
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  warningIcon: {
    color: '#ff9800',
    fontSize: 35,
    marginLeft: 10,
  },
  label: {
    display: 'inline-block',
  },
  value: {
    display: 'inline-block',
    float: 'right',
  },
  total: {
    fontWeight: 'bold',
  },
  panel: {},
  divider: {
    marginTop: 5,
  },
}));

export function RenewalWidget() {
  const classes = useStyles();

  const [monthOffset, setMonthOffset] = useState(1); // get statistics for next month as default

  const date = Moment(Date.now()).add(monthOffset, 'months');
  const year = date.year();

  const { data } = useQuery<{ summary: RenewalSummary }>(query, { variables: { month: date.month() + 1, year }, fetchPolicy: 'network-only' });

  const LinkToDetails = (props) => (
    <Link to="/renewal" title={props.title}>
      {props.children}
    </Link>
  );

  return (
    <div className={classes.panel}>
      <WidgetPanel>
        <WidgetTitle
          title="Fornyelser i"
          offset={monthOffset}
          displayYear={true}
          forwardLimit={3}
          yearNav={true}
          stateSetter={(step) => {
            setMonthOffset(monthOffset + step);
          }}
        />

        {!data && <LoadingSpinner />}
        {data && <Summary summary={data.summary} />}

        <LinkToDetails>Se mer</LinkToDetails>
      </WidgetPanel>
    </div>
  );
}
