import React, { useState } from 'react';
import { Table, TableCell, TableHead, TableRow, IconButton, Grid, Stack } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';
import { formatDateOnly } from '../../Formatters';
import { Link } from 'react-router-dom';
import { Delete } from '@mui/icons-material';
import { RemoveUserAccessDialog } from './RemoveUserAccessDialog';
import { useQuery } from '@apollo/client';
import { GET_USER_DATA_FOR_SUBSCRIPTION } from './UsersInLicense.graphql';
import { UserAccess, User } from '../../interfaces';
import { Counter } from '../../components/Counter';
import { ExcelExportLicenseUsers } from './ExcelExportLicenseUsers';

export interface Props {
  users: UserAccess[];
  licenseId: number;
  subscriptionId: number;
}

export function UserTable({ users, licenseId, subscriptionId }: Props) {
  const [numberOfItems, setNumberOfItems] = useState(50);
  const [currentUser, setCurrentUser] = useState<UserAccess | null>(null);
  const { loading, error, data } = useQuery<{ users: { id: string; userId: number; guideViewsCount: number }[] }>(GET_USER_DATA_FOR_SUBSCRIPTION, {
    fetchPolicy: 'no-cache',
    variables: { licenseId, subscriptionId },
  });

  const getGuideViews = (user: User) => {
    if (error) return 'Feilet';
    if (loading || !data) return 'Henter..';

    const userData = data.users.find((x) => x.userId === user.id);
    if (!userData) return 0;

    return userData.guideViewsCount.toLocaleString('nb-NO');
  };
  return (
    <Grid container justifyContent="flex-end">
      {currentUser && (
        <RemoveUserAccessDialog
          userAccessId={currentUser.id}
          userId={currentUser.user.id}
          displayName={currentUser.user.displayName}
          licenseId={licenseId}
          onClose={() => setCurrentUser(null)}
        />
      )}

      <Stack alignItems="center" direction="row">
        {' '}
        <ExcelExportLicenseUsers users={users ?? []} licenseId={licenseId} subscriptionId={subscriptionId} />
        <Counter list={users} />
      </Stack>
      <Grid item xs={12}>
        <Table padding="none">
          <TableHead>
            <TableRow>
              <TableCell>Navn</TableCell>
              <TableCell>E-post</TableCell>
              <TableCell>Sist innlogget</TableCell>
              <TableCell title="Antall oppslag de siste 12 måneder">Sidevisninger</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <InfiniteScroll element="tbody" loadMore={() => setNumberOfItems(numberOfItems + 50)} hasMore={users.length > numberOfItems}>
            {users.slice(0, numberOfItems).map((customerAccess) => {
              return (
                <TableRow key={customerAccess.id}>
                  <TableCell>
                    <Link to={'/user/' + customerAccess.user.id}>{customerAccess.user.displayName}</Link>
                  </TableCell>
                  <TableCell>{customerAccess.user.email}</TableCell>
                  <TableCell>{formatDateOnly(customerAccess.user.lastLoggedIn)}</TableCell>
                  <TableCell>{getGuideViews(customerAccess.user)}</TableCell>
                  <TableCell>
                    <IconButton title="Fjern brukertilgang" onClick={() => setCurrentUser(customerAccess)} size="large">
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </InfiniteScroll>
        </Table>
      </Grid>
    </Grid>
  );
}
