import React from 'react';
import { Divider, List, Hidden, Drawer, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Link, withRouter } from 'react-router-dom';
import { RouteDef } from './Shell';

interface SidebarProps {
  routes: RouteDef[];
  mobileOpen: boolean;
  handleDrawerToggle(): void;
}

interface InjectedProps {
  theme: any;
  classes: any;
}

interface CombinedProps extends SidebarProps, InjectedProps {}

const drawerWidth = 240;

const styles = (theme: any) => ({
  drawerHeader: theme.mixins.toolbar,
  drawerPaper: {
    width: 250,
    height: '100vh',
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      position: 'relative',
    },
  },
  menuLink: {
    color: 'black',
    textDecoration: 'none',
  },
  logo: {
    width: 60,
    height: 60,
    marginLeft: 20,
  },
});

let listItemLink = (props: any) => {
  let {
    classes,
    history,
    route: { path, title, icon, badge },
  } = props;
  let click = () => {
    history.push(path);
  };
  let renderLink = () => {
    return (
      <Link className={classes.menuLink} to={path}>
        {title}
      </Link>
    );
  };

  const Icon = icon;
  const Badge = badge;
  let currentPath = history.location.pathname;
  return (
    <MenuItem onClick={click} selected={path === '/' ? currentPath === path : currentPath.startsWith(path)}>
      <ListItemIcon>
        {badge && icon ? (
          <Badge>
            <Icon />
          </Badge>
        ) : (
          <Icon />
        )}
      </ListItemIcon>
      <ListItemText primary={renderLink()} />
    </MenuItem>
  );
};

let ListItemLink = withStyles(styles as any)(withRouter(listItemLink));

@((withStyles as any)(styles, { withTheme: true }))
export default class Sidebar extends React.Component<SidebarProps, any> {
  get combined(): CombinedProps {
    return this.props as SidebarProps & InjectedProps;
  }

  render() {
    let { classes, theme, routes, handleDrawerToggle, mobileOpen } = this.combined;
    const drawer = (
      <div>
        <div className={classes.drawerHeader} style={{ backgroundColor: '#FAFAFA' }}>
          <Link to={'/'}>
            <img src="/longhouse.svg" className={classes.logo} alt=""></img>
          </Link>
        </div>
        <Divider />
        <List>
          {routes
            .filter((x) => x.title)
            .map((x, index) => (
              <ListItemLink key={index} route={x} />
            ))}
        </List>
      </div>
    );
    return (
      <>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </>
    );
  }
}
