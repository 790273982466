import XLSX from 'xlsx';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton } from '@mui/material';
import { ProductDefinition, RenewalInfo } from './Overview';


const useStyles = makeStyles(theme => ({
    excelButton: {
    },
    excelIcon: {
        width: 24,
        height: 24
    }
}));

export function RenewalExcelExport({ renewals, notificationsNewPrices, products }: { renewals: RenewalInfo[], notificationsNewPrices: RenewalInfo[], products: ProductDefinition[] }) {
    const classes = useStyles();
    return <IconButton
        className={classes.excelButton}
        onClick={() => exportFile(renewals, products, notificationsNewPrices)}
        title="Eksport til Excel"
        disabled={!renewals}
    >
        <img src="/microsoft-excel.svg" className={classes.excelIcon} alt="Excel icon" />
    </IconButton>;
}

//const getNumber = (numberString: string|undefined) =>numberString ? parseInt(numberString.replace(/\u00a0/g, ''), 10) : '';

function exportFile(renewals: RenewalInfo[], products: ProductDefinition[], notificationsNewPrices: RenewalInfo[]) {
    const headings: string[] = ['Kunde', 'Abonnement'];

    const diffs = notificationsNewPrices.filter(x => {
        const renewal = renewals.find(r => r.licenseId === x.licenseId);
        return renewal === undefined || x.total !== renewal.total;
    });

    const subheadings: string[] = ['', ''];

    products.forEach(c => {
        headings.push(c.name);
        headings.push('');
        subheadings.push('Varsling');
        subheadings.push('Fornyelse');
    });

    

    // We need to convert to numbers, to enable sorting etc in Excel. 
    // To parse, remove group separator, which is a non-breaking space (Unicode U+00A0) in nb-NO.


    const data: (string | number | null)[][] = diffs.map(notificationsNewPrice => {

        var line: (string | number | null)[] = [];

        const renewal = renewals.find(x => x.licenseId === notificationsNewPrice.licenseId);
        const isRemoved = renewal === undefined;

        line.push(notificationsNewPrice.name);
        line.push(notificationsNewPrice.subscriptionOrderNumber);
        products.forEach(p => { 
            var priceAtNotification = p.selector(notificationsNewPrice);
            var priceAtRenewal=isRemoved ? undefined : p.selector(renewal!);
            if (priceAtNotification !== undefined)
                line.push(priceAtNotification);
            else
                line.push(0);
            if (priceAtRenewal !== undefined) 
                line.push(priceAtRenewal);
            else
                line.push(0);
        });

        return line;
    });

    data.unshift(subheadings);
    data.unshift(headings);

    const sheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, "Fornyelser");
    XLSX.writeFile(workbook, "fornyelser.xlsx");
}

