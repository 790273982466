import React from 'react';
import { ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, IconButton } from '@mui/material';
import { Phone, Edit } from '@mui/icons-material';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { UpdateValueDialog } from './UpdateValueDialog';

interface PhoneProps {
  phone: string;
  update?(phone: string): void;
}

interface PhoneState {
  open: boolean;
}

export class ListItemPhoneNumber extends React.PureComponent<PhoneProps, PhoneState> {
  state = {
    open: false,
  };

  edit(edit: boolean) {
    this.setState({ open: edit });
  }

  render() {
    let { phone, update } = this.props;
    let { open } = this.state;
    let title = 'Endre telefonnummer';
    let phoneValue = phone ? phone : '';

    let phoneNumber = phone ? parsePhoneNumberFromString(phone, 'NO') : undefined;
    let phoneNumberAsString = phoneNumber ? phoneNumber.formatNational() : phone;
    return (
      <ListItem>
        <ListItemIcon title="Telefonnummer">
          <Phone />
        </ListItemIcon>
        <ListItemText>{phoneNumberAsString}</ListItemText>
        {update && (
          <>
            <ListItemSecondaryAction>
              <IconButton title={title} onClick={() => this.edit(true)} size="large">
                <Edit />
              </IconButton>
            </ListItemSecondaryAction>
            <UpdateValueDialog title={title} label="Telefonnummer" value={phoneValue} open={open} onClose={() => this.edit(false)} update={update} />
          </>
        )}
      </ListItem>
    );
  }
}
