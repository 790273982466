import React from 'react';
import { Email } from '@mui/icons-material';
import { ListItemWithEdit } from './ListItemWithEdit';

interface Props {
  email: string;
  update?(email: string): void;
}

export function ListItemEmail({ email, update }: Props) {
  let icon = <Email />;
  return <ListItemWithEdit icon={icon} title="Endre e-post" label="E-post" value={email} update={update} />;
}
