import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';

interface DialogProps {
  open: boolean;
  onClose(): void;
  update(value: string);
  value: string;
  title?: string;
  label?: string;
  type?: string;
  multiline?: boolean;
  inputProps?: any;
}

export function UpdateValueDialog({ open, onClose, update, value, title, label, type, multiline, inputProps }: DialogProps) {
  const [newValue, setNewValue] = useState(value);

  const abort = () => {
    setNewValue(value);
    onClose();
  };
  const commit = () => {
    update(newValue);
    onClose();
  };
  const catchReturn = (ev) => {
    if (ev.key === 'Enter' && !multiline) {
      commit();
      ev.preventDefault();
    }
  };

  const definedType = type ? type : 'text';
  return (
    <Dialog open={open} onClose={abort}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form>
          <TextField
            multiline={multiline}
            autoFocus
            type={definedType}
            label={label}
            fullWidth
            value={newValue ? newValue : ''}
            onChange={(ev) => setNewValue(ev.target.value)}
            margin="normal"
            InputProps={inputProps}
            onKeyPress={catchReturn}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={abort}>Avbryt</Button>
        <Button disabled={value === newValue} variant="contained" color="primary" onClick={commit}>
          Endre
        </Button>
      </DialogActions>
    </Dialog>
  );
}
