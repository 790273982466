const minCharAfterDecimalOnDomain = 2;
const maxDecimalOnDomain = 4;
export default function IsDomainValid(newEmailDomain: string) {
  let isValid = true;
  let arrNewDomain = getDomainAsArrayFromString(newEmailDomain);
  if (arrNewDomain) {
    if (!AreDomainFormatsCorrect(arrNewDomain)) {
      isValid = false;
    }
  } else {
    if (CountCharAfterDecimal(newEmailDomain) < minCharAfterDecimalOnDomain || FindSpecialChar(newEmailDomain)) {
      isValid = false;
    }
  }
  return isValid;
}
const AreDomainFormatsCorrect = (domains) => {
  let result = true;
  domains.forEach((domain) => {
    if (CountCharAfterDecimal(domain) < minCharAfterDecimalOnDomain || FindSpecialChar(domain)) {
      result = false;
    }
  });
  return result;
};
const CountCharAfterDecimal = (domainName) => {
  let count = 0;
  if (domainName.indexOf('.') !== -1) {
    let arr = domainName.toString().split('.');
    if (CountDecimal(domainName) <= maxDecimalOnDomain) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].length < 1) {
          return count;
        }
      }
      count = arr[arr.length - 1].length;
    }
  }
  return count;
};
const CountDecimal = (domainName) => {
  let count = 0;
  let result = domainName.toString().split('.');
  count = result.length - 1;
  return count > maxDecimalOnDomain ? maxDecimalOnDomain + 1 : count;
};
const FindSpecialChar = (domainName) => {
  let result = false;
  if (domainName.indexOf('.') !== -1) {
    let arr = domainName.toString().split('.');
    arr.forEach((x) => {
      if (checkIfStringHasSpecialChar(x)) {
        result = true;
      }
    });
  } else {
    if (checkIfStringHasSpecialChar(domainName)) {
      result = true;
    }
  }
  return result;
};
function checkIfStringHasSpecialChar(_string) {
  let result = false;
  let spChars = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
  if (spChars.test(_string)) {
    result = true;
  }
  return result;
}
export const oidcOnAppSettingsFile = ['oftad', 'sintefazuread'];
export const getDomainAsArrayFromString = (domainString: string) => {
  let result = [domainString];
  if (domainString.indexOf(',') > -1) {
    result = domainString.split(',');
  }
  return result;
};
export const changeDateFormat = (date: string) => {
  let dateParts = date && date.split('.');
  let endPart = dateParts && dateParts[2] && dateParts[2].split(' ');
  let year = endPart && endPart[0];
  let month = dateParts[1];
  let day = dateParts[0];
  let result = year + '-' + month + '-' + day;
  return result;
};
export const convertArrtoCommaSeparetdString = (arr: string[]) => {
  let result = '';
  if (arr && arr.length > 0) {
    for (let i = 0; i < arr.length; i++) {
      if (i !== arr.length - 1) {
        result += arr[i] + ',';
      } else {
        result += arr[i];
      }
    }
  }
  return result;
};
