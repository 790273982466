import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { UPDATE_PRICE } from './subscriptions.graphql';
import moment from 'moment';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MomentUtils from '@date-io/moment';
import { SubscriptionModel } from '../interfaces';
import { useActionStyles } from './overview';

export function UpdatePriceDialog({
  subscription,
  open,
  onClose,
  setBusy,
}: {
  subscription: SubscriptionModel;
  open: boolean;
  onClose();
  setBusy(busy: boolean);
}) {
  const classes = useActionStyles();
  const price = subscription.price;
  const defaultTimeOfChange = moment().add(1, 'years').startOf('year').toDate();

  const [singlePrice, setSinglePrice] = useState(price.singleUser);
  const [extraPrice, setExtraPrice] = useState(price.extraUser);
  const [timeOfChange, setTimeOfChange] = useState(defaultTimeOfChange);

  const [updateValue, { loading }] = useMutation(UPDATE_PRICE, {
    variables: { input: { subscriptionId: subscription.bksId, singleUser: singlePrice, extraUser: extraPrice, timeOfChange } },
  });

  useEffect(() => {
    setBusy(loading);
  }, [loading, setBusy]);

  if (!price) return null;

  const priceChanged = singlePrice !== price.singleUser || extraPrice !== price.extraUser;

  const onTimeOfChangeChanged = (newValue) => {
    if (newValue) setTimeOfChange(newValue);
    else setTimeOfChange(defaultTimeOfChange);
  };

  const onCommit = () => {
    updateValue();
    onClose();
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="dialog-title" open={open}>
      <DialogTitle id="dialog-title">
        Endre pris
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} size="large">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <form>
          <div className={classes.timePanel}>
            <Typography>Når skal endringen gjelde fra?</Typography>
            <div>
              <LocalizationProvider utils={MomentUtils} dateAdapter={AdapterDateFns}>
                <DatePicker
                  disablePast
                  showTodayButton
                  inputFormat="d/M/yyyy"
                  label="dato"
                  value={timeOfChange}
                  onChange={onTimeOfChangeChanged}
                  renderInput={(params) => <TextField {...params} helperText={params?.inputProps?.placeholder} />}
                />
                <TimePicker
                  className={classes.timePicker}
                  ampm={false}
                  inputFormat="HH:mm"
                  label="tidspunkt"
                  value={timeOfChange}
                  onChange={onTimeOfChangeChanged}
                  renderInput={(params) => <TextField {...params} helperText={params?.inputProps?.placeholder} />}
                />
              </LocalizationProvider>
            </div>
          </div>
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
              <TextField
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">kr</InputAdornment>,
                  inputProps: { min: 0 },
                }}
                label="1 bruker"
                helperText="Beløp for 1 bruker"
                type="number"
                margin="normal"
                required
                value={singlePrice}
                onChange={(ev) => setSinglePrice(Number(ev.target.value))}
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                label="Ekstra brukere"
                helperText="Beløp pr ekstra bruker"
                InputProps={{
                  startAdornment: <InputAdornment position="start">kr</InputAdornment>,
                  inputProps: { min: 0 },
                }}
                type="number"
                margin="normal"
                required
                value={extraPrice}
                onChange={(ev) => setExtraPrice(Number(ev.target.value))}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Avbryt
        </Button>
        <Button onClick={onCommit} disabled={!priceChanged} color="primary" variant="contained">
          Endre pris
        </Button>
      </DialogActions>
    </Dialog>
  );
}
