import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';

interface IncreaseAccessProps {
  open: boolean;
  subscriptionName: string;
  onClose(): void;
  onSubmit(increase: number);
}

export function IncreaseAccessDialog({ open, subscriptionName, onClose, onSubmit }: IncreaseAccessProps) {
  const [increase, setIncrease] = useState(0);

  const abort = () => {
    onClose();
    setIncrease(0);
  };

  const submit = () => {
    onSubmit(increase);
    onClose();
    setIncrease(0);
  };

  const catchReturn = (ev) => {
    if (ev.key === 'Enter') {
      submit();
      ev.preventDefault();
    }
  };

  return (
    <Dialog open={open} onClose={abort}>
      <DialogTitle>{`Legg til nye tilganger til ${subscriptionName}`}</DialogTitle>
      <DialogContent>
        <form>
          <TextField
            autoFocus
            type="number"
            label="Antall nye tilganger"
            fullWidth
            value={increase}
            onChange={(ev) => setIncrease(Number(ev.target.value))}
            margin="normal"
            onKeyPress={catchReturn}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={abort}>Avbryt</Button>
        <Button disabled={increase <= 0} variant="contained" color="primary" onClick={submit}>
          Legg til
        </Button>
      </DialogActions>
    </Dialog>
  );
}
