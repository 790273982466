import React, { useState } from 'react';
import { Table, TableHead, TableRow, TableCell, Button, Dialog, DialogActions, DialogContent, DialogTitle, TableBody } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { RenewalInfo } from './Overview';
import { numberAsKr } from '../Formatters';
import Dropzone from 'react-dropzone';
import XLSX from 'xlsx';
import { Moment } from 'moment';

const useStyles = makeStyles((theme) => ({
  ours: {
    color: theme.palette.primary.main,
  },
  theirs: {
    color: theme.palette.error.main,
  },
  dropzoneBox: {
    alignItems: 'center',
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    marginTop: 30,
  },
  dropZoneContainer: {
    width: 330,
    height: 200,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 70,
  },
  stacked: {
    position: 'absolute',
  },
  spinnerContainer: {
    marginLeft: 100,
    marginTop: 5,
  },
}));

export function DiffMaconomy({ renewals, date }: { renewals: RenewalInfo[]; date: Moment }) {
  const [items, setItems] = useState<MaconomyItem[] | undefined>(undefined);

  return (
    <>
      <FileUploader onUploaded={(x) => setItems(x)} />
      {items && <DiffMaconomyDialog onClose={() => setItems(undefined)} renewals={renewals} items={items} date={date} />}
    </>
  );
}
interface MaconomyItem {
  subscriptionNumber: string;
  customerNumber: string;
  subscription: string;
  sum: number;
}

interface Diff {
  ours?: number;
  theirs?: number;
}

interface DiffItem {
  subscriptionNumber: string;
  licenseId: number;
  name: string;
  bfs: Diff;
  bfsp: Diff;
  bfsb: Diff;
  bfsf: Diff;
  bfsu: Diff;
  bvn: Diff;
}

function DiffMaconomyDialog({ renewals, items, onClose, date }: { renewals: RenewalInfo[]; items: MaconomyItem[]; onClose(): void; date: Moment }) {
  const classes = useStyles();
  const array: DiffItem[] = [];
  renewals.forEach((element) => {
    const maconomyItems = items.filter((x) => x.subscriptionNumber === element.subscriptionOrderNumber);
    const getValue = (subscription: string): number | undefined => {
      const items = maconomyItems.filter((x) => x.subscription === subscription);
      if (items.length === 0) return undefined;

      return items.reduce((total, x) => total + x.sum, 0);
    };
    array.push({
      subscriptionNumber: element.subscriptionOrderNumber,
      name: element.name,
      licenseId: element.licenseId,
      bfs: { ours: element.bfs, theirs: getValue('BFS') },
      bfsp: { ours: element.bfsp, theirs: getValue('BFSP') },
      bfsb: { ours: element.bfsb, theirs: getValue('BFSB') },
      bfsf: { ours: element.bfsf, theirs: getValue('BFSF') },
      bfsu: { ours: element.bfsu, theirs: getValue('BFSU') },
      bvn: { ours: element.bvn, theirs: getValue('BVN') },
    });
  });

  const grouped: any = items
    .filter((x) => renewals.find((r) => r.subscriptionOrderNumber === x.subscriptionNumber) === undefined)
    .reduce(function (rv, x) {
      (rv[x.subscriptionNumber] = rv[x.subscriptionNumber] || []).push(x);
      return rv;
    }, {});

  Object.getOwnPropertyNames(grouped).forEach((name) => {
    const items: MaconomyItem[] = grouped[name];
    const getValue = (subscription: string): number | undefined => {
      const subscriptionItems = items.filter((x) => x.subscription === subscription);
      if (subscriptionItems.length === 0) return undefined;

      return subscriptionItems.reduce((total, item) => total + item.sum, 0);
    };

    array.push({
      subscriptionNumber: name,
      name: items[0].customerNumber,
      licenseId: 0,
      bfs: { theirs: getValue('BFS') },
      bfsp: { theirs: getValue('BFSP') },
      bfsb: { theirs: getValue('BFSB') },
      bfsf: { theirs: getValue('BFSF') },
      bfsu: { theirs: getValue('BFSU') },
      bvn: { theirs: getValue('BVN') },
    });
  });

  const diffs = array.filter((x) => hasDiff(x.bfs) || hasDiff(x.bfsp) || hasDiff(x.bfsb) || hasDiff(x.bfsf) || hasDiff(x.bfsu) || hasDiff(x.bvn));

  return (
    <Dialog open onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>Avvik i Maconomy ({date.format('MMMM YYYY')})</DialogTitle>
      <DialogContent>
        <div>
          <span className={classes.ours}>Våre tall</span>
          <span className={classes.theirs}> Deres tall</span>
        </div>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Kunde</TableCell>
              <TableCell>Abonnement</TableCell>
              <TableCell align="right">Komplett</TableCell>
              <TableCell align="right">Planlegging</TableCell>
              <TableCell align="right">Byggdetaljer</TableCell>
              <TableCell align="right">Byggforvaltning</TableCell>
              <TableCell align="right">Utførelse</TableCell>
              <TableCell align="right">BVN</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {diffs.map((s) => {
              return (
                <TableRow key={`${s.subscriptionNumber}:${s.name}`}>
                  <TableCell>{s.licenseId ? <Link to={'/license/' + s.licenseId}>{s.name}</Link> : s.name}</TableCell>
                  <TableCell>{s.subscriptionNumber}</TableCell>
                  <DiffCell diff={s.bfs} />
                  <DiffCell diff={s.bfsp} />
                  <DiffCell diff={s.bfsb} />
                  <DiffCell diff={s.bfsf} />
                  <DiffCell diff={s.bfsu} />
                  <DiffCell diff={s.bvn} />
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Lukk</Button>
      </DialogActions>
    </Dialog>
  );
}

function DiffCell({ diff }: { diff: Diff }) {
  const classes = useStyles();

  if (!hasDiff(diff)) return <TableCell />;
  return (
    <TableCell align="right">
      <span className={classes.ours}>{numberAsKr(diff.ours)}</span>
      <span className={classes.theirs}>{numberAsKr(diff.theirs)}</span>
    </TableCell>
  );
}

function hasDiff(a: Diff): boolean {
  if (!a.ours && !a.theirs) return false;

  return roundToTwo(a.ours) !== roundToTwo(a.theirs);
}

function FileUploader({ onUploaded }: { onUploaded(items: MaconomyItem[]): void }) {
  const classes = useStyles({});

  const addFile = async (newFiles: File[]) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      if (e.target && e.target.result) {
        const data = new Uint8Array(e.target.result as ArrayBufferLike);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheet = workbook.Sheets['Abonnement_fakturering_sjekker_'];
        const array: MaconomyItem[] = [];
        var currentRow = 13;
        while (sheet.hasOwnProperty(`A${currentRow}`)) {
          array.push({
            subscriptionNumber: sheet[`A${currentRow}`].v.toString(),
            customerNumber: sheet[`B${currentRow}`].v,
            subscription: sheet[`F${currentRow}`].v,
            sum: sheet[`K${currentRow}`].v,
          });
          currentRow++;
        }
        onUploaded(array);
      }
    };
    reader.readAsArrayBuffer(newFiles[0]);
  };

  return (
    <div className={classes.dropZoneContainer}>
      <div className={classes.stacked}>
        <Dropzone onDrop={addFile} accept=".xlsx">
          {({ getRootProps, getInputProps }) => (
            <section>
              <div className={classes.dropzoneBox} {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Dra og slipp Maconomy rapport her for å sammenligne, eller klikk for å velge</p>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    </div>
  );
}

function roundToTwo(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}
