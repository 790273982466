import gql from 'graphql-tag';

const RenewalItem = gql`
  fragment RenewalModelItem on RenewalModel {
    id
    licenseId
    name
    subscriptionOrderNumber
    bfs
    bfsp
    bfsb
    bfsf
    bfsu
    bvn
    total
  }
`;

export const GET_RENEWALS = gql`
    query($month: Int!, $year: Int!){ 
        renewals: getRenewals(month: $month, year: $year)  { 
            ...RenewalModelItem
        } 
        renewalsNewPrices: getRenewalsAtNotificationWithNewPrices(month: $month, year: $year)  { 
            ...RenewalModelItem
        }
    }
    ${RenewalItem}`;

export const GET_RENEWALS_NEW = gql`
    query($month: Int!, $year: Int!){ 
    
        renewalsNewPrices: getRenewalsAtNotificationWithNewPrices(month: $month, year: $year)  { 
            ...RenewalModelItem
        }
    }
    ${RenewalItem}`;

export const GET_RENEWALS_MACONOMY = gql`
  query ($month: Int!, $year: Int!) {
    renewals: getRenewalsFromMaconomy(month: $month, year: $year) {
      ...RenewalModelItem
    }
  }
  ${RenewalItem}
`;

export const UPDATE_MACONOMY = gql`
  mutation ($input: UpdateMaconomyInput!) {
    updateMaconomy(input: $input) {
      id
      fetchedAt
      dataPoints {
        licenseId
        total
      }
    }
  }
`;
