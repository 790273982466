import makeStyles from '@mui/styles/makeStyles';
import { Grid, CircularProgress, Box } from '@mui/material';
import MainContentPanel from '../components/MainContentPanel';
import { useQuery } from '@apollo/client';
import { GET_USER_OCCUPATION } from './reports.graphql';
import { UserModel } from '../interfaces';
import { Typography, TextField } from '@mui/material';
import { GeneralExcelExport, GeneralExcelExportProps } from './brreg';
import React, { useState } from 'react';

export const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  export: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  searchField: {
    marginBottom: '16px',
    display: 'flex',
  },
  searchInput: {
    flexGrow: 1,
    marginLeft: '16px',
  },
}));

export function UserOccupation() {
  const [searchQuery, setSearchQuery] = useState('');
  const { loading, data } = useQuery<{ userOccupation: UserModel[] }>(GET_USER_OCCUPATION);
  const classes = useStyles();
  if (loading || !data) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
  const groupedData = groupByOccupation(data.userOccupation);

  const filteredData = groupedData.filter(({ occupation }) => occupation.toLowerCase().includes(searchQuery.toLowerCase()));
  const arrHeading: string[] = ['Yrke', 'Antall bruker'];
  const dataSet = groupedData && groupedData.map((x) => [x.occupation, x.count]);
  const workBook = 'BRUKER_ANTALL_PER_YRKE';
  const fileName = 'brukerantall.xlsx';
  const props: GeneralExcelExportProps = {
    dataSet: JSON.stringify(dataSet),
    headings: JSON.stringify(arrHeading),
    workBookName: workBook,
    fileName: fileName,
  };
  return (
    <MainContentPanel>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Brukerantall per yrke</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.searchField}>
            <TextField label="Søk" variant="outlined" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} fullWidth />
            {groupedData && <GeneralExcelExport {...props} />}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {filteredData.map(({ occupation, count }) => (
              <Grid item key={occupation} xs={4}>
                <div className={classes.card}>
                  <Typography variant="subtitle1" style={{ fontSize: '1.2rem', color: 'darkslategray' }}>
                    {occupation}
                  </Typography>
                  <Typography variant="subtitle1">{`Antall: ${count}`}</Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </MainContentPanel>
  );
}
function groupByOccupation(data: UserModel[]) {
  const groupedData = data.reduce(
    (groups, user) => {
      const { occupation } = user;
      if (!groups[occupation]) {
        groups[occupation] = {
          occupation,
          count: 0,
        };
      }
      groups[occupation].count++;
      return groups;
    },
    {} as Record<string, { occupation: string; count: number }>,
  );
  return Object.values(groupedData);
}
