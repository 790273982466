import { TextField } from '@mui/material';
import { useState } from 'react';
interface Prop {
  callbackValue: (name: string) => void;
  existingId: string;
}
export default function ClientID(prop: Prop) {
  const { callbackValue: sendbackClientID, existingId } = prop;
  const [clientID, setClientID] = useState(existingId);
  const handleClientIDBlur = (id: string) => {
    sendbackClientID(id);
  };
  return (
    <>
      <TextField
        label="Client ID"
        fullWidth
        value={clientID}
        type="text"
        onChange={(event) => setClientID(event.target.value)}
        onBlur={(event) => handleClientIDBlur(event.target.value)}
        margin="normal"
        required
      />
    </>
  );
}
