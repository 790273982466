import React, { useState } from 'react';
import { TextField, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  customColor: {
    color: '#717171',
  },
  errorBorder: {
    border: '1px solid red',
  },
});

const ExtraUser = ({ initialValue, onChange, disabled }) => {
  const [value, setValue] = useState(initialValue);
  if (initialValue !== value) setValue(initialValue);
  const handleChange = (event) => {
    const newValue = event.target.value;
    if (newValue === '' || (parseInt(newValue) >= 0 && parseInt(newValue) <= 200)) {
      setValue(newValue);
      onChange(newValue === '' ? '' : parseInt(newValue)); // Pass the new value back to the parent component
    }
  };

  const classes = useStyles();
  const isError = !disabled && (parseInt(value) < 1 || parseInt(value) > 200 || value === '');

  return (
    <div>
      <Box border={1} borderColor="grey.500" p={2} borderRadius={2} className={isError ? classes.errorBorder : classes.customColor}>
        <Typography gutterBottom>Extra Percentage</Typography>
        <TextField
          type="number"
          value={value}
          onChange={handleChange}
          disabled={disabled}
          fullWidth
          variant="outlined"
          margin="dense"
          inputProps={{ min: 1, max: 200 }}
        />
      </Box>
    </div>
  );
};

export default ExtraUser;
