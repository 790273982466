import makeStyles from '@mui/styles/makeStyles';
import { TableCell, TableRow } from '@mui/material';
import { RequiredClaim } from '../../interfaces';
interface Prop {
  requiredClaim: RequiredClaim;
}
export default function ClaimBox(props: Prop) {
  const { requiredClaim } = props;
  const useStyles = makeStyles((theme) => ({
    tableRow: {
      paddingLeft: 22,
    },
    tableCell: {
      paddingLeft: 22,
    },
  }));
  const classes = useStyles();
  return (
    <>
      <TableRow className={classes.tableRow}>
        <TableCell>{requiredClaim.type}</TableCell>
        <TableCell className={classes.tableCell}>{requiredClaim.value}</TableCell>
      </TableRow>
    </>
  );
}
