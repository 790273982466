import { Typography, Grid, Button, Tooltip } from '@mui/material';
import { ArrowLeft, ArrowRight, FastForward, FastRewind } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import Moment from 'moment';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'inline-block',
  },
  titleIcon: {
    display: 'inline-block',
    marginRight: -8,
    float: 'right',
  },
  btn: {
    minWidth: 24,
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

interface Props {
  title: string;
  offset?: number;
  displayYear?: boolean;
  forwardLimit?: number;
  yearNav?: boolean;
  stateSetter?(number): void;
}

export function WidgetTitle({ title, offset, displayYear, forwardLimit, yearNav, stateSetter }: Props) {
  const classes = useStyles();

  const now = Moment(Date.now());
  var date = Moment(now).add(offset, 'months');
  var month = date.format('MMMM');
  var year = date.format('yyyy');

  const canGoBack = (step) => Moment(date).add(step, 'months') > Moment('2020-05-01');

  const canGoForward = (step) => Moment(date).add(step, 'months') < Moment(now).add(forwardLimit ?? 0, 'months');

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom className={classes.title}>
          {title} {month} {displayYear || Moment(date).add(-1, 'months').year() !== now.year() ? year : ''}
        </Typography>
        {offset !== undefined && stateSetter && (
          <div className={classes.titleIcon}>
            {yearNav && (
              <Tooltip title="forrige år">
                <Button
                  variant="text"
                  className={classes.btn}
                  onClick={() => {
                    stateSetter(-12);
                  }}
                  disabled={!canGoBack(-12)}
                >
                  <FastRewind sx={{ fontSize: 20 }} />
                </Button>
              </Tooltip>
            )}
            <Tooltip title="forrige måned">
              <Button
                variant="text"
                className={classes.btn}
                onClick={() => {
                  stateSetter(-1);
                }}
                disabled={!canGoBack(-1)}
              >
                <ArrowLeft />
              </Button>
            </Tooltip>
            <Tooltip title="neste måned">
              <Button
                variant="text"
                className={classes.btn}
                onClick={() => {
                  stateSetter(+1);
                }}
                disabled={!canGoForward(1)}
              >
                <ArrowRight />
              </Button>
            </Tooltip>
            {yearNav && (
              <Tooltip title="neste år">
                <Button
                  variant="text"
                  className={classes.btn}
                  onClick={() => {
                    stateSetter(+12);
                  }}
                  disabled={!canGoForward(12)}
                >
                  <FastForward sx={{ fontSize: 20 }} />
                </Button>
              </Tooltip>
            )}
          </div>
        )}
      </Grid>
    </Grid>
  );
}
