import React from 'react';
import { Typography, Button } from '@mui/material';
import { Banner } from '../../components/Banner';
import { HighlightOff } from '@mui/icons-material';
import { CANCEL_TERMINATE } from './LicenseInfo.graphql';
import { License, LicenseModel } from '../../interfaces';
import { formatDateOnly } from '../../Formatters';
import { useMutation } from '@apollo/client';

export function DeletedBanner({ license, bksLicense }: { bksLicense: License; license: LicenseModel }) {
  const [cancelTermination] = useMutation(CANCEL_TERMINATE, { variables: { input: { id: license?.bksId } } });

  if (bksLicense.licenseStatus !== 'Deleted' && bksLicense.licenseStatus !== 'Terminated' && (!license || !license.pendingTermination)) return null;

  if (license) {
    const reason = license.terminationReason;
    const header = reason ? `Slettet: ${reason}` : 'Slettet';
    const date = license.pendingTermination ? bksLicense.agreementExpire : license.terminatedAt;
    const dateString = date ? ` (${formatDateOnly(date)})` : undefined;
    const action = license.pendingTermination ? <Button onClick={() => cancelTermination()}>Avbryt oppsigelse</Button> : undefined;
    return (
      <Banner icon={<HighlightOff fontSize="large" />} action={action}>
        <Typography variant="body2">
          {header}
          {dateString}
        </Typography>
      </Banner>
    );
  }

  return (
    <Banner icon={<HighlightOff fontSize="large" />}>
      <Typography variant="body2">Slettet</Typography>
    </Banner>
  );
}
