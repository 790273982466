import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ListItemAddress } from '../../components/ListItemAddress';
import { ListItemPhoneNumber } from '../../components/ListItemPhoneNumber';
import { ListItemEmail } from '../../components/ListItemEmail';
import { ListItemOrgNo } from './ListItemOrgNo';
import { ListItemCompanyName } from './ListItemCompanyName';
import { License, LicenseModel } from '../../interfaces';
import { useMutation } from '@apollo/client';
import { UPDATE_NAME, UPDATE_ORGNUMBER, UPDATE_EMAIL, UPDATE_PHONE, UPDATE_ADDRESS, UPDATE_INVOICEREF } from './LicenseInfo.graphql';
import { ListItemWithEdit } from '../../components/ListItemWithEdit';
import { Receipt, Security, WorkspacePremium } from '@mui/icons-material';
import NewADFSButton from '../../administration/ADFSConfig/AddADFSConfigDialog';
import DeleteAdfs from '../../administration/ADFSConfig/DeleteAdfs';
import makeStyles from '@mui/styles/makeStyles';

interface ListProps {
  display: boolean;
  bksLicense: License;
  license: LicenseModel;
}
const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none' as 'none',
  },
  accessType: {
    maxWidth: 'fit-content' as 'fit-content',
  },
  btnDelete: {
    display: 'flex' as 'flex',
    justifyContent: 'flex-end' as 'flex-end',
  },
}));

export function LicenseCard({ display, bksLicense, license }: ListProps) {
  const classes = useStyles();
  const exec = (action, obj) => action({ variables: { input: { id: bksLicense.id, ...obj } } });
  const [updateName] = useMutation(UPDATE_NAME);
  const [updateOrgNo] = useMutation(UPDATE_ORGNUMBER);
  const [updateEmail] = useMutation(UPDATE_EMAIL);
  const [updatePhone] = useMutation(UPDATE_PHONE);
  const [updateAddress] = useMutation(UPDATE_ADDRESS);
  const [updateInvoiceRef] = useMutation(UPDATE_INVOICEREF);

  const doUpdateName = (companyName) => exec(updateName, { companyName });
  const doUpdateOrgNo = (orgNumber) => exec(updateOrgNo, { orgNumber });
  const doUpdateEmail = (email) => exec(updateEmail, { email });
  const doUpdatePhone = (phone) => exec(updatePhone, { phone });
  const doUpdateAddress = (address) => updateAddress({ variables: { input: address } });
  const doUpdateInvoiceRef = (invoiceRef) => exec(updateInvoiceRef, { invoiceRef });
  const isAdfsType = (customerType) => {
    return customerType.toLowerCase() === 'brukernavn/passord' ? false : true;
  };
  if (!display) return null;
  return (
    <List>
      <ListItemCompanyName companyName={bksLicense.companyName} update={doUpdateName} />
      <ListItemOrgNo orgNumber={bksLicense.orgNumber} update={doUpdateOrgNo} />
      <ListItemEmail email={bksLicense.email} update={doUpdateEmail} />
      <ListItemPhoneNumber phone={bksLicense.phone} update={doUpdatePhone} />
      <ListItemAddress address={bksLicense.address} update={doUpdateAddress} />
      <ListItemWithEdit
        title="Faktura referanse"
        label="Faktura referanse"
        icon={<Receipt />}
        value={license ? license.invoiceRef : ''}
        update={doUpdateInvoiceRef}
      />
      <ListItem>
        <ListItemIcon title="Kundetype">
          <Security />
        </ListItemIcon>
        {!isAdfsType(bksLicense.customerType) && <ListItemText className={classes.accessType}>{bksLicense.customerType}</ListItemText>}
        <ListItemText className={classes.link}>
          <NewADFSButton licenseId={bksLicense && bksLicense.id} licenseScheme={license && license.adfsScheme} />
        </ListItemText>
        {isAdfsType(bksLicense.customerType) && (
          <ListItemText className={classes.btnDelete}>
            <DeleteAdfs licenseId={bksLicense && bksLicense.id} provider={license && license.adfsScheme} />
          </ListItemText>
        )}
      </ListItem>
      {license.isGodkjentVaatromsbedrift === true && <ListItem>
        <ListItemIcon>
          <WorkspacePremium />
        </ListItemIcon>
        <ListItemText>Godkjent våtromsbedrift</ListItemText>
      </ListItem>}
    </List>
  );
}
