import gql from 'graphql-tag';
import { DiscountItem, DiscountOverviewItem } from '../interfaces.graphql';

export const GET_DISCOUNTS = gql`
  query ($id: ID!) {
    discounts: getDiscounts(id: $id) {
      ...DiscountOverviewItem
    }
  }
  ${DiscountOverviewItem}
`;

export const GET_DISCOUNT = gql`
  query ($id: ID!) {
    discount: getDiscount(id: $id) {
      ...DiscountItem
    }
  }
  ${DiscountItem}
`;

export const GET_DISCOUNT_TOKEN = gql`
  query ($id: ID!) {
    discountToken: getDiscountToken(id: $id) {
      id
    }
  }
`;

export const GET_USAGES = gql`
  query ($id: ID!) {
    licenses: getDiscountUsages(id: $id) {
      id
      companyName
      orgNumber
      customerNumber
      administrator
      isDeleted
      createdAt
    }
  }
`;

export const CREATE_DISCOUNT = gql`
  mutation CreateDiscount($input: CreateDiscountInput!) {
    createDiscount(input: $input) {
      ...DiscountItem
    }
  }
  ${DiscountItem}
`;

export const DELETE_DISCOUNT = gql`
  mutation DeleteDiscount($input: DeleteDiscountInput!) {
    deleteDiscount(input: $input)
  }
`;

export const DEACTIVATE_DISCOUNT = gql`
  mutation DeactivateDiscount($input: DeactivateDiscountInput!) {
    deactivateDiscount(input: $input) {
      ...DiscountItem
    }
  }
  ${DiscountItem}
`;

export const UPDATE_DISCOUNT = gql`
  mutation UpdateDiscount($input: UpdateDiscountInput!) {
    updateDiscount(input: $input) {
      ...DiscountItem
    }
  }
  ${DiscountItem}
`;

export const UPDATE_VALUE = gql`
  mutation UpdateDiscountValue($input: UpdateDiscountValueInput!) {
    updateDiscountValue(input: $input) {
      ...DiscountItem
    }
  }
  ${DiscountItem}
`;

export const UPDATE_EMAIL_FILTER = gql`
  mutation UpdateDiscountEmailFilter($input: UpdateDiscountEmailFilterInput!) {
    updateDiscountEmailFilter(input: $input) {
      ...DiscountItem
    }
  }
  ${DiscountItem}
`;

export const CANCEL_VALUE_UPDATE = gql`
  mutation cancelDiscountValueUpdate($input: CancelDiscountValueUpdateInput!) {
    cancelDiscountValueUpdate(input: $input) {
      ...DiscountItem
    }
  }
  ${DiscountItem}
`;

export const GET_SUBSCRIPTIONS = gql`
  query {
    subscriptions: allSubscriptions(onlyPaid: true) {
      id
      name
      number
      parentId
    }
  }
`;
