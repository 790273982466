import React from 'react';
import { Dialog, DialogTitle, Button, TextField, DialogContent, DialogActions } from '@mui/material';

interface Props {
  firstName: string;
  lastName: string;
  open: boolean;
  onClose(): void;
  update(firstName: string, lastName: string): void;
}

interface State {
  firstName: string;
  lastName: string;
}

export class UpdateNameDialog extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    let { firstName, lastName } = this.props;
    this.state = {
      firstName: firstName,
      lastName: lastName,
    };
  }

  handleFirstNameChange = (event) => {
    let { value } = event.target;
    this.setState({ firstName: value });
  };

  handleLastNameChange = (event) => {
    let { value } = event.target;
    this.setState({ lastName: value });
  };

  abort = () => {
    let { firstName, lastName, onClose } = this.props;
    this.setState({
      firstName: firstName,
      lastName: lastName,
    });
    onClose();
  };

  commit = () => {
    let { firstName, lastName } = this.state;
    let { onClose, update } = this.props;
    update(firstName, lastName);
    onClose();
  };

  render() {
    let { open } = this.props;
    let { firstName, lastName } = this.state;
    return (
      <Dialog open={open}>
        <DialogTitle>Endre Navn</DialogTitle>
        <DialogContent>
          <form>
            <TextField label="Fornavn" fullWidth value={firstName} onChange={this.handleFirstNameChange} margin="normal" />
            <TextField label="Etternavn" fullWidth value={lastName} onChange={this.handleLastNameChange} margin="normal" />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.abort}>Avbryt</Button>
          <Button variant="contained" color="primary" onClick={this.commit}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
