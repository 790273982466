import React, { useState, useCallback } from 'react';
import { TextField } from '@mui/material';
import { debounce } from 'throttle-debounce';

interface SearchProps {
  filter(searchTerm: string): void;
  parentSearchTerm?: string;
}

export function SearchField({ filter, parentSearchTerm }: SearchProps) {
  const [searchTerm, setSearchTerm] = useState(parentSearchTerm === undefined ? '' : parentSearchTerm);
  const triggerFilter = useCallback((s: string) => debounce(300, filter(s)), [filter]);

  const handleFilterInput = (event) => {
    let s = event.target.value;
    if (!s) {
      sessionStorage.setItem('searchTerm', '');
    }
    setSearchTerm(s);
    triggerFilter(s);
  };

  return (
    <TextField
      margin="dense"
      name="searchTerm"
      label="Søk"
      type="text"
      variant="standard"
      onChange={handleFilterInput}
      value={searchTerm}
      fullWidth
    />
  );
}
