import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  Button,
  TextField,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
  Typography,
} from '@mui/material';

import { GET_COUNTRIES } from './ListItemAddress.graphql';
import { Address } from '../interfaces';
import Loading from '../components/LoadingSpinner';
import { useQuery } from '@apollo/client';

interface Props {
  address: Address;
  open: boolean;
  onClose(): void;
  updateAddress(input: Address): void;
}

export function UpdateAddressDialog({ open, address, onClose, updateAddress }: Props) {
  const [newAddress, setNewAddress] = useState({ ...address });

  const handleChange = (event) => {
    let { id, value } = event.target;

    let newAddress2 = { ...newAddress };
    newAddress2[id] = value;

    setNewAddress(newAddress2);
  };

  const handleSelectChange = (event) => {
    let { value } = event.target;
    setNewAddress({ ...newAddress, country: value });
  };

  const abort = () => {
    setNewAddress({ ...address });
    onClose();
  };

  const commit = () => {
    delete newAddress['__typename'];
    updateAddress(newAddress);
    onClose();
  };

  const labels = {
    address1: 'Adresse1',
    address2: 'Adresse2',
    postalCode: 'Postnummer',
    city: 'Poststed',
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Endre adresse</DialogTitle>
      <DialogContent>
        <form>
          {Object.keys(labels).map((key) => (
            <TextField
              key={key}
              id={key}
              label={labels[key]}
              fullWidth
              value={newAddress[key] ? newAddress[key] : ''}
              onChange={handleChange}
              margin="normal"
            />
          ))}
          <CountrySelect label="Land" fullWidth value={newAddress.country} onChange={handleSelectChange} required />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={abort}>Avbryt</Button>
        <Button variant="contained" color="primary" onClick={commit}>
          Endre adresse
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function CountrySelect(props) {
  const { value, onChange, label, fullWidth, required } = props;
  const { loading, data } = useQuery<{ countries: { mostUsed: string[]; all: string[] } }>(GET_COUNTRIES);
  const isReady = !loading && data;

  const item = isReady && data ? data.countries : { mostUsed: [], all: [] };

  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel>{label}</InputLabel>
      {loading && <Loading size={32} />}
      {isReady && (
        <Select value={value ? value : ''} onChange={onChange} required={required} {...props.SelectProps}>
          <ListSubheader disableSticky>
            <Typography color="textPrimary" variant="h6">
              Mest brukt
            </Typography>
          </ListSubheader>
          {item.mostUsed.map((x) => (
            <MenuItem key={x} value={x}>
              {x}
            </MenuItem>
          ))}
          <ListSubheader disableSticky>
            <Typography color="textPrimary" variant="h6">
              Resten
            </Typography>
          </ListSubheader>
          {item.all.map((x) => (
            <MenuItem key={x} value={x}>
              {x}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
}
