import React from 'react';
import { Typography, TableHead, TableCell, TableBody, Table, TableRow } from '@mui/material';
import WidgetPanel from './WidgetPanel';

import gql from 'graphql-tag';
import LoadingSpinner from '../components/LoadingSpinner';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';

var query = gql`
  query {
    licenses: licensesWithUninvoicedChanges {
      id
      companyName
    }
  }
`;

interface License {
  id: number;
  companyName: string;
}

export function LicensesWithUninvoicedChanges() {
  const { data, loading } = useQuery<{ licenses: License[] }>(query);

  return (
    <WidgetPanel>
      <Typography variant="h5">Lisenser med ikke-fakturerte endringer</Typography>
      {loading && <LoadingSpinner />}
      {data && data.licenses && <Details licenses={data.licenses} />}
    </WidgetPanel>
  );
}

function Details({ licenses }: { licenses: License[] }) {
  return (
    <Table padding="none">
      <TableHead>
        <TableRow>
          <TableCell>Navn</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {licenses.map((license) => (
          <TableRow key={license.id}>
            <TableCell>
              <Link to={`/license/${license.id}`}>{license.companyName}</Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
