import React, { useState } from 'react';
import Fuse from 'fuse.js';
import { SearchField } from '../../components/SearchField';
import { UserTable, Props } from './UserTable';
import { UserAccess } from '../../interfaces';

export function SearchableUserTable({ users, subscriptionId, licenseId }: Props) {
  const [searchTerm, setSearchTerm] = useState('');

  let filteredUsers = filterUsers(users, searchTerm);
  return (
    <div>
      <SearchField filter={setSearchTerm} />
      <UserTable users={filteredUsers} subscriptionId={subscriptionId} licenseId={licenseId} />
    </div>
  );
}

function filterUsers(users: UserAccess[], searchTerm: string): UserAccess[] {
  var options = {
    shouldSort: false,
    tokenize: true,
    matchAllTokens: true,
    threshold: 0,
    location: 0,
    distance: 10,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ['user.displayName', 'user.email'],
  };
  if (!searchTerm) {
    return users;
  } else {
    var fuse = new Fuse(users, options);
    var filtered = fuse.search(searchTerm);
    return filtered;
  }
}
