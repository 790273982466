import React from 'react';
import { HowToReg } from '@mui/icons-material';
import { NumberWidget } from './NumberWidget';
import { Link } from 'react-router-dom';

var query = `
    query { 
        number: userAccessTrials
    }`;

export class Trials extends React.PureComponent<any, any> {
  render() {
    return (
      <NumberWidget heading="Aktive prøvetilganger" query={query} icon={<HowToReg fontSize="large" />}>
        <Link to="/license/2126">Se brukerne</Link>
      </NumberWidget>
    );
  }
}
