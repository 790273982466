import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Grid, Link, ListItemButton, Typography } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import BusinessIcon from '@mui/icons-material/Business';
import DocumentOverview from './DocumentOverview';
import CompanyOverview from './CompanyOverview';

// Define styles using Material UI makeStyles hook
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  link: {
    textDecoration: 'none',
  },
  listItemButton: {
    padding: '12px 16px',
  },
  listItemText: {
    fontSize: '14px',
    color: '#287891',
    fontStyle: 'arial',
    marginLeft: '10px',
  },
  icon: {
    color: '#757575',
  },
}));

// Define Overview component using React and TypeScript
function UsageOverview() {
  // Use styles defined above
  const classes = useStyles();

  // Define state to keep track of which overview to display
  const [selectedOverview, setSelectedOverview] = React.useState('usageOverview');

  // Define function to handle link clicks
  const handleLinkClick = (overview: string) => {
    setSelectedOverview(overview);
  };

  return (
    <>
      {selectedOverview === 'usageOverview' && (
        <Grid container spacing={2}>
          <Grid item>
            <Link component={RouterLink} to="#" color="inherit" className={classes.link} onClick={() => handleLinkClick('document')}>
              <ListItemButton className={classes.listItemButton}>
                <DescriptionIcon className={classes.icon} />
                <Typography className={classes.listItemText}>Per Dokument</Typography>
              </ListItemButton>
            </Link>
          </Grid>
          <Grid item>
            <Link component={RouterLink} to="#" color="inherit" className={classes.link} onClick={() => handleLinkClick('company')}>
              <ListItemButton className={classes.listItemButton}>
                <BusinessIcon className={classes.icon} />
                <Typography className={classes.listItemText}>Per Firma</Typography>
              </ListItemButton>
            </Link>
          </Grid>
        </Grid>
      )}
      {selectedOverview === 'document' && <DocumentOverview onBackClick={() => setSelectedOverview('usageOverview')} />}
      {selectedOverview === 'company' && <CompanyOverview onBackClick={() => setSelectedOverview('usageOverview')} />}
    </>
  );
}

export default UsageOverview;
