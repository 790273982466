import React from 'react';
import { Dialog, DialogTitle, Button, DialogContent, DialogActions, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GET_SUBSCRIPTIONS, REMOVE_USER_ACCESS, REMOVE_USER_ACCESSES } from './UsersInLicense.graphql';
import { DataProxy, useMutation, useQuery } from '@apollo/client';
import { LicenseSubscription } from '../../interfaces';
import { GET_ACCESSES } from '../../users/user.graphql';

const useStyles = makeStyles((theme) => ({
  all: {
    background: theme.palette.error.main,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));
interface Props {
  userAccessId: number;
  userId: number;
  displayName: string;
  licenseId: number;
  onClose(): void;
}

interface Accesses {
  id: number;
  license: {
    id: number;
    companyName: string;
  };
  subscription: {
    id: number;
    name: string;
  };
  createdAt: string;
  isDeleted: boolean;
}

export function RemoveUserAccessDialog({ userAccessId, userId, displayName, licenseId, onClose }: Props) {
  const classes = useStyles();
  const { loading, data } = useQuery<{ userAccess: Accesses[] }>(GET_ACCESSES, { variables: { id: userId } });
  const [removeAccess] = useMutation(REMOVE_USER_ACCESS, {
    variables: { input: { id: licenseId, userAccessId: userAccessId } },
    update(cache, { data: { removeUserAccess } }) {
      UpdateGetSubscriptionsCache(cache, licenseId, [removeUserAccess.id]);
      UpdateGetAccesses(cache, userId, [removeUserAccess.id]);
    },
  });
  const [removeAccesses] = useMutation(REMOVE_USER_ACCESSES, {
    variables: { input: { id: licenseId, userId: userId } },
    update(cache, { data: { removeUserAccesses } }) {
      UpdateGetSubscriptionsCache(
        cache,
        licenseId,
        removeUserAccesses.map((x) => x.id),
      );
      UpdateGetAccesses(
        cache,
        userId,
        removeUserAccesses.map((x) => x.id),
      );
    },
  });
  const hasMultipleUserAccess = (): boolean => {
    if (loading || !data) return false;

    return data.userAccess.filter((x) => x.license.id === licenseId).length > 1;
  };

  return (
    <Dialog open={true}>
      <DialogTitle>Fjern brukertilgang</DialogTitle>
      <DialogContent>
        <Typography>Vil du fjerne tilgangen til: {displayName}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Avbryt</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            removeAccess();
            onClose();
          }}
        >
          Fjern
        </Button>
        {hasMultipleUserAccess() && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.all}
            title="Dette vil fjerne brukertilgangen på alle produkter"
            onClick={() => {
              removeAccesses();
              onClose();
            }}
          >
            Fjern alle
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

function UpdateGetSubscriptionsCache(cache: DataProxy, licenseId: number, userAccessIds: number[]) {
  const data = cache.readQuery<{ subscriptions: LicenseSubscription[] }, { id: number }>({ query: GET_SUBSCRIPTIONS, variables: { id: licenseId } });
  if (data === null) return;
  cache.writeQuery({
    query: GET_SUBSCRIPTIONS,
    variables: { id: licenseId },
    data: {
      ...data,
      subscriptions: data.subscriptions.map((x) => {
        return { ...x, userAccess: x.userAccess.filter((ua) => !userAccessIds.includes(ua.id)) };
      }),
    },
  });
}
function UpdateGetAccesses(cache: DataProxy, userId: number, userAccessIds: number[]) {
  const data = cache.readQuery<{ userAccess: Accesses[] }, { id: number }>({ query: GET_ACCESSES, variables: { id: userId } });
  if (data === null) return;
  cache.writeQuery({
    query: GET_ACCESSES,
    variables: { id: userId },
    data: { ...data, userAccess: data.userAccess.filter((x) => !userAccessIds.includes(x.id)) },
  });
}
