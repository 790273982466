import React, { useState } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  DialogActions,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@apollo/client';
import { TERMINATE, CANCEL_TERMINATE, GET_LICENSE } from './LicenseInfo.graphql';
import { LicenseModel } from '../../interfaces';
import { GET_SUBSCRIPTIONS } from './UsersInLicense.graphql';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(),
    minWidth: 120,
  },
  immediate: {
    background: theme.palette.error.main,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

interface TerminationProps {
  licenseId: number;
  license: LicenseModel;
  suspendValue: boolean;
}

export function TerminateButton({ license, licenseId, suspendValue }: TerminationProps) {
  const [showDialog, setShowDialog] = useState(false);
  const [reason, setReason] = useState<string | undefined>(undefined);
  const classes = useStyles();
  const [terminate, { loading }] = useMutation(TERMINATE);
  const [cancelTermination] = useMutation(CANCEL_TERMINATE, { variables: { input: { id: licenseId } } });
  const close = () => {
    setShowDialog(false);
    setReason(undefined);
  };
  const commit = (effectuateNow?: boolean) => {
    terminate({
      variables: { input: { id: licenseId, reason, effectuateNow } },
      update(cache) {
        if (!effectuateNow) return;

        const data = cache.readQuery<{ license: any }>({ query: GET_LICENSE, variables: { id: licenseId } });
        if (data)
          cache.writeQuery({
            query: GET_LICENSE,
            variables: { id: licenseId },
            data: {
              license: {
                __typename: 'LicenseQueryModel',
                bksLicense: { ...data.license.bksLicense, licenseStatus: 'Deleted', isActive: false },
                license: { ...data.license.license, pendingTermination: false, terminationReason: reason },
              },
            },
          });

        const subData = cache.readQuery<{ paidSubscriptions: any[]; subscriptions: any[]; license: any }>({
          query: GET_SUBSCRIPTIONS,
          variables: { id: licenseId },
        });
        if (subData)
          cache.writeQuery({
            query: GET_SUBSCRIPTIONS,
            variables: { id: licenseId },
            data: {
              subscriptions: [],
              paidSubscriptions: subData.paidSubscriptions,
              license: subData.license,
            },
          });
      },
    });
    close();
  };
  return (
    <Grid item>
      {/*console.log('terminateButton suspendValue', suspendValue)*/}
      {license && license.pendingTermination ? (
        <Button variant="contained" color="secondary" onClick={() => cancelTermination()}>
          Avbryt oppsigelse
        </Button>
      ) : (
        <>
          <Button variant="contained" color="secondary" onClick={() => setShowDialog(true)}>
            Avslutt kundeforhold
          </Button>
          <Dialog open={showDialog} onClose={close} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
            <DialogTitle id="form-dialog-title">Avslutt kundeforhold</DialogTitle>
            <DialogContent>
              <DialogContentText>Oppgi årsak til oppsigelse:</DialogContentText>
              <form>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="reason">Årsak</InputLabel>
                  <Select value={reason ? reason : ''} onChange={(ev) => setReason(ev.target.value as string)} input={<Input />}>
                    <MenuItem value="Ønsker ikke å oppgi årsak">
                      <em>Ønsker ikke å oppgi årsak</em>
                    </MenuItem>
                    <MenuItem value={'Konkurs/Opphørt'}>Konkurs/Opphørt</MenuItem>
                    <MenuItem value={'Pensjonist'}>Pensjonist</MenuItem>
                    <MenuItem value={'Har ikke bruk for (nytteverdi)'}>Har ikke bruk for (nytteverdi)</MenuItem>
                    <MenuItem value={'For høy pris'}>For høy pris</MenuItem>
                    <MenuItem value={'Har/Får tilgang gjennom andre'}>Har/Får tilgang gjennom andre</MenuItem>
                    <MenuItem value={'Endring av virksomhet/fagfelt'}>Endring av virksomhet/fagfelt</MenuItem>
                    <MenuItem value={'Diverse'}>Diverse</MenuItem>
                    <MenuItem value={'Mislighold'}>Mislighold</MenuItem>
                  </Select>
                </FormControl>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={close} color="primary">
                Avbryt
              </Button>
              <Button disabled={loading} onClick={() => commit()} variant="contained" color="primary">
                Avslutt
              </Button>
              <Button
                disabled={loading}
                onClick={() => commit(true)}
                variant="contained"
                color="primary"
                className={classes.immediate}
                title="Dette vil avslutte lisensen med umiddelbar virkning"
              >
                Avslutt nå
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Grid>
  );
}
