import { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { ClientAuthorization } from '../../interfaces';
import DeleteClientDialog from './DeleteClientDialog';
import ClaimBox from './ClaimBox';
interface Prop {
  clientAuthorization: ClientAuthorization;
  id: Number;
  bksID: any;
  scheme: string;
}
export default function AuthorizationBox(props: Prop) {
  const { clientAuthorization, id, scheme, bksID } = props;
  const useStyles = makeStyles((theme) => ({
    parentDiv: {
      width: 680,
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: 'silver',
      marginTop: 10,
      borderRadius: 10,
    },
    titleDiv: {
      borderWidth: 2,
      paddingLeft: 20,
      borderRadius: 10,
      marginTop: 10,
      fontWeight: 600,
    },
    deleteDiv: {
      marginLeft: 639,
      marginTop: -24,
    },
  }));
  const classes = useStyles();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const handleDelete = () => {
    setOpenConfirmation(true);
  };
  const onClose = () => {
    setOpenConfirmation(false);
  };
  return (
    <>
      <div className={classes.parentDiv}>
        <div className={classes.titleDiv}>{clientAuthorization.clientAuthorizationID}</div>
        <div className={classes.deleteDiv}>
          <Delete style={{ cursor: 'pointer' }} onClick={() => handleDelete()} />
          <DeleteClientDialog
            open={openConfirmation}
            id={id}
            onClose={onClose}
            name={clientAuthorization.clientAuthorizationID}
            scheme={scheme}
            bksID={bksID}
          />
        </div>
        <div>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Claim</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clientAuthorization.requiredClaims.map((_item, _index) => {
                  return <ClaimBox requiredClaim={_item} key={_index} />;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
}
