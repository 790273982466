import { TableHead, TableCell, TableBody, Table, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import WidgetPanel from './WidgetPanel';
import { WidgetTitle } from './WidgetTitle';
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../components/LoadingSpinner';
import { GET_USERS_WITH_HIGH_USAGE } from '../reports/reports.graphql';

interface User {
  userId: string;
  name: string;
  licenseId: string;
  companyName: string;
  views: string;
}

const LinkToDetails = (props) => (
  <Link to={`/report?tab=5&offset=${props.offset}`} title={props.title}>
    {props.children}
  </Link>
);

export function UsersWithHighUsage() {
  const [monthOffset, setMonthOffset] = useState(0); // get statistics for current month

  var take = 5;

  const { loading, data } = useQuery(GET_USERS_WITH_HIGH_USAGE, { variables: { monthOffset: monthOffset, take: take }, fetchPolicy: 'network-only' });

  return (
    <div>
      <WidgetPanel>
        <WidgetTitle
          title={`Topp ${take} aktive brukere i`}
          offset={monthOffset}
          forwardLimit={1}
          stateSetter={(step) => {
            setMonthOffset(monthOffset + step);
          }}
        />

        {loading && <LoadingSpinner />}
        {!loading && !data && <div>Mangler data</div>}
        {data && renderDetails(data.users)}
        <LinkToDetails offset={monthOffset}>Se mer</LinkToDetails>
      </WidgetPanel>
    </div>
  );

  function renderDetails(users) {
    return (
      <Table padding="none">
        <TableHead>
          <TableRow>
            <TableCell>Navn</TableCell>
            <TableCell align="right">Antall sidevisninger</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user: User) => {
            return (
              <TableRow key={user.userId}>
                <TableCell>
                  <Link to={`/user/${user.userId}`}>{user.name}</Link>
                </TableCell>
                <TableCell align="right">{user.views}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}
