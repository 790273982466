import React from 'react';
import { LocationCity } from '@mui/icons-material';
import { ListItemWithEdit } from '../../components/ListItemWithEdit';

interface Props {
  orgNumber: string;
  update?(orgNumber: string): void;
}

export class ListItemOrgNo extends React.PureComponent<Props> {
  render() {
    let { orgNumber, update } = this.props;
    let icon = <LocationCity />;
    return <ListItemWithEdit icon={icon} value={orgNumber} title="Endre org. nummer" label="Org. nummer" update={update} />;
  }
}
