import React, { useState } from 'react';
import Fuse from 'fuse.js';
import { SearchField } from '../../components/SearchField';
import { OrderTable } from './OrderTable';
import { Switch, FormControlLabel } from '@mui/material';
import { Order } from '../../interfaces';
import { OrderType, OrderStatus } from '../../interfaces.enums';

interface Props {
  orders: Order[];
  showIncomplete: boolean;
  setShowIncomplete(val: boolean): void;
}

export function OrderSearchableTable({ orders, showIncomplete, setShowIncomplete }: Props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [showCard, setShowCard] = useState(true);
  const [showInvoice, setShowInvoice] = useState(true);
  const [showNonInvoiceable, setShowNonInvoiceable] = useState(true);

  const isAuthorized = (order: Order): boolean => {
    return (
      order.status === OrderStatus.Completed ||
      order.status === OrderStatus.Fullfilled ||
      order.status === OrderStatus.Credited ||
      (order.type === OrderType.Invoice && order.status === OrderStatus.Open)
    );
  };

  const filterOrders = (): Order[] => {
    const filteredOrders = orders.filter(
      (x) =>
        (showCard || x.type !== OrderType.Card) &&
        (showInvoice || x.type !== OrderType.Invoice || (showNonInvoiceable && !x.isInvoiceable)) &&
        (showNonInvoiceable || x.isInvoiceable) &&
        (showIncomplete || isAuthorized(x)),
    );
    var options = {
      shouldSort: false,
      tokenize: true,
      matchAllTokens: true,
      threshold: 0,
      location: 0,
      distance: 10,
      maxPatternLength: 32,
      minMatchCharLength: 3,
      keys: ['orderNumber', 'registrationEmail', 'customer.displayName', 'license.companyName'],
    };
    if (!searchTerm) {
      return filteredOrders;
    } else {
      var fuse = new Fuse(filteredOrders, options);
      var fused = fuse.search(searchTerm);
      return fused;
    }
  };

  const filteredOrders = filterOrders();
  return (
    <div>
      <SearchField filter={setSearchTerm} />
      <FormControlLabel control={<Switch onChange={() => setShowCard(!showCard)} checked={showCard} />} label="Kort" />
      <FormControlLabel control={<Switch onChange={() => setShowInvoice(!showInvoice)} checked={showInvoice} />} label="Faktura" />
      <FormControlLabel
        control={<Switch onChange={() => setShowNonInvoiceable(!showNonInvoiceable)} checked={showNonInvoiceable} />}
        label="Ikke-fakturerbar"
      />
      <FormControlLabel control={<Switch onChange={() => setShowIncomplete(!showIncomplete)} checked={showIncomplete} />} label="Ufullstendige" />
      <OrderTable orders={filteredOrders} />
    </div>
  );
}
