import React, { useState, useEffect } from 'react';
import MainContentPanel from '../../components/MainContentPanel';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Paper, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ShareDirectoryClient, FileGetPropertiesResponse } from '@azure/storage-file-share';
import Dropzone from 'react-dropzone';
import LoadingSpinner from '../../components/LoadingSpinner';
import { Delete, ExpandMore } from '@mui/icons-material';
import { formatDateOnly } from '../../Formatters';
import { useUserProfile } from '../../components/User';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_ATTACHMENT, REMOVE_ATTACHMENT, GET_STORAGE_DETAILS } from './Attachments.graphql';
import ConfirmDialog from '../../components/ConfirmDialog';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  dropzoneBox: {
    alignItems: 'center',
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    marginTop: 30,
  },
  summary: {
    alignItems: 'center',
    margin: 0,
  },
  description: {
    flexBasis: '80%',
  },
  secondary: {
    flexGrow: 1,
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 24px',
    borderRadius: 0,
    position: 'relative',
    transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:before': {
      top: -1,
      left: 0,
      right: 0,
      height: 1,
      opacity: 1,
      position: 'absolute',
      content: "''",
      transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      backgroundColor: 'rgba(0,0,0,0.12)',
    },
  },
}));

interface AttachmentsProps {
  licenseId: number;
}

export function Attachments({ licenseId }: AttachmentsProps) {
  const classes = useStyles();
  const [fileToDelete, setFileToDelete] = useState<string | undefined>(undefined);
  const [files, loading, deleteFile, addFiles] = useAzureStorageFiles(licenseId);

  async function deleteAttachment(): Promise<void> {
    if (!fileToDelete) return;

    await deleteFile(fileToDelete);
    setFileToDelete(undefined);
  }

  return (
    <MainContentPanel>
      <Typography gutterBottom variant="h5">
        Vedlegg
      </Typography>

      <Dropzone onDrop={addFiles} disabled={loading}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div className={classes.dropzoneBox} {...getRootProps()}>
              <input {...getInputProps()} />
              <p>Dra og slipp filer her, eller klikk for å velge</p>
            </div>
          </section>
        )}
      </Dropzone>
      {loading && <LoadingSpinner />}

      {files && files.length > 0 && (
        <>
          {files.map((x) =>
            x.description ? (
              <Accordion key={x.name}>
                <AccordionSummary classes={{ content: classes.summary }} expandIcon={<ExpandMore />}>
                  <AttachmentDetails classes={classes} file={x} setFileToDelete={setFileToDelete} />
                </AccordionSummary>
                {x.description && (
                  <AccordionDetails>
                    <Typography variant="body2" component="pre">
                      {decode(x.description)}
                    </Typography>
                  </AccordionDetails>
                )}
              </Accordion>
            ) : (
              <Paper key={x.name} className={classes.paper}>
                <AttachmentDetails classes={classes} file={x} setFileToDelete={setFileToDelete} />
              </Paper>
            ),
          )}
        </>
      )}
      <ConfirmDialog
        open={fileToDelete !== undefined}
        title="Slett vedlegg"
        message={`Vil du slette vedlegget: ${fileToDelete}?`}
        confirmText="Slett"
        deny={() => setFileToDelete(undefined)}
        confirm={async () => await deleteAttachment()}
      />
    </MainContentPanel>
  );
}

function decode(escaped: string) {
  return decodeURI(escaped.replace(/%(?![\dA-F]{2})/g, '%25'));
}

interface DetailProps {
  classes: any;
  file: AzureFile;
  setFileToDelete: (name: string) => void;
}
function AttachmentDetails({ classes, file, setFileToDelete }: DetailProps) {
  return (
    <>
      <Typography className={classes.description}>
        {file.url ? (
          <a title="Åpne vedlegg i ny fane" href={file.url} target="_blank" rel="noreferrer">
            {file.title || file.name}
          </a>
        ) : (
          file.title
        )}
      </Typography>
      <Typography className={classes.secondary} variant="caption">
        {`lastet opp ${formatDateOnly(file.date)}${file.user ? ' av ' + file.user : ''}`}
      </Typography>
      <IconButton onClick={() => setFileToDelete(file.name)} size="large">
        <Delete />
      </IconButton>
    </>
  );
}

interface AzureFile {
  name: string;
  url?: string;
  date?: Date;
  user?: string;
  title?: string;
  description?: string;
}

export function useAzureStorageFiles(licenseId: number): [AzureFile[] | undefined, boolean, (fileName: string) => void, (files: File[]) => void] {
  const [files, setFiles] = useState<AzureFile[] | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const profile = useUserProfile();
  const [addAttachment] = useMutation(ADD_ATTACHMENT);
  const [removeAttachment] = useMutation(REMOVE_ATTACHMENT);
  const { data } = useQuery<{ details: { url: string } }>(GET_STORAGE_DETAILS, { variables: { id: licenseId }, fetchPolicy: 'network-only' });

  let currentShareDirectoryClient: ShareDirectoryClient | undefined = undefined;
  async function getDirectoryClient(): Promise<ShareDirectoryClient> {
    if (currentShareDirectoryClient) return currentShareDirectoryClient;
    if (!data) throw new TypeError('storage details not set');

    const directoryClient = new ShareDirectoryClient(data.details.url);
    currentShareDirectoryClient = directoryClient;

    return directoryClient;
  }

  async function getAzureFile(fileName: string): Promise<AzureFile> {
    const directoryClient = await getDirectoryClient();
    var file = directoryClient.getFileClient(fileName);
    var props = await file.getProperties();
    var created = getMetadata(props, 'created');
    return {
      name: fileName,
      url: props.contentLength && props.contentLength > 0 ? file.url : undefined,
      date: created ? moment(created).toDate() : props.fileCreatedOn,
      user: getMetadata(props, 'user'),
      title: getMetadata(props, 'title'),
      description: getMetadata(props, 'description'),
    };
  }

  const getMetadata = (props: FileGetPropertiesResponse, name: string) =>
    props.metadata && props.metadata[name] ? decode(props.metadata[name]) : undefined;

  async function getFiles() {
    if (!data) return;

    setLoading(true);

    const directoryClient = await getDirectoryClient();
    const list: AzureFile[] = [];
    for await (const entity of directoryClient.listFilesAndDirectories()) {
      if (entity.kind === 'file') list.push(await getAzureFile(entity.name));
    }
    setFiles(list);
    setLoading(false);
  }

  async function deleteFile(fileName: string) {
    if (!data) return;

    setLoading(true);

    const directoryClient = await getDirectoryClient();
    var response = await directoryClient.deleteFile(fileName);
    if (!response.errorCode) {
      removeAttachment({ variables: { input: { id: licenseId, name: fileName } } });
      if (files) setFiles(files.filter((f) => f.name !== fileName));
    }

    setLoading(false);
  }

  async function addFiles(newFiles: File[]) {
    if (!data || newFiles.length === 0) return;

    setLoading(true);

    const directoryClient = await getDirectoryClient();
    await Promise.all(
      newFiles.map(async (file) => {
        const fileClient = directoryClient.getFileClient(file.name);
        fileClient.create(file.size);
        await fileClient.uploadData(file);
        await fileClient.setMetadata({ user: encodeURI(profile.name) });
        addAttachment({ variables: { input: { id: licenseId, name: file.name } } });
      }),
    );

    setLoading(false);
    const azureFiles = await Promise.all(
      newFiles.map(async (x) => {
        return await getAzureFile(x.name);
      }),
    );
    if (files) setFiles(files.concat(azureFiles.filter((af) => !files.some((f) => f.name === af.name))));
    else setFiles(azureFiles);
  }

  useEffect(() => {
    getFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return [files, loading, deleteFile, addFiles];
}
