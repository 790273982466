import React, { useState } from 'react';
import { CircularProgress, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Edit, Delete } from '@mui/icons-material';
import LoadingSpinner from '../components/LoadingSpinner';
import MainContentPanel from '../components/MainContentPanel';
import { formatDate, numberAsKr } from '../Formatters';
import { CANCEL_PRICE_UPDATE, GET_PRICE } from './pdf.graphql';
import { Alert } from '@mui/material';
import { UpdatePriceDialog } from './UpdatePriceDialog';
import { useMutation, useQuery } from '@apollo/client';

interface PriceChange {
  id: string;
  timeOfChange: string;
  newPrice: number;
}
export interface GuidePriceModel {
  id: string;
  price: number;
  pendingPriceChange: PriceChange;
}

export function PdfPriceCard() {
  const { loading, data } = useQuery<{ pdf: GuidePriceModel }>(GET_PRICE);

  return (
    <MainContentPanel>
      <Typography variant="h5">Enkeltanvisning</Typography>
      {loading && <LoadingSpinner />}
      {data && <PdfTable pdf={data.pdf} />}
    </MainContentPanel>
  );
}

function PdfTable({ pdf }: { pdf: GuidePriceModel }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Navn</TableCell>
          <TableCell align="right">Pris</TableCell>
          <TableCell style={{ width: 355 }} />
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Pdf</TableCell>
          <TableCell align="right">
            <Price pdf={pdf} />
          </TableCell>
          <TableCell>
            <PriceAction pdf={pdf} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

const usePriceStyles = makeStyles((theme) => ({
  deleted: {
    textDecoration: 'line-through',
  },
}));

function Price({ pdf }: { pdf: GuidePriceModel }) {
  const classes = usePriceStyles();
  const price = pdf.price;
  if (!price) return null;
  const priceAsKr = numberAsKr(price);
  if (!pdf.pendingPriceChange) return <span>{priceAsKr}</span>;
  return (
    <>
      <Typography className={classes.deleted} color="error" variant="body1" display="inline">
        {priceAsKr}
      </Typography>
      <Typography color="primary" variant="body1" display="inline">
        {numberAsKr(pdf.pendingPriceChange.newPrice)}
      </Typography>
    </>
  );
}

export const useActionStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    width: 250,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  timePanel: {
    background: 'rgb(232, 244, 253)',
    borderRadius: 4,
    padding: 5,
    marginBottom: 25,
  },
  timePicker: {
    marginLeft: theme.spacing(2),
  },
  alert: {
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  progress: {
    verticalAlign: `middle`,
  },
}));

function PriceAction({ pdf }: { pdf: GuidePriceModel }) {
  const classes = useActionStyles();
  const [open, setOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const [cancelUpdate, { loading }] = useMutation(CANCEL_PRICE_UPDATE);
  if (!pdf.price) return null;

  if (pdf.pendingPriceChange) {
    return (
      <>
        <IconButton disabled={loading} title="Forkast endring" onClick={() => cancelUpdate()} size="large">
          <Delete />
        </IconButton>
        <Alert className={classes.alert} severity="info">
          Prisen vil bli oppdatert {formatDate(pdf.pendingPriceChange.timeOfChange)}
        </Alert>
      </>
    );
  } else {
    return (
      <>
        <IconButton disabled={busy} title="Oppdater prisen" onClick={() => setOpen(true)} size="large">
          <Edit />
        </IconButton>
        <UpdatePriceDialog pdf={pdf} open={open} onClose={() => setOpen(false)} setBusy={setBusy} />
        {busy && <CircularProgress className={classes.progress} size={30} />}
      </>
    );
  }
}
