import React from 'react';
import gql from 'graphql-tag';
import { Query } from '@apollo/client/react/components';
import Loading from './LoadingSpinner';

const queryWithLoading = (props: any) => {
  let { query, ...rest } = props;
  if (typeof query === 'string') {
    query = gql(query);
  }
  return (
    <Query errorPolicy={'all'} query={query} {...rest}>
      {(queryProps) => {
        let { networkStatus, error } = queryProps;

        if (networkStatus === 8 || error) {
          return (
            <div>
              <h3>GraphQl error</h3>
              <div>
                <pre>{JSON.stringify(error, null, 2)}</pre>
              </div>
            </div>
          );
        }

        if (networkStatus !== 7) {
          return <Loading />;
        }

        return props.children(queryProps);
      }}
    </Query>
  );
};

export default queryWithLoading;
