import React, { useState } from 'react';
import { Table, TableHead, TableRow, TableCell, Paper, IconButton } from '@mui/material';
import { useQuery } from '@apollo/client';
import { GET_TERMINATION } from './reports.graphql';
import LoadingSpinner from '../components/LoadingSpinner';
import Grid from '@mui/material/Grid';
import { useStyles, TimeRange } from './churn';
import XLSX from 'xlsx';

export function TerminationReport() {
  const nowDate = new Date();
  const [from, setFrom] = useState<Date>(new Date(nowDate.getFullYear(), nowDate.getMonth(), 1));
  const [to, setTo] = useState<Date | null>(null);
  const classes = useStyles();
  const { loading, data } = useQuery<{ model: TerminationModel[] }>(GET_TERMINATION, { variables: { fromDate: from, toDate: to } });
  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item>
          <TimeRange from={from} setFrom={setFrom} to={to} setTo={setTo} />
        </Grid>
        <Grid item>{data && <TerminationExcelExport model={data.model} />}</Grid>
      </Grid>
      <Paper elevation={3}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Kunde</TableCell>
              <TableCell>Mottatt</TableCell>
              <TableCell>Årsak</TableCell>
              <TableCell>Abonnementer</TableCell>
            </TableRow>
          </TableHead>
          {data &&
            data.model.map((x) => {
              const arrLength = x.licenseSubscriptions.length;
              return (
                <TableRow key={x.id}>
                  <TableCell className={classes.fitwidth}>{x.licenseUserName}</TableCell>
                  <TableCell>{x.terminationRequestedDate}</TableCell>
                  <TableCell>{x.terminationReason}</TableCell>
                  <TableCell className={classes.fitwidth}>
                    {x.licenseSubscriptions.map((sub, i) => {
                      return arrLength === i + 1 ? sub : sub + ', ';
                    })}
                  </TableCell>
                </TableRow>
              );
            })}
        </Table>
        {loading && <LoadingSpinner />}
      </Paper>
    </>
  );
}

function TerminationExcelExport({ model }: { model: TerminationModel[] }) {
  const classes = useStyles();
  const exportFile = () => {
    const workbook = XLSX.utils.book_new();
    const headingForPending: string[] = ['Kunde', 'Oppsigelse mottatt', 'Årsak', 'Abonnementer'];
    const inputHeading = headingForPending;
    const workBookName = 'OPPSIGELSER';
    const data = model.map((x) => [x.licenseUserName, x.terminationRequestedDate, x.terminationReason, x.licenseSubscriptions]);
    const input = [inputHeading, ...data];
    const sheet = XLSX.utils.aoa_to_sheet(input);
    XLSX.utils.book_append_sheet(workbook, sheet, workBookName);
    XLSX.writeFile(workbook, 'churn.xlsx');
  };
  return (
    <IconButton onClick={exportFile} title="Eksport til Excel" size="large">
      <img src="/microsoft-excel.svg" className={classes.excelIcon} alt="Excel icon" />
    </IconButton>
  );
}

interface TerminationModel {
  licenseUserName: string;
  licenseSubscriptions: string[];
  terminationRequestedDate: string;
  terminationReason: string;
  id: number;
}
