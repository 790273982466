import React, { useState } from 'react';
import { Typography, Button } from '@mui/material';
import MainContentPanel from '../../components/MainContentPanel';
import { ChangeLog } from '../../components/ChangeLog';
import { useConfig } from '../../components/Configuration';
import gql from 'graphql-tag';

import LoadingSpinner from '../../components/LoadingSpinner';
import { Change } from '../../interfaces';
import { useQuery } from '@apollo/client';

interface Props {
  licenseId: number;
}

let query = gql`
  query ($id: Int!, $offset: Int, $limit: Int) {
    queryResult: licenseChangeLog(id: $id, offset: $offset, limit: $limit) {
      totalCount
      changes {
        id
        isExternal
        description
        createdAt
        createdBy
      }
    }
  }
`;

export function ChangeLogCard({ licenseId }: Props) {
  const config = useConfig();
  const [limit, setLimit] = useState(5);
  const pollIntervall = config ? config.defaultPollingInterval : 0;

  const { loading, data, refetch } = useQuery<{ queryResult: { totalCount: number; changes: Change[] } }>(query, {
    variables: { id: licenseId, limit },
    pollInterval: pollIntervall,
  });
  const moreToLoad = data && data.queryResult.changes && data.queryResult.changes.length < data.queryResult.totalCount;

  const doFetchMore = () => {
    if (!data) return;
    setLimit(limit + 5);
    refetch();
  };
  return (
    <MainContentPanel>
      <Typography variant="h5">Endringer</Typography>
      {(!data || loading) && <LoadingSpinner />}
      {data && <ChangeLog changes={data.queryResult.changes} />}
      {moreToLoad && (
        <Button disabled={loading} onClick={doFetchMore}>
          Last flere
        </Button>
      )}
    </MainContentPanel>
  );
}
