import React from 'react';
import Grid from '@mui/material/Grid';
import { SalesSummary } from './SalesSummary';
import { NewSubscriptionsSummary } from './NewSubscriptionsSummary';
import { SalesDistribution } from './SalesDistribution';
import { UserTrend } from './UserTrend';
import { UserCounts } from './UserCounts';
import { LicensesWithHighUsage } from './LicensesWithHighUsage';
import { UsersWithHighUsage } from './UsersWithHighUsage';
import { Trials } from './Trials';
import { LicensesWithPendingTermination } from './LicensesWithPendingTermination';
import { LockedLicenses } from './LockedLicenses';
import { RenewalWidget } from './RenewalWidget';
import { PendingMarkdowns } from './PendingMarkdowns';
import { PendingMarkdownBySubscription } from './PendingMarkdownBySubscription';
import { LicensesWithUninvoicedChanges } from './LicensesWithUninvoicedChanges';

class Overview extends React.PureComponent<any, any> {
  render() {
    return (
      <Grid container>
        <Grid item xs={4}>
          <UserCounts />
          <UserTrend />
          <NewSubscriptionsSummary />
          <Trials />
        </Grid>
        <Grid item xs={4}>
          <SalesSummary />
          <SalesDistribution />
          <RenewalWidget />
          <LicensesWithUninvoicedChanges />
        </Grid>
        <Grid item>
          <LicensesWithHighUsage />
          <UsersWithHighUsage />
          <LicensesWithPendingTermination />
          <PendingMarkdowns />
          <PendingMarkdownBySubscription />
          <LockedLicenses />
        </Grid>
      </Grid>
    );
  }
}

export default Overview;
