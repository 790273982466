import React from 'react';
import { Table, TableHead, TableRow, TableCell, Paper, Link } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';
import { Guide } from '../../interfaces';
import { SearchField } from '../../components/SearchField';
import CustomerTable from './CustomerTable';
import Fuse from 'fuse.js';
import { Counter } from '../../components/Counter';
interface Props {
  guides: Guide[];
}
interface State {
  numberOfItems: number;
  isGuideTableVisible: boolean;
  guideId: any;
  title: string;
  searchTerm: string;
}
export default class GuideTable extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      numberOfItems: 50,
      isGuideTableVisible: true,
      guideId: 0,
      title: '',
      searchTerm: '',
    };
  }
  loadMore() {
    this.setState({ numberOfItems: this.state.numberOfItems + 50 });
  }
  hasMore(): boolean {
    let { numberOfItems } = this.state;
    let { guides } = this.props;
    return guides.length > numberOfItems;
  }
  handleDocumentClick = (id, title) => {
    this.setState({ isGuideTableVisible: false, guideId: id, title: title });
  };
  handleBack = () => {
    this.setState({ isGuideTableVisible: true });
  };
  setSearchTerm = (searchTerm: string) => {
    this.setState({ searchTerm: searchTerm });
  };
  filterGuides(): Guide[] {
    let { searchTerm } = this.state;
    let guides = this.props.guides;
    var options = {
      shouldSort: false,
      tokenize: true,
      matchAllTokens: true,
      threshold: 0,
      location: 0,
      distance: 10,
      maxPatternLength: 32,
      minMatchCharLength: 3,
      keys: ['docName', 'title'],
    };
    if (!searchTerm) {
      return guides;
    } else {
      var fuse = new Fuse(guides, options);
      var filtered = fuse.search(searchTerm);
      return filtered;
    }
  }
  renderTable = (filteredGuides, numberOfItems) => {
    return (
      <>
        <Paper elevation={1}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nummer</TableCell>
                <TableCell>Anvisning tittel</TableCell>
              </TableRow>
            </TableHead>
            <InfiniteScroll element="tbody" loadMore={() => this.loadMore()} hasMore={this.hasMore()}>
              {filteredGuides.slice(0, numberOfItems).map((x) => {
                return (
                  <TableRow key={x.docId}>
                    <TableCell>
                      <Link component="button" onClick={() => this.handleDocumentClick(x.docId, x.title)}>
                        {x.docName}
                      </Link>
                    </TableCell>
                    <TableCell>{x.title}</TableCell>
                  </TableRow>
                );
              })}
            </InfiniteScroll>
          </Table>
        </Paper>
      </>
    );
  };
  renderSearchBar = (filteredGuides) => {
    return (
      <>
        <Paper elevation={1}>
          <div>
            <SearchField filter={this.setSearchTerm} />
          </div>
          <Counter list={filteredGuides} />
        </Paper>
      </>
    );
  };
  render() {
    let { numberOfItems, isGuideTableVisible } = this.state;
    let filteredGuides = this.filterGuides();
    if (isGuideTableVisible) {
      return (
        <>
          {this.renderSearchBar(filteredGuides)}
          {this.renderTable(filteredGuides, numberOfItems)}
        </>
      );
    } else {
      return <CustomerTable selectedGuide={this.state.title} handleBack={this.handleBack} guideId={this.state.guideId} />;
    }
  }
}
