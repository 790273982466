import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Switch, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close, Security } from '@mui/icons-material';
import { LicenseModel, LicenseSubscription } from '../../interfaces';
import { Alert } from '@mui/material';
import { GET_LICENSE } from './LicenseInfo.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_TWO_FACTOR_AUTHENTICATION } from './UsersInLicense.graphql';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialog: {
    width: 575,
  },
}));

export function SecuritySettings({ subscriptions }: { subscriptions: LicenseSubscription[] }) {
  const [open, setOpen] = useState(false);
  const licenseId = subscriptions.length > 0 ? subscriptions[0].licenseId : 0;
  const { data } = useQuery<{ license: { license: LicenseModel } }>(GET_LICENSE, { variables: { id: licenseId } });

  if (subscriptions.length === 0) return null;

  return (
    <>
      <IconButton onClick={() => setOpen(true)} title="Endre sikkerhetsinnstillinger" size="large">
        <Security />
      </IconButton>
      {data && data.license && <SecurityDialog license={data.license.license} open={open} close={() => setOpen(false)} />}
    </>
  );
}

function SecurityDialog({ license, open, close }: { license: LicenseModel; open: boolean; close() }) {
  const classes = useStyles();
  const [twoFactor, setTwoFactor] = useState(license.requireTwoFactorAuthentication);
  const [updateTwoFactor] = useMutation(UPDATE_TWO_FACTOR_AUTHENTICATION, { variables: { input: { id: license.id, required: twoFactor } } });
  const unchanged = twoFactor === license.requireTwoFactorAuthentication;
  const onClose = () => close();
  const onCommit = () => {
    updateTwoFactor();
    onClose();
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="dialog-title" open={open}>
      <DialogTitle id="dialog-title">
        Endre sikkerhetsinnstillinger
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} size="large">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <form>
          <FormControlLabel
            control={<Switch checked={twoFactor} onChange={() => setTwoFactor(!twoFactor)} name="2fa" />}
            label="To-faktor autentisering"
          />
          <Alert severity="info">
            <Typography variant="body2">
              Denne vil påvirke alle eksisterende brukere. Nye brukere på lisensen vil ikke bli påvirket. Da må man oppdatere innstillingene på nytt.
            </Typography>
          </Alert>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Avbryt
        </Button>
        <Button onClick={onCommit} disabled={unchanged} color="primary" variant="contained">
          Oppdater innstillinger
        </Button>
      </DialogActions>
    </Dialog>
  );
}
