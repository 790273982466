import gql from 'graphql-tag';

export const REMOVE_ATTACHMENT = gql`
  mutation RemoveAttachment($input: LicenseRemoveAttachmentInput!) {
    licenseRemoveAttachment(input: $input)
  }
`;

export const ADD_ATTACHMENT = gql`
  mutation AddAttachment($input: LicenseAddAttachmentInput!) {
    licenseAddAttachment(input: $input)
  }
`;

export let GET_STORAGE_DETAILS = gql`
  query ($id: Int!) {
    details: getAttachmentStorageDetails(id: $id) {
      url
    }
  }
`;
