import React from 'react';
import { IconButton, Avatar, Toolbar, AppBar, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import MenuIcon from '@mui/icons-material/Menu';
import { withUser, UserProps } from '../components/User';
import { withConfiguration } from '../components/Configuration';
import { Config } from '../config';

const drawerWidth = 240;

interface TopHeaderProps {
  handleDrawerToggle(): void;
}

interface InjectedProps {
  classes: any;
  config: Config;
  user: UserProps;
}

interface CombinedProps extends TopHeaderProps, InjectedProps {}

const styles = (theme: any) => ({
  flex: {
    flex: 1,
  },
  appBar: {
    position: 'absolute',
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  env: {
    marginRight: 100,
  },
  version: {
    marginRight: 15,
  },
});

@((withStyles as any)(styles))
class TopHeader extends React.PureComponent<TopHeaderProps, any> {
  get combined(): CombinedProps {
    return this.props as TopHeaderProps & InjectedProps;
  }

  render() {
    let { classes, user, config, handleDrawerToggle } = this.combined;

    const name = user.profile && user.profile.name ? user.profile.name : '';
    var initials = name
      .split(' ')
      .map((x) => x[0])
      .join('');
    return (
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle} className={classes.navIconHide} size="large">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.flex} noWrap>
            Salg
          </Typography>
          <EnvironmentDisplay className={classes.env} environment={config.env} />
          <Typography className={classes.version} variant="caption" color="inherit">
            v{config.version}
          </Typography>
          <Avatar>{initials}</Avatar>
        </Toolbar>
      </AppBar>
    );
  }
}

function EnvironmentDisplay({ environment, className }) {
  const EnvTypograpy = ({ children }) => (
    <Typography variant="h3" color="inherit" className={className}>
      {children}
    </Typography>
  );

  if (environment === 'Dev') return <EnvTypograpy>DEV</EnvTypograpy>;
  if (environment === 'test') return <EnvTypograpy>TEST</EnvTypograpy>;
  if (environment === 'staging') return <EnvTypograpy>BETA</EnvTypograpy>;

  return null;
}

export default withUser()(withConfiguration()(TopHeader));
