import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { ArrowUpward } from '@mui/icons-material';
import { UPGRADE_SUBSCRIPTION, UPGRADE_DIALOG_QUERY, GET_SUBSCRIPTIONS } from './UsersInLicense.graphql';
import { useMutation, useLazyQuery } from '@apollo/client';
import { Subscription, LicenseSubscription } from '../../interfaces';
import UpgradeSubscriptionDialog from './UpgradeSubscriptionDialog';
export function UpgradeSubscriptionButton({ access, parentSubscription }: { access: LicenseSubscription; parentSubscription: Subscription }) {
  const [open, setOpen] = useState(false);
  const [upgradeSubscription] = useMutation(UPGRADE_SUBSCRIPTION);
  const [getUpgradeData, { loading, data }] = useLazyQuery(UPGRADE_DIALOG_QUERY, {
    variables: { id: access.licenseId, subscriptionId: access.subscription.id },
    fetchPolicy: 'network-only',
  });
  if (open && !loading && !data) getUpgradeData();
  const submit = (numberOfAccesses: number) => {
    upgradeSubscription({
      variables: {
        input: {
          id: access.licenseId,
          fromSubscriptionId: access.subscription.id,
          toSubscriptionId: access.subscription.parentId,
          numberOfAccesses,
        },
      },
      update(cache) {
        const queryVariable = { id: access.licenseId };
        const result = cache.readQuery({ query: GET_SUBSCRIPTIONS, variables: queryVariable }) as any;

        cache.writeQuery({
          query: GET_SUBSCRIPTIONS,
          variables: queryVariable,
          data: {
            subscriptions: result.subscriptions
              .filter((x) => x.subscription.parentId !== parentSubscription.id)
              .concat({
                ...access,
                id: `${access.licenseId}:${parentSubscription.id}`,
                subscription: parentSubscription,
                numberOfUsers: numberOfAccesses,
              }),
            paidSubscriptions: result.paidSubscriptions,
            license: result.license,
          },
        });
      },
    });
    setOpen(false);
  };
  return (
    <>
      <IconButton onClick={() => setOpen(true)} title="Oppgradér" size="large">
        <ArrowUpward />
      </IconButton>
      {data && !loading && (
        <UpgradeSubscriptionDialog
          open={open}
          parentName={parentSubscription ? parentSubscription.name : 'Komplett'}
          message={data.upgradeDialog.message}
          accessCount={data.upgradeDialog.accessCount}
          sumFreeSeats={data.upgradeDialog.sumFreeSeats}
          maxFreeSeats={data.upgradeDialog.maxFreeSeats}
          onCancel={() => setOpen(false)}
          onSubmit={submit}
        />
      )}
    </>
  );
}
