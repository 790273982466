import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';

interface DialogProps {
  open: boolean;
  onClose(): void;
  update(value: string);
  title: string;
}

export function SetEmailDialog({ open, title, onClose, update }: DialogProps) {
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const disableCommit = error || value.length === 0;

  const handleEmailChange = () => {
    setError(validateEmail(value));
  };

  const handleOnClose = () => {
    setValue('');
    setError(false);
    onClose();
  };
  const commit = () => {
    update(value);
    handleOnClose();
  };

  return (
    <Dialog open={open} onClose={handleOnClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          label="E-post"
          fullWidth
          value={value}
          type="email"
          onChange={(ev) => setValue(ev.target.value)}
          onBlur={handleEmailChange}
          margin="normal"
          error={error}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>Avbryt</Button>
        <Button disabled={disableCommit} variant="contained" color="primary" onClick={commit}>
          Legg til
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function validateEmail(value: string): boolean {
  if (value.length === 0) {
    return false;
  }

  const regexp = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
  return value.split(',').some((x) => !regexp.test(x));
}
