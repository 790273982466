import React, { useState } from 'react';
import { Subscription } from '../../interfaces';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText, TextField } from '@mui/material';

export interface SubscriptionData {
  id: number;
  numberOfAccesses: number;
}

interface Props {
  open: boolean;
  availableSubscriptions: Subscription[];
  onCancel: () => void;
  onSubmit: (data: SubscriptionData[]) => void;
}

export default function AddSubscriptionDialog({ open, availableSubscriptions, onCancel, onSubmit }: Props) {
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<SubscriptionData[]>([]);

  const cancel = () => {
    setSelectedSubscriptions([]);
    onCancel();
  };

  const submit = () => {
    onSubmit(selectedSubscriptions);
    setSelectedSubscriptions([]);
  };

  return (
    <Dialog open={open} onClose={cancel} maxWidth="sm" fullWidth>
      <DialogTitle>Velg produkter</DialogTitle>
      <DialogContent>
        <Subscriptions
          availableSubscriptions={availableSubscriptions}
          selectedSubscriptions={selectedSubscriptions}
          setSelectedSubscriptions={setSelectedSubscriptions}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>Avbryt</Button>
        <Button variant="contained" color="primary" onClick={submit}>
          Lagre
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface SubscriptionProps {
  selectedSubscriptions: SubscriptionData[];
  setSelectedSubscriptions(input: SubscriptionData[]): void;
  availableSubscriptions: Subscription[];
}

function Subscriptions({ availableSubscriptions, selectedSubscriptions, setSelectedSubscriptions }: SubscriptionProps) {
  const getAccesses = (subscription: Subscription) => {
    const selected = selectedSubscriptions.find((ss) => ss.id === subscription.id);
    return selected ? selected.numberOfAccesses : '';
  };

  const updateAccesses = (subscription: Subscription, numberOfAccesses: number) => {
    const selected = selectedSubscriptions.find((ss) => ss.id === subscription.id);
    if (selected) {
      setSelectedSubscriptions(selectedSubscriptions.map((s) => (s.id === selected.id ? { id: s.id, numberOfAccesses } : s)));
    } else {
      setSelectedSubscriptions([...selectedSubscriptions, { id: subscription.id, numberOfAccesses }]);
    }
  };

  return (
    <List>
      {availableSubscriptions.map((s) => (
        <ListItem key={s.id}>
          <ListItemText>{s.name}</ListItemText>
          <TextField label="Antall" value={getAccesses(s)} onChange={(event) => updateAccesses(s, Number(event.target.value))} />
        </ListItem>
      ))}
    </List>
  );
}
