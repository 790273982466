import { useQuery } from '@apollo/client';
import { AdfsSetting } from '../../interfaces';
import LoadingSpinner from '../../components/LoadingSpinner';
import { Link } from 'react-router-dom';
import { Code, CardMembership, RecentActors } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { Paper, Grid } from '@mui/material';
import { GET_SINGLE_ADFS_BY_BKS_ID } from './ADFSConfig.graphql';

const useStyles = makeStyles((theme) => ({
  right: {
    textAlign: 'right',
  },
  link: {
    textDecoration: 'none' as 'none',
  },
  snackbarStyleViaNestedContent: {
    backgroundColor: 'lightgreen',
    color: 'black',
  },
}));

export default function ADFSSubSettings(props) {
  const classes = useStyles();
  let result = props.match.params.bksID;
  const bksID = result && parseInt(result);
  const { loading, data } = useQuery<{ adfs: AdfsSetting }>(GET_SINGLE_ADFS_BY_BKS_ID, { variables: { bksID }, fetchPolicy: 'no-cache' });
  if (loading || !data) return <LoadingSpinner />;
  return (
    <>
      <Paper elevation={1}>
        <Grid container justifyContent="center" spacing={1} direction="row" style={{ padding: 20 }}>
          <Grid item xs={12}>
            <Grid container spacing={1} direction="row" style={{ padding: 20 }}>
              <Grid item>
                <Link to={'/license/' + bksID}>
                  {' '}
                  <h3>{data && data.adfs && data.adfs.scheme}</h3>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} direction="row" style={{ padding: 20 }}>
              <Grid item>
                <Code htmlColor="#757575" />
              </Grid>
              <Grid item>
                <Link to={{ pathname: '/generalsetting/', state: data }} className={classes.link}>
                  <div>Generelle instillinger</div>
                </Link>
              </Grid>
              <Grid item style={{ marginLeft: 40 }}>
                <CardMembership htmlColor="#757575" />
              </Grid>
              <Grid item>
                <Link to={{ pathname: '/advancesetting/', state: data }} className={classes.link}>
                  <div>Avanserte instillinger</div>
                </Link>
              </Grid>
              <Grid item style={{ marginLeft: 60 }}>
                <RecentActors htmlColor="#757575" />
              </Grid>
              <Grid item>
                <Link to={{ pathname: '/authorization/', state: data }} className={classes.link}>
                  <div>Client Authorization</div>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
