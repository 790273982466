import React from 'react';
import { Paper } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },

    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
});

@((withStyles as any)(styles))
export default class WidgetPanel extends React.Component<any> {
  render() {
    return <Paper className={this.props.classes.root}>{this.props.children}</Paper>;
  }
}
