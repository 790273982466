import React from 'react';
import { Typography, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import MainContentPanel from '../../components/MainContentPanel';
import { useConfig } from '../../components/Configuration';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../../components/LoadingSpinner';
import { Mail } from '@mui/icons-material';
import { formatDate } from '../../Formatters';

interface Props {
  userId: number;
}

interface Notification {
  id: string;
  subject: string;
  sentAt: string;
}

let query = gql`
  query ($userId: Int!, $offset: Int, $limit: Int) {
    queryResult: getUserNotifications(userId: $userId, offset: $offset, limit: $limit) {
      totalCount
      notifications {
        id
        subject
        sentAt
      }
    }
  }
`;

export function Notifications({ userId }: Props) {
  const config = useConfig();
  const pollIntervall = config ? config.defaultPollingInterval : 0;
  const rootUrl = config ? config.apiUrl : '/';

  const { loading, data, fetchMore } = useQuery<{ queryResult: { totalCount: number; notifications: Notification[] } }>(query, {
    variables: { userId, offset: 0, limit: 5 },
    pollInterval: pollIntervall,
  });
  const moreToLoad = data && data.queryResult.notifications.length < data.queryResult.totalCount;

  const doFetchMore = () => {
    if (!data) return;
    fetchMore({
      variables: { offset: data.queryResult.notifications.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        if (!fetchMoreResult || !fetchMoreResult.queryResult.notifications || fetchMoreResult.queryResult.notifications.length === 0) {
          return prev;
        }
        return Object.assign({}, prev, {
          queryResult: {
            __typename: 'GetUserNotificationsModel',
            totalCount: prev.queryResult.totalCount,
            notifications: [...prev.queryResult.notifications, ...fetchMoreResult.queryResult.notifications],
          },
        });
      },
    });
  };
  return (
    <MainContentPanel>
      <Typography variant="h5">Genererte e-poster</Typography>
      {(!data || loading) && <LoadingSpinner />}
      {data && <NotificationList userId={userId} notifications={data.queryResult.notifications} rootUrl={rootUrl} />}
      {moreToLoad && (
        <Button disabled={loading} onClick={doFetchMore}>
          Last flere
        </Button>
      )}
    </MainContentPanel>
  );
}

function NotificationList({ userId, notifications, rootUrl }: { userId: number; notifications: Notification[]; rootUrl: string }) {
  if (!notifications) return null;

  return (
    <List>
      {notifications.map((x) => (
        <ListItem key={x.sentAt}>
          <ListItemIcon>
            <MailLink userId={userId} notificationId={x.id} rootUrl={rootUrl} />
          </ListItemIcon>
          <ListItemText primary={<span dangerouslySetInnerHTML={{ __html: x.subject }}></span>} secondary={`${formatDate(x.sentAt)}`} />
        </ListItem>
      ))}
    </List>
  );
}

function MailLink({ userId, notificationId, rootUrl }: { userId: number; notificationId: string; rootUrl: string }) {
  return (
    <a
      title="Åpne mail ny fane"
      href={rootUrl + '/notification/user/' + userId + '?notificationId=' + notificationId}
      target="_blank"
      rel="noreferrer"
    >
      <Mail />
    </a>
  );
}
