import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableBody,
  IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { RenewalInfo, ProductDefinition } from './Overview';
import { numberAsKr } from '../Formatters';
import { Moment } from 'moment';
import { Difference } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  positive: {
    color: theme.palette.primary.main,
  },
  negative: {
    color: theme.palette.error.main,
  },
}));

export function DiffNotification({
  renewals,
  notifications,
  products,
  date,
}: {
  renewals: RenewalInfo[];
  notifications: RenewalInfo[];
  products: ProductDefinition[];
  date: Moment;
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton color="secondary" onClick={(_) => setOpen(true)} title="Sammenlign med varsel">
        <Difference />
      </IconButton>
      {open && (
        <DiffNotificationDialog onClose={() => setOpen(false)} products={products} renewals={renewals} notifications={notifications} date={date} />
      )}
    </>
  );
}

function DiffNotificationDialog({
  renewals,
  notifications,
  products,
  onClose,
  date,
}: {
  renewals: RenewalInfo[];
  notifications: RenewalInfo[];
  products: ProductDefinition[];
  onClose(): void;
  date: Moment;
}) {
  const diffs = notifications.filter((x) => {
    const renewal = renewals.find((r) => r.licenseId === x.licenseId);
    return renewal === undefined || x.total !== renewal.total;
  });

  return (
    <Dialog open onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>Endringer fra da det ble sendt varsel om fornyelse ({date.format('MMMM YYYY')})</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Kunde</TableCell>
              <TableCell>Abonnement</TableCell>
              {products.map((p) => (
                <TableCell key={`HEADER-${p.name}`} align="right">
                  {p.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {diffs.map((notification) => {
              const renewal = renewals.find((x) => x.licenseId === notification.licenseId);
              const isRemoved = renewal === undefined;
              return (
                <TableRow key={notification.licenseId}>
                  <TableCell>
                    <Link to={'/license/' + notification.licenseId}>{notification.name}</Link>
                  </TableCell>
                  <TableCell>{notification!.subscriptionOrderNumber}</TableCell>
                  {products.map((p) => (
                    <TableCell key={`${notification.id}${p.name}`} align="right">
                      <DiffDisplayer a={p.selector(notification)} b={isRemoved ? undefined : p.selector(renewal!)} />
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Lukk</Button>
      </DialogActions>
    </Dialog>
  );
}

function DiffDisplayer({ a, b }: { a: number | undefined; b: number | undefined }) {
  const classes = useStyles();

  if (a === undefined && b === undefined) return null;

  const numberA = a !== undefined ? (a as number) : 0;
  const numberB = b !== undefined ? (b as number) : 0;
  if (numberA === numberB) return <span>{numberAsKr(numberA)}</span>;

  return (
    <>
      <span className={classes.negative} style={{ textDecoration: 'line-through' }}>
        {toKr(numberA)}
      </span>
      <span className={classes.positive}>{toKr(numberB)}</span>
    </>
  );
}

function toKr(val: number): string {
  return val.toLocaleString('nb-NO', { style: 'currency', currency: 'NOK', minimumFractionDigits: 2 });
}
