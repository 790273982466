import { UserManager, User, Log } from 'oidc-client';
import getConfig from '../config';
import state from '../state';

var userStoreKey = '';

const getUserManager = async () => {
  const config = await getConfig();
  const authority = config.authorityUrl + '/';
  const client = 'SalgClient';
  userStoreKey = `oidc.user:${authority}:${client}`;
  return new UserManager({
    authority: authority,
    client_id: 'SalgClient',
    acr_values: 'idp:' + config.idp,
    redirect_uri: config.homeUrl,
    post_logout_redirect_uri: config.homeUrl,
    response_type: 'id_token token',
    scope: 'openid profile email Salg',
    filterProtocolClaims: true,
    automaticSilentRenew: true,
    accessTokenExpiringNotificationTime: 90,
    silent_redirect_uri: `${config.homeUrl}/silent-renew.html`,
  });
};

export const getToken = () => {
  const item = sessionStorage.getItem(userStoreKey);
  if (item === null) return null;

  return JSON.parse(item).access_token;
};

export const refreshToken = async () => {
  Log.warn('Expired token - performing user login');
  var userManager = await getUserManager();
  userManager.removeUser();
  await login();
};

export const login = async () => {
  const userManager = await getUserManager();
  const user: User | null = await userManager.getUser();

  if (user) {
    state.userProfile = user.profile;
  } else {
    try {
      const signedInUser = await userManager.signinRedirectCallback();
      window.location.href = window.location.href.split('#')[0];
      state.userProfile = signedInUser.profile;
    } catch (error) {
      await userManager.signinRedirect({ redirect_uri: window.location.href });
    }
  }
};
