import React from 'react';
import { Business } from '@mui/icons-material';
import { NumberWidget } from './NumberWidget';
import { Link } from 'react-router-dom';

var query = `
    query { 
        number: licensesWithPendingTermination
    }`;

export class LicensesWithPendingTermination extends React.PureComponent<any, any> {
  render() {
    return (
      <NumberWidget heading="Kunder under oppsigelse" query={query} icon={<Business fontSize="large" />}>
        <Link to="/license?filter=pendingTermination">Se kundene</Link>
      </NumberWidget>
    );
  }
}
