import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { OrderSearchableTable } from './OrderSearchableTable';
import { GET_ORDERS } from './Orders.graphql';
import MainContentPanel from '../../components/MainContentPanel';
import LoadingSpinner from '../../components/LoadingSpinner';
import { Order } from '../../interfaces';
import { useConfig } from '../../components/Configuration';
import { useQuery } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  fetchMore: {
    textAlign: 'center',
    marginTop: theme.spacing(),
  },
}));

export default function Overview() {
  const config = useConfig();
  const classes = useStyles();
  const pollIntervall = config ? config.defaultPollingInterval : 0;
  const [showIncomplete, setShowIncomplete] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const { data, loading } = useQuery<{ orders: Order[] }>(GET_ORDERS, {
    variables: { showIncomplete, limit: showAll ? null : 100 },
    pollInterval: pollIntervall,
  });
  return (
    <MainContentPanel>
      <Typography gutterBottom variant="h5">
        Ordre
      </Typography>
      {loading && <LoadingSpinner />}
      {data && <OrderSearchableTable orders={data.orders} showIncomplete={showIncomplete} setShowIncomplete={setShowIncomplete} />}
      {data && !showAll && (
        <div className={classes.fetchMore}>
          <Button onClick={(_) => setShowAll(true)}>Hent resten</Button>
        </div>
      )}
    </MainContentPanel>
  );
}
