import React from 'react';
import { LocationCity } from '@mui/icons-material';
import { ListItemWithEdit } from '../../components/ListItemWithEdit';

interface Props {
  companyName: string;
  update?(companyName: string): void;
}

export class ListItemCompanyName extends React.PureComponent<Props> {
  render() {
    let { companyName, update } = this.props;
    let icon = <LocationCity />;
    return <ListItemWithEdit icon={icon} value={companyName} title="Endre navn" label="Navn" update={update} />;
  }
}
