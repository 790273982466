import React from 'react';
import { Paper } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },

    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
});

@((withStyles as any)(styles))
export default class MainContentPanel extends React.Component<any> {
  render() {
    return (
      <Paper className={this.props.classes.root}>
        <ErrorBoundary>{this.props.children}</ErrorBoundary>
      </Paper>
    );
  }
}

interface EBState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<{}, EBState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // TODO log?
  }

  render() {
    if (this.state.hasError) {
      return <h1>Oi sann! Noe gikk galt</h1>;
    }

    return this.props.children;
  }
}
