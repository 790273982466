import { TextField } from '@mui/material';
import { useState } from 'react';
import { AdfsSetting } from '../../interfaces';
interface Prop {
  callbackValue: (name: string) => void;
  callbackError: (error: boolean) => void;
  result: AdfsSetting[] | undefined;
  existingName: string;
}
export default function CallbackPath(prop: Prop) {
  const { callbackValue: sendbackPath, result, callbackError: sendbackError, existingName } = prop;
  const [duplicateCallbackPathErrorText, setDuplicateCallbackPathErrorText] = useState('');
  const [invalidCallbackPathErrorText, setInvalidCallbackPathErrorText] = useState('');
  const [callbackPath, setCallbackPath] = useState(existingName.replace(/\//g, ''));
  const handleCallbackPathChange = (name: string) => {
    clearDuplicateError();
    clearInvalidError();
    setCallbackPath(name);
  };
  const setDuplicateError = () => {
    setDuplicateCallbackPathErrorText('duplicate value');
    sendbackError(true);
  };
  const clearDuplicateError = () => {
    setDuplicateCallbackPathErrorText('');
    sendbackError(false);
  };
  const setInvalidError = () => {
    setInvalidCallbackPathErrorText('Only - is a valid special character in callbackpath');
    sendbackError(true);
  };
  const clearInvalidError = () => {
    setInvalidCallbackPathErrorText('');
    sendbackError(false);
  };
  const isCallbackPathValid = (path: string) => {
    let result = path.match(/[^A-Za-z-]/g);
    return result ? false : true;
  };
  const checkDuplicateCallback = (name: string) => {
    let cleanArray = washCallbackArr(result);
    var isDuplicate =
      cleanArray &&
      cleanArray.find((obj) => {
        return obj.toLowerCase() === name.toLowerCase();
      });
    return isDuplicate ? true : false;
  };
  const specialCharacters = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
  const removeSpecialCharacter = (providerName: string) => {
    let result = providerName && providerName.replace(/\s/g, '');
    result = result.replace(specialCharacters, '');
    return result;
  };
  const checkDuplicateCallbackWhileUpdate = (name: string) => {
    let inputValue = removeSpecialCharacter(name);
    const cleanExistingName = existingName && removeSpecialCharacter(existingName);
    if (inputValue !== cleanExistingName) {
      return checkDuplicateCallback(name) ? true : false;
    } else {
      return false;
    }
  };
  const washCallbackArr = (array: AdfsSetting[] | undefined) => {
    return array && array.map((x) => x.callbackPath.replace(/\s+|[/]/g, ''));
  };
  const handleCallbackPathBlur = (name: string) => {
    if (!isCallbackPathValid(name)) {
      setInvalidError();
    } else {
      if (existingName) {
        checkDuplicateCallbackWhileUpdate(name) ? setDuplicateError() : sendbackPath(name);
      } else {
        checkDuplicateCallback(name && name.replace(/\s/g, '')) ? setDuplicateError() : sendbackPath(name);
      }
    }
  };
  return (
    <>
      <TextField
        label="Callback Path"
        error={duplicateCallbackPathErrorText.length === 0 && invalidCallbackPathErrorText.length === 0 ? false : true}
        helperText={invalidCallbackPathErrorText.length === 0 ? duplicateCallbackPathErrorText : invalidCallbackPathErrorText}
        onBlur={(event) => handleCallbackPathBlur(event.target.value)}
        onChange={(event) => handleCallbackPathChange(event.target.value)}
        fullWidth
        value={callbackPath}
        type="text"
        margin="normal"
        required
      />
    </>
  );
}
