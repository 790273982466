import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Box,
  MenuItem,
  Grid,
} from '@mui/material';
import moment from 'moment';
import { MCustomer } from '../../interfaces';

import { customerSearch } from './Maconomy.graphql';
import Loading from '../../components/LoadingSpinner';
import { useLazyQuery } from '@apollo/client';

interface Props {
  open: boolean;
  onCancel: () => void;
  onSubmit: (customer: MCustomer) => void;
  orgNumber?: string;
}

export default function SearchMaconomyCustomerDialog({ onCancel, onSubmit, open }: Props) {
  const [name, setName] = useState('');
  const [orgNumber, setOrgNumber] = useState('');
  const [customerNumber, setCustomerNumber] = useState('');
  const [hasSearched, setHasSearched] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<MCustomer | undefined>(undefined);
  const [invoiceMonth, setInvoiceMonth] = useState<number>(moment().month() + 1);
  const [invoiceYear, setInvoiceYear] = useState<number>(moment().year() + 1);

  const resetState = () => {
    setName('');
    setOrgNumber('');
    setCustomerNumber('');
    setSelectedCustomer(undefined);
    setHasSearched(false);
    setInvoiceMonth(moment().month() + 1);
    setInvoiceYear(moment().year() + 1);
  };
  const cancel = () => {
    onCancel();
    resetState();
  };

  const getSelectedCustomer = (): MCustomer | undefined => {
    if (selectedCustomer) return selectedCustomer;
    return data && data.customers && data.customers.length === 1 ? data.customers[0] : undefined;
  };

  const submit = () => {
    var customer = getSelectedCustomer();
    if (!customer) return;
    onSubmit({ ...customer, invoiceMonth, invoiceYear });
    resetState();
  };

  const [getCustomers, { loading, data }] = useLazyQuery<{ customers: MCustomer[] }>(customerSearch);
  const search = () => {
    setHasSearched(true);
    const padField = (field) => (field !== '' ? `%${field}%` : '');
    getCustomers({ variables: { name: padField(name), orgNumber: padField(orgNumber), customerNumber: padField(customerNumber) } });
  };

  const catchReturn = (ev) => {
    if (ev.key === 'Enter') {
      search();
      ev.preventDefault();
    }
  };

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="sm" fullWidth>
      <DialogTitle>Finn kunde i Maconomy</DialogTitle>
      <DialogContent>
        <TextField label="Navn" value={name} onChange={(e) => setName(e.target.value)} onKeyPress={catchReturn} fullWidth />
        <TextField label="Org.nr." value={orgNumber} onChange={(e) => setOrgNumber(e.target.value)} onKeyPress={catchReturn} fullWidth />
        <TextField
          label="Kundenummer i Maconomy"
          value={customerNumber}
          onChange={(e) => setCustomerNumber(e.target.value)}
          onKeyPress={catchReturn}
          fullWidth
        />

        <Box py={2}>
          <Button variant="contained" color="secondary" onClick={search}>
            Søk
          </Button>
        </Box>
        <Divider />
        {loading ? (
          <Loading size={50} />
        ) : (
          <List>
            {data && data.customers && hasSearched ? (
              data.customers.map((c) => (
                <ListItem
                  key={c.customerNumber}
                  button
                  selected={selectedCustomer && selectedCustomer.customerNumber === c.customerNumber}
                  onClick={() => setSelectedCustomer(c)}
                >
                  <ListItemText primary={c.companyName} secondary={c.orgNumber} />
                </ListItem>
              ))
            ) : (
              <Typography>Ingen funnet</Typography>
            )}
          </List>
        )}
        <Grid container>
          <Grid item xs={6}>
            <TextField
              label="Faktureringsmåned"
              select
              value={invoiceMonth}
              onChange={(e) => setInvoiceMonth(parseInt(e.target.value))}
              margin="normal"
              fullWidth
            >
              {moment.months().map((month, i) => (
                <MenuItem key={i + 1} value={i + 1}>
                  {month}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Faktureringsår"
              select
              value={invoiceYear}
              onChange={(e) => setInvoiceYear(parseInt(e.target.value))}
              margin="normal"
              fullWidth
            >
              <MenuItem key={moment().year()} value={moment().year()}>
                {moment().year()}
              </MenuItem>
              <MenuItem key={moment().year() + 1} value={moment().year() + 1}>
                {moment().year() + 1}
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>Avbryt</Button>
        <Button disabled={!getSelectedCustomer()} variant="contained" color="primary" onClick={submit}>
          Koble til kunde
        </Button>
      </DialogActions>
    </Dialog>
  );
}
