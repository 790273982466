import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from '@mui/material';
import { ListItemAddress } from '../../components/ListItemAddress';
import { ListItemPhoneNumber } from '../../components/ListItemPhoneNumber';
import { ListItemEmail } from '../../components/ListItemEmail';
import { ListItemName } from './ListItemName';
import { User, Address } from '../../interfaces';
import { AccountBox, Edit } from '@mui/icons-material';
import { validateEmail } from '../../components/SetEmailDialog';

interface Props {
  user: User;
  onEditLoginId?(loginId: string): void;
  onEditName?(firstName: string, lastName: string): void;
  onEditAddress?(address: Address): void;
  onEditPhone?(phone: string): void;
  onEditEmail?(email: string): void;
  disableLink?: boolean;
}

export function UserCard({ user, onEditLoginId, onEditAddress, onEditPhone, onEditEmail, onEditName, disableLink }: Props) {
  if (user === null) return <Typography>Ingen administrator</Typography>;
  return (
    <>
      <List>
        {(user.loginId !== user.email || onEditLoginId) && <ListItemLoginId value={user.loginId} update={onEditLoginId} />}
        <ListItemName
          userId={user.id}
          firstName={user.firstName}
          lastName={user.lastName}
          displayName={user.displayName}
          update={onEditName}
          disableLink={disableLink}
        />
        <ListItemPhoneNumber phone={user.phone} update={onEditPhone} />
        <ListItemEmail email={user.email} update={onEditEmail} />
        <ListItemAddress address={user.address} update={onEditAddress} />
      </List>
    </>
  );
}

function ListItemLoginId({ value, update }: { value: string; update: any }) {
  const [open, setOpen] = useState(false);

  return (
    <ListItem>
      <ListItemIcon title="Brukernavn">
        <AccountBox />
      </ListItemIcon>
      <ListItemText>{value}</ListItemText>
      {update && (
        <>
          <ListItemSecondaryAction>
            <IconButton title="Endre brukernavn" onClick={() => setOpen(true)} size="large">
              <Edit />
            </IconButton>
          </ListItemSecondaryAction>
          <UpdateLoginDialog value={value} open={open} onClose={() => setOpen(false)} update={update} />
        </>
      )}
    </ListItem>
  );
}

function UpdateLoginDialog({ open, onClose, update, value }) {
  const [newValue, setNewValue] = useState(value);
  const [error, setError] = useState(false);
  const disableCommit = error || value.length === 0 || value === newValue;

  const handleEmailChange = () => {
    setError(validateEmail(newValue));
  };

  const abort = () => {
    setNewValue(value);
    onClose();
  };
  const commit = () => {
    update(newValue);
    onClose();
  };
  const catchReturn = (ev) => {
    if (ev.key === 'Enter') {
      commit();
      ev.preventDefault();
    }
  };

  return (
    <Dialog open={open} onClose={abort}>
      <DialogTitle>Endre brukernavn</DialogTitle>
      <DialogContent>
        <form>
          <TextField
            autoFocus
            type="email"
            label="Brukernavn"
            fullWidth
            error={error}
            value={newValue ? newValue : ''}
            onBlur={handleEmailChange}
            onChange={(ev) => setNewValue(ev.target.value)}
            margin="normal"
            onKeyPress={catchReturn}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={abort}>Avbryt</Button>
        <Button disabled={disableCommit} variant="contained" color="primary" onClick={commit}>
          Endre
        </Button>
      </DialogActions>
    </Dialog>
  );
}
