import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  DialogContentText,
  TextField,
  FormGroup,
  Grid,
  InputLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import { CREATE_LICENSE, GET_LICENSES } from './License.graphql';
import { validateEmail } from '../../components/SetEmailDialog';
import Loading from '../../components/LoadingSpinner';
import { License, MCustomer } from '../../interfaces';
import { CountrySelect } from '../../components/UpdateAddressDialog';
import { GET_DISCOUNTS } from '../../discounts/discount.graphql';
import Select from 'react-select';
import { LicenseOverviewModel } from './ColumnDefinitions';
import { useMutation, useQuery } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  discount: {
    marginTop: theme.spacing(2),
  },
  discountLabel: {
    marginBottom: 3,
  },
}));

const selectStyles = {
  menu: (base) => ({
    ...base,
    zIndex: 100,
  }),
};

interface Option {
  value: string;
  label: string;
}

interface Props {
  open: boolean;
  customer?: MCustomer;
  onClose: () => void;
}
export default function AddLicenseDialog({ open, onClose, customer }: Props) {
  const classes = useStyles();

  const getEmptyCustomer = () => ({
    customerNumber: '',
    companyName: '',
    orgNumber: '',
    phone: '',
    email: '',
    address: {
      id: '',
      address1: '',
      address2: '',
      postalCode: '',
      city: '',
      country: 'Norge',
    },
    invoiceRef: '',
  });

  const [customerData, setCustomerData] = useState<MCustomer>(getEmptyCustomer());
  const [discountIds, setDiscountIds] = useState<string[]>([]);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [contactEmail, setContactEmail] = useState('');
  const [invalidContactEmail, setInvalidContactEmail] = useState(false);
  const [doValidate, setDoValidate] = useState(false);

  if (!customerData.customerNumber && customer) setCustomerData(customer);

  const clearState = () => {
    setCustomerData(getEmptyCustomer());
    setInvalidEmail(false);
    setContactEmail('');
    setInvalidContactEmail(false);
    setDoValidate(false);
    setDiscountIds([]);
  };

  const handleClose = () => {
    clearState();
    onClose();
  };

  const [create, { called, loading, data }] = useMutation(CREATE_LICENSE, {
    update(cache, { data: { createLicense } }) {
      try {
        const { licenses } = cache.readQuery({ query: GET_LICENSES, variables: { onlyActive: true } }) as any;
        const bksLicense: License = createLicense.bksLicense;
        const newItem: LicenseOverviewModel = {
          id: bksLicense.id,
          companyName: bksLicense.companyName,
          orgNumber: bksLicense.orgNumber,
          customerNumber: bksLicense.customerType,
          administrator: bksLicense.contact.email,
          createdAt: bksLicense.createdAt,
          isDeleted: false,
          isGodkjentVaatromsbedrift: null,
        };
        cache.writeQuery({
          query: GET_LICENSES,
          variables: { onlyActive: true },
          data: { licenses: licenses.concat([newItem]) },
        });
      } catch {
        console.log('Unable to update licenses cache.');
      }
    },
  });
  const handleAddLicense = () => {
    if (!contactEmail || invalidContactEmail || invalidEmail) {
      setDoValidate(true);
      return;
    }

    create({
      variables: {
        input: {
          customerNumber: customerData.customerNumber,
          companyName: customerData.companyName,
          orgNumber: customerData.orgNumber,
          phone: customerData.phone,
          email: customerData.email,
          address1: customerData.address.address1,
          address2: customerData.address.address2,
          postalCode: customerData.address.postalCode,
          city: customerData.address.city,
          country: customerData.address.country,
          contactEmail,
          invoiceMonth: customerData.invoiceMonth,
          invoiceRef: customerData.invoiceRef,
          discountIds,
        },
      },
    });
  };

  if (called && !loading && data) return <Redirect to={'/license/' + data.createLicense.bksLicense.id} />;

  const handleChange = (propertyName: string) => (event) => {
    setCustomerData({ ...customerData, [propertyName]: event.target.value } as MCustomer);
  };

  const handleAddressChange = (propertyName: string) => (event) => {
    setCustomerData({ ...customerData, address: { ...customerData.address, [propertyName]: event.target.value } } as MCustomer);
  };

  return (
    <Dialog fullWidth maxWidth="md" onClose={handleClose} open={open}>
      <DialogTitle>
        Legg til ny kunde
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose} size="large">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>Oppdatér firmainformasjon, abonnement og e-post til den som skal være administrator.</DialogContentText>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <FormGroup>
              <TextField
                label="E-post til administrator"
                fullWidth
                value={contactEmail}
                type="email"
                onChange={(ev) => setContactEmail(ev.target.value)}
                onBlur={() => setInvalidContactEmail(validateEmail(contactEmail))}
                error={invalidContactEmail || (doValidate && !contactEmail)}
                helperText={(invalidContactEmail && 'Ugyldig e-postadresse') || (doValidate && !contactEmail && 'Feltet er påkrevd')}
                margin="normal"
                autoFocus
                required
              />
            </FormGroup>
            <FormGroup>
              <TextField
                label="Firmanavn"
                fullWidth
                value={customerData.companyName}
                type="text"
                onChange={handleChange('companyName')}
                margin="normal"
              />
            </FormGroup>
            <FormGroup>
              <TextField
                label="Org.nummer"
                fullWidth
                value={customerData.orgNumber}
                type="text"
                onChange={handleChange('orgNumber')}
                margin="normal"
              />
            </FormGroup>
            <FormGroup>
              <TextField
                label="E-post"
                fullWidth
                value={customerData.email}
                type="email"
                onChange={handleChange('email')}
                onBlur={() => setInvalidEmail(validateEmail(customerData.email))}
                margin="normal"
                error={invalidEmail}
                helperText={invalidEmail && 'Ugyldig e-postadresse'}
              />
            </FormGroup>
            <FormGroup>
              <TextField label="Telefonnummer" fullWidth value={customerData.phone} type="phone" onChange={handleChange('phone')} margin="normal" />
            </FormGroup>
            <FormGroup>
              <TextField
                label="Faktura ref"
                fullWidth
                value={customerData.invoiceRef}
                type="text"
                onChange={handleChange('invoiceRef')}
                margin="normal"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <FormGroup className={classes.discount}>
              <DiscountPicker discountIds={discountIds} setDiscountIds={setDiscountIds} />
            </FormGroup>
            <FormGroup>
              <TextField
                label="Addresse 1"
                fullWidth
                value={customerData.address.address1}
                type="text"
                onChange={handleAddressChange('address1')}
                margin="normal"
              />
            </FormGroup>
            <FormGroup>
              <TextField
                label="Addresse 2"
                fullWidth
                value={customerData.address.address2}
                type="text"
                onChange={handleAddressChange('address2')}
                margin="normal"
              />
            </FormGroup>
            <FormGroup>
              <TextField
                label="Postnummer"
                fullWidth
                value={customerData.address.postalCode}
                type="text"
                onChange={handleAddressChange('postalCode')}
                margin="normal"
              />
            </FormGroup>
            <FormGroup>
              <TextField
                label="Poststed"
                fullWidth
                value={customerData.address.city}
                type="text"
                onChange={handleAddressChange('city')}
                margin="normal"
              />
            </FormGroup>
            <FormGroup>
              <CountrySelect label="Land" fullWidth value={customerData.address.country} onChange={handleAddressChange('country')} />
            </FormGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Lukk</Button>
        <Button color="primary" variant="contained" onClick={() => handleAddLicense()} disabled={called}>
          {!loading && 'Legg til'}
          {loading && <Loading size={20} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DiscountPicker({ discountIds, setDiscountIds }: { discountIds: string[]; setDiscountIds(ids: string[]): void }) {
  const classes = useStyles();
  const discountsResult = useQuery(GET_DISCOUNTS, { variables: { id: 1 } });

  let discountOptions: Option[] = [];
  if (discountsResult.loading) discountOptions = [{ value: '', label: 'Laster...' }];
  else if (discountsResult.error) discountOptions = [{ value: '', label: 'Feil oppsto: ' + discountsResult.error.message }];
  else if (discountsResult.data && discountsResult.data.discounts)
    discountOptions = discountsResult.data.discounts.map((s) => ({ value: s.id, label: s.name }));

  return (
    <>
      <InputLabel className={classes.discountLabel} htmlFor="discount-input">
        Rabatt
      </InputLabel>
      <Select
        styles={selectStyles}
        id="discount-input"
        placeholder="Velg rabatter"
        isMulti
        options={discountOptions.filter((x) => !discountIds.some((d) => d === x.value))}
        value={discountOptions.filter((x) => discountIds.some((d) => d === x.value))}
        onChange={(values) => setDiscountIds(values.map((v) => v.value))}
      />
    </>
  );
}
