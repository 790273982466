import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Switch, FormControlLabel, List, Stack, TextField, Tooltip, Button, Typography } from '@mui/material';
import { UserData } from '../../interfaces';
import { Delete } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import DeleteUsers from './DeleteUsers';
import {
  GridColDef,
  GridFilterModel,
  GridRowParams,
  GridToolbar,
  GridValidRowModel,
  nbNO,
  GridLogicOperator,
  DataGridPro,
  useGridApiRef,
  GridEditDateCell,
  GridColTypeDef,
  GRID_DATE_COL_DEF,
  getGridStringOperators,
} from '@mui/x-data-grid-pro';
import { formatDateOnly } from '../../Formatters';
import { GET_USERS } from '../user.graphql';
import { useQuery } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  action: {
    marginRight: 15,
    margin: 1,
  },
  button: {
    marginRight: 15,
    marginLeft: 15,
    margin: 1,
  },
}));

function loadBoolean(key, defaultValue): boolean {
  const item = sessionStorage.getItem(key);
  const value = item != null ? item === 'true' : defaultValue;
  return value;
}

function loadNumber(key, defaultValue): number {
  const item = sessionStorage.getItem(key);
  const value = item != null ? parseInt(item) : defaultValue;
  return value;
}

export function UserSearchableTable() {
  const apiRef = useGridApiRef();

  const classes = useStyles();

  const [selected, setSelected] = React.useState<string[]>([]);

  const [openConfirm, setOpenConfirm] = useState(false);

  const [onlyActive, setOnlyActive] = useState(() => loadBoolean('onlyActive', true));
  const [activeSingleDocument, setActiveSingleDocument] = useState(() => loadBoolean('activeSingleDocument', false));

  const [nonActiveSingleDocument, setNonActiveSingleDocument] = useState(() => loadBoolean('nonActiveSingleDocument', false));
  const [nonActiveForNumberYears, setNonActiveForNumberYears] = useState(() => loadNumber('nonActiveForNumberYears', 5));

  const [nonActiveNumberYears, setNonActiveNumberYears] = React.useState<string>(nonActiveForNumberYears.toString());

  useEffect(() => {
    sessionStorage.setItem('onlyActive', onlyActive.toString());
    sessionStorage.setItem('activeSingleDocument', activeSingleDocument.toString());
    sessionStorage.setItem('nonActiveSingleDocument', nonActiveSingleDocument.toString());
    sessionStorage.setItem('nonActiveForNumberYears', nonActiveForNumberYears.toString());
  }, [onlyActive, activeSingleDocument, nonActiveSingleDocument, nonActiveForNumberYears]);

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  const [filter, setFilter] = useState('');

  const pageSize = paginationModel.pageSize;
  const page = paginationModel.page;

  const { loading, data, error, refetch } = useQuery<{ users: UserData }>(GET_USERS, {
    variables: {
      page,
      pageSize,
      onlyActive,
      activeSingleDocument,
      nonActiveSingleDocument,
      nonActiveForNumberYears,
      filter,
    },
    fetchPolicy: 'no-cache',
  });

  const isLoading = loading || !data;

  const rowCount = data?.users.count ?? 0;

  const users = data?.users.users ?? [];

  const [rowCountState, setRowCountState] = React.useState(rowCount);
  React.useEffect(() => {
    setRowCountState((prevRowCountState) => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  const dateColumnType: GridColTypeDef<Date, string> = {
    ...GRID_DATE_COL_DEF,
    resizable: false,
    renderEditCell: (params) => {
      return <GridEditDateCell {...params} />;
    },
    valueFormatter: (params) => {
      if (params.value) {
        return formatDateOnly(params.value);
      }
      return '';
    },
  };

  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ['contains', 'equals', 'startsWith', 'endsWith', 'isEmpty', 'isNotEmpty'].includes(value),
  );

  const columns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: 'Navn',
      width: 180,
      filterOperators: filterOperators,
      renderCell: (params) => {
        return <Link to={`/user/${params.id}`}>{params.value}</Link>;
      },
    },
    {
      field: 'email',
      headerName: 'E-post',
      width: 180,
      filterOperators: filterOperators,
    },
    {
      field: 'lastLoggedIn',
      ...dateColumnType,
      headerName: 'Sist innlogget',
      width: 180,
    },
    {
      field: 'loginId',
      headerName: 'Brukernavn',
      width: 180,
      filterOperators: filterOperators,
    },
  ];

  const rows: GridValidRowModel[] = users.map((x) => {
    return {
      id: x.id,
      isDeletable: !x.isAdminuserOnActiveLicense,
      displayName: x.displayName,
      email: x.email,
      lastLoggedIn: x.lastLoggedIn,
      loginId: x.loginId,
    };
  });

  const onFilterChange = React.useCallback(
    (filterModel: GridFilterModel) => {
      var queryOptionsJson = JSON.stringify({ ...filterModel });
      if (queryOptionsJson) setFilter(queryOptionsJson);
    },
    [setFilter],
  );

  const removeFromList = React.useCallback(() => refetch(), [refetch]);

  return (
    <>
      <List component={Stack} direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <Tooltip title="Brukere med lisens på en serie.">
            <FormControlLabel
              control={
                <Switch
                  onChange={() => {
                    setNonActiveSingleDocument(false);
                    return setOnlyActive(!onlyActive);
                  }}
                  checked={onlyActive}
                />
              }
              label="Aktive"
            />
          </Tooltip>

          <Tooltip title="Brukere med lisens en eller flere enkeltanvisninger.">
            <FormControlLabel
              control={
                <Switch
                  onChange={() => {
                    setNonActiveSingleDocument(false);
                    return setActiveSingleDocument(!activeSingleDocument);
                  }}
                  checked={activeSingleDocument}
                />
              }
              label="Aktive Single"
            />
          </Tooltip>

          <Tooltip title="Brukere ikke tilknyttet lisens.">
            <FormControlLabel
              control={
                <Switch
                  onChange={() => {
                    setOnlyActive(false);
                    setActiveSingleDocument(false);
                    return setNonActiveSingleDocument(!nonActiveSingleDocument);
                  }}
                  checked={nonActiveSingleDocument}
                />
              }
              label="Ikke aktive."
            />
          </Tooltip>

          <Tooltip title="Slett merkede brukere. Brukere som administrerer lisenser kan ikke slettes.">
            <Button
              disabled={selected.length === 0}
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={() => setOpenConfirm(true)}
              startIcon={<Delete />}>
              Slett
            </Button>
          </Tooltip>

          {openConfirm && (
            <DeleteUsers
              open={openConfirm}
              onClose={() => setOpenConfirm(false)}
              userId={selected.map((c) => Number(c))}
              onlyActive={onlyActive}
              activeSingleDocument={activeSingleDocument}
              nonActiveSingleDocument={nonActiveSingleDocument}
              nonActiveForNumberYears={nonActiveForNumberYears}
              setSelected={setSelected}
              users={users}
              removeFromList={removeFromList}
            />
          )}

          {nonActiveSingleDocument && (
            <>
              <Tooltip title="År siden siste innlogging.">
                <TextField
                  type="text"
                  style={{ width: 75 }}
                  label="År"
                  InputProps={{
                    inputProps: { min: 0, max: 25, step: 1 },
                  }}
                  value={nonActiveNumberYears}
                  onChange={(e) => {
                    if (e.target.value !== '') {
                      var years = parseInt(e.target.value);
                      if (!isNaN(years)) {
                        setNonActiveNumberYears(e.target.value);
                      }
                    } else {
                      setNonActiveNumberYears('');
                    }
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      setNumberOfYearsOnParent();
                    }
                  }}
                  onBlur={(e) => {
                    setNumberOfYearsOnParent();
                  }}
                />
              </Tooltip>
            </>
          )}
        </Stack>
      </List>
      {error && <Typography>{error.message}</Typography>}
      <div>
        <DataGridPro
          apiRef={apiRef}
          autoHeight
          density="compact"
          rows={rows}
          columns={columns}
          checkboxSelection
          filterDebounceMs={2000}
          isRowSelectable={function (params: GridRowParams) {
            return params.row.isDeletable;
          }}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelected(newRowSelectionModel.map((c) => c.toString()));
          }}
          localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
          pagination
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: paginationModel.pageSize,
                page: paginationModel.page,
              },
            },
            filter: {
              filterModel: {
                items: [],
                quickFilterLogicOperator: GridLogicOperator.Or,
              },
            },
          }}
          loading={isLoading}
          rowCount={rowCountState}
          paginationMode="server"
          pageSizeOptions={[10, 25, 100]}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          onPaginationModelChange={setPaginationModel}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </div>
    </>
  );

  function setNumberOfYearsOnParent() {
    var years = parseInt(nonActiveNumberYears);
    if (!isNaN(years)) {
      setNonActiveForNumberYears(parseInt(nonActiveNumberYears));
    } else {
      setNonActiveForNumberYears(5);
    }
  }
}
